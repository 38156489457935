<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <rect x="5" y="6" width="14" height="14" fill="white" />
    <rect x="3" y="6" width="18" height="4" fill="white" />
    <path
      d="M4.11538 21.5V10.6154H2.5V5.40389H7.67118C7.53014 5.24107 7.4343 5.05771 7.38365 4.85381C7.33302 4.64993 7.3077 4.43584 7.3077 4.21156C7.3077 3.46371 7.56945 2.82804 8.09295 2.30454C8.61645 1.78104 9.25213 1.51929 9.99998 1.51929C10.3833 1.51929 10.7384 1.59974 11.0654 1.76064C11.3923 1.92154 11.6846 2.1366 11.9423 2.40584C12.2 2.12634 12.4923 1.9087 12.8192 1.75294C13.1461 1.59717 13.5012 1.51929 13.8846 1.51929C14.6324 1.51929 15.2681 1.78104 15.7916 2.30454C16.3151 2.82804 16.5769 3.46371 16.5769 4.21156C16.5769 4.43336 16.549 4.64426 16.4932 4.84426C16.4374 5.04426 16.3442 5.2308 16.2134 5.40389H21.5V10.6154H19.8846V21.5H4.11538ZM13.8846 3.01924C13.5468 3.01924 13.2636 3.1335 13.0351 3.36204C12.8065 3.59057 12.6923 3.87374 12.6923 4.21156C12.6923 4.54939 12.8065 4.83257 13.0351 5.06109C13.2636 5.28962 13.5468 5.40389 13.8846 5.40389C14.2224 5.40389 14.5056 5.28962 14.7341 5.06109C14.9626 4.83257 15.0769 4.54939 15.0769 4.21156C15.0769 3.87374 14.9626 3.59057 14.7341 3.36204C14.5056 3.1335 14.2224 3.01924 13.8846 3.01924ZM8.80765 4.21156C8.80765 4.54939 8.92192 4.83257 9.15045 5.06109C9.37898 5.28962 9.66216 5.40389 9.99998 5.40389C10.3378 5.40389 10.621 5.28962 10.8495 5.06109C11.078 4.83257 11.1923 4.54939 11.1923 4.21156C11.1923 3.87374 11.078 3.59057 10.8495 3.36204C10.621 3.1335 10.3378 3.01924 9.99998 3.01924C9.66216 3.01924 9.37898 3.1335 9.15045 3.36204C8.92192 3.59057 8.80765 3.87374 8.80765 4.21156ZM3.99998 6.90386V9.11541H11.25V6.90386H3.99998ZM11.25 20V10.6154H5.61533V20H11.25ZM12.75 20H18.3846V10.6154H12.75V20ZM20 9.11541V6.90386H12.75V9.11541H20Z"
      fill="currentColor"
    />
  </svg>
</template>
