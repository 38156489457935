<template>
  <section class="container">
    <h2 class="title">{{ $t("home::yourRemindersTitle") }}</h2>

    <CallReminder v-for="call in upcomingCalls" :key="call.id" :call="call" />

    <router-link
      v-for="bill in payableBills"
      :key="bill.id"
      :to="`/payments?event-id=${bill.attributes.authorization_id}`"
      class="reminder"
    >
      <span class="left-block">
        <RoundIconFrame background="var(--r-color-green-light)">
          <UIIcon name="payment" />
        </RoundIconFrame>

        <span class="reminder-text">
          {{ $t("home::awaitingPayment") }}

          <br />

          <strong class="ph-no-capture" :class="{ warning: bill.attributes.due_soon || bill.attributes.overdue }">
            <UIIcon
              v-if="bill.attributes.due_soon || bill.attributes.overdue"
              class="warning-icon"
              name="warning-rounded"
            />

            {{ $t("home::dueDate", { date: localizedComputedDatetime(bill) }) }}
          </strong>
        </span>
      </span>

      <UIIcon name="chevron" class="chevron" />
    </router-link>
  </section>
</template>

<script setup lang="ts">
import UIIcon from "@/uikit/renata/components/UIIcon/UIIcon.vue";
import RoundIconFrame from "@/uikit/renata/components/RoundIconFrame/RoundIconFrame.vue";
import type { Calls, Bill } from "@/network/codecs";
import CallReminder from "./CallReminder.vue";
import { useTranslator } from "@/composables/useTranslator";

defineProps<{
  upcomingCalls: Calls;
  payableBills: Bill[];
}>();

const translator = useTranslator();

const localizedComputedDatetime = (bill: Bill) => {
  return new Intl.DateTimeFormat(translator.currentLocale, {
    month: "short",
    day: "numeric",
  }).format(new Date(bill.attributes.payment_due_date));
};
</script>

<style lang="postcss" scoped>
.container {
  margin-top: 0.25rem;
  padding: 1.5rem 0.75rem;
  background: var(--r-color-green-light);

  @media (--tablet) {
    margin-top: 0;
    padding: 2.25rem;
    border-radius: 1rem;
  }
}

.title {
  margin-bottom: 0.75rem;
  font-size: 1.4375rem;
  line-height: 1.75rem;
  color: var(--r-color-blue-green-dark);
}

.reminder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.25rem;
  padding: 1rem;

  text-decoration: none;

  background: var(--r-color-white);
  border-radius: 1.25rem;
  outline: 1px solid var(--r-color-white);

  &:last-child {
    margin-bottom: 0;
  }

  @media (--hoverable) {
    &:hover {
      outline-color: var(--r-color-content-50);
    }
  }

  /* stylelint-disable-next-line order/order */
  &:active {
    background: var(--r-color-content-20);
    outline-color: var(--r-color-content-20);
  }
}

.left-block {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.chevron {
  font-size: 1.5rem;
}

.reminder-text {
  @add-mixin body-default;

  strong {
    @add-mixin body-default-bold;

    display: flex;
    gap: 0.25rem;
    align-items: center;

    &.warning {
      color: var(--r-color-error);
    }
  }
}

.warning-icon {
  font-size: 1.25rem;
}
</style>
