import { defineStore } from "pinia";
import type { LocationQuery, RouteParamsGeneric, RouteRecordNameGeneric } from "vue-router";

export interface TransitionToRoute {
  /**
   * The whole location including the `search` and `hash`. This string is
   * percentage encoded.
   */
  fullPath: string;
  /**
   * Object representation of the `search` property of the current location.
   */
  query: LocationQuery;
  /**
   * Hash of the current location. If present, starts with a `#`.
   */
  hash: string;
  name: RouteRecordNameGeneric;
  params: RouteParamsGeneric;
}
export const useRouterStore = defineStore("routerStore", {
  state: () => ({
    transitionToRoute: null as TransitionToRoute | null,
  }),
  actions: {
    startTransition(route: TransitionToRoute) {
      this.transitionToRoute = route;
    },
    endTransition() {
      this.transitionToRoute = null;
    },
  },
});
