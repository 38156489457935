<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M5.75001 17V9.49999H7.25001V17H5.75001ZM11.25 17V9.49999H12.75V17H11.25ZM2.76926 20.5V19H21.2308V20.5H2.76926ZM16.75 17V9.49999H18.25V17H16.75ZM2.76926 7.49999V6.07699L12 1.55774L21.2308 6.07699V7.49999H2.76926ZM6.31526 5.99999H17.6848L12 3.24999L6.31526 5.99999Z"
      fill="currentColor"
    />
  </svg>
</template>
