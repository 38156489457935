<template>
  <svg
    class="loader"
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11 0C13.0874 2.48917e-08 15.1317 0.593921 16.8941 1.71239C18.6565 2.83086 20.0643 4.42771 20.9531 6.31643C21.8419 8.20514 22.1749 10.3077 21.9133 12.3787C21.6516 14.4496 20.8062 16.4033 19.4756 18.0117L17.611 16.4691C18.6488 15.2146 19.3083 13.6907 19.5123 12.0754C19.7164 10.46 19.4566 8.82001 18.7634 7.34681C18.0702 5.87362 16.9721 4.62807 15.5974 3.75567C14.2227 2.88326 12.6282 2.42 11 2.42V0Z"
      fill="currentColor"
    />
  </svg>
</template>

<style scoped lang="postcss">
.loader {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
