<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M18.1578 9.94622L17.1038 8.86147L19.0422 6.95397L20.096 8.00772L18.1578 9.94622ZM2.25 19.75V18.25H21.75V19.75H2.25ZM11.25 7.11547V4.42297H12.75V7.11547H11.25ZM5.84225 9.91522L3.93475 7.97697L4.9885 6.92297L6.927 8.86147L5.84225 9.91522ZM7.425 14H16.575C16.1917 13.1 15.5901 12.375 14.7703 11.825C13.9504 11.275 13.027 11 12 11C10.973 11 10.0496 11.275 9.22975 11.825C8.40992 12.375 7.80833 13.1 7.425 14ZM5.55775 15.5C5.67308 13.8 6.34742 12.375 7.58075 11.225C8.81408 10.075 10.2872 9.49997 12 9.49997C13.7128 9.49997 15.1859 10.075 16.4193 11.225C17.6526 12.375 18.3269 13.8 18.4423 15.5H5.55775Z"
      fill="currentColor"
    />
  </svg>
</template>
