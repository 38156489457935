<template>
  <div
    class="progress-bar"
    :class="{ visible: progress > 0 && progress < 100 }"
    :aria-hidden="progress <= 0 || progress >= 100"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="progress"
    :aria-label="$t('progressbar::label')"
  >
    <div class="bar" :style="`transform: translate3d(${progress - 100}%,0,0)`"></div>
    <!--    <div class="spinner">
          <UIIcon class="spinner-icon" name="loader" />
        </div>-->
  </div>
</template>

<script lang="ts">
export interface ProgressBarPlugin {
  start: () => void;
  done: () => void;
}
</script>

<script setup lang="ts">

import { computed } from "vue";
import { useProgressBarStore } from "@/stores/progressBarStore";

const progressBar = useProgressBarStore();
const progress = computed(() => progressBar.progress);
</script>

<style lang="postcss" scoped>
.bar {
  height: 2px;
  background: var(--main-color);
  box-shadow: 0 0 5px 0 var(--main-color);
}

.progress-bar {
  --main-color: #ff7468;

  pointer-events: none;

  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  left: 0;

  opacity: 0;

  transition: opacity 300ms linear;

  &.visible {
    opacity: 1;

    & .bar {
      transition: all 200ms ease 0s;
    }
  }
}

/*.spinner {
  position: fixed;
  z-index: 999999;
  top: 15px;
  right: 15px;

  display: block;
}

.spinner-icon {
  font-size: 1.25rem;
  color: var(--main-color);
}*/
</style>
