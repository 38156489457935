<template>
  <svg
    width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11.9999 3.63477L22.2884 11.3463L21.3769 12.5423L19.4999 11.1345V20.5H4.49993V11.1345L2.62293 12.5423L1.71143 11.3463L11.9999 3.63477ZM7.92693 12.2593C7.92693 13.0351 8.34426 13.8621 9.17893 14.7403C10.0134 15.6186 10.9538 16.5321 11.9999 17.4808C13.0589 16.5321 14.0057 15.6189 14.8402 14.7413C15.6748 13.8634 16.0922 13.0369 16.0922 12.2618C16.0922 11.6181 15.8723 11.0834 15.4324 10.6578C14.9924 10.2321 14.4645 10.0193 13.8487 10.0193C13.4675 10.0193 13.1202 10.0972 12.8067 10.253C12.4932 10.4087 12.2243 10.609 11.9999 10.854C11.7884 10.609 11.5192 10.4087 11.1922 10.253C10.8653 10.0972 10.5257 10.0193 10.1732 10.0193C9.55668 10.0193 9.02818 10.2315 8.58768 10.656C8.14718 11.0807 7.92693 11.6151 7.92693 12.2593ZM17.9999 19V10L11.9999 5.50002L5.99993 10V19H17.9999Z"
      fill="currentColor" />
  </svg>
</template>
