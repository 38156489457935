<template>
  <router-view />
  <PageTitle :title="documentTitle || 'Stork Club'" />
</template>

<script setup lang="ts">
import PageTitle from "@/components/PageTitle.vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { useTranslator } from "@/composables/useTranslator";

const route = useRoute();

const translator = useTranslator();
const documentTitle = computed(() => {
  return typeof route.meta.documentTitle === "function"
    ? route.meta.documentTitle(translator.t)
    : route.meta.documentTitle;
});
</script>
