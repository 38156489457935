<template>
  <div class="renata-ui">
    <slot />
  </div>
</template>

<style lang="postcss">
.renata-ui {
  --r-border-radius: 1rem;
  --r-border-radius-m: 0.75rem;

  --r-color-coral-light: #ffbcb3;
  --r-color-coral: #ff7468;
  --r-color-coral-dark: #680206;
  --r-color-coral-hover: #ff9f95;
  --r-color-coral-press: #ff8277;
  --r-color-brown-black: #4a4a4a;
  --r-color-brown-grey: #908b85;
  --r-color-blue-light: #e4e7ff;
  --r-color-blue: #3d4ed5;
  --r-color-blue-dark: #00169c;
  --r-color-blue-hover: #c3c8f7;
  --r-color-blue-press: #a1aaee;
  --r-color-blue-green: #8ad2e1;
  --r-color-blue-green-dark: #0c353d;
  --r-color-blue-green-light: #c4e7ee;
  --r-color-green-light: #cdf9cc;
  --r-color-green: #84e181;
  --r-color-green-dark: #003826;
  --r-color-green-hover: #b0efae;
  --r-color-green-press: #93e690;
  --r-color-sky-light: #c4e7ee;
  --r-color-sky: #8ad2e1;
  --r-color-sky-hover: #8ad2e166;
  --r-color-sky-press: #8ad2e1cc;
  --r-color-gold-light: #ffefd0;
  --r-color-gold: #e0c485;
  --r-color-gold-dark: #3e2e00;
  --r-color-gold-hover: #f3deb2;
  --r-color-gold-press: #e6cd94;
  --r-color-content-20: #f5f3f1;
  --r-color-content-40: #e0ddd9;
  --r-color-content-50: #bab5b0;
  --r-color-content-60: #948e87;
  --r-color-content-80: #6e6e6e;
  --r-color-content-100: #4a4a4a;
  --r-color-light-grey: #fbfaf9;
  --r-color-white: #ffffff;
  --r-color-black: #000000;
  --r-color-error: #b3261e;

  --r-transition-short: 0.128s;
  --r-transition-long: 0.3s;

  display: contents;

  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.25rem;

  :where(h1) {
    font-family: Faktum, sans-serif;
    font-size: 1.9375rem;
    font-weight: 700;
    font-style: normal;
    line-height: 2.25rem;
    color: var(--r-color-brown-black);
    letter-spacing: -0.9px;
  }

  :where(h2) {
    font-family: Faktum, sans-serif;
    font-size: 1.625rem;
    font-weight: 600;
    font-style: normal;
    line-height: 2rem;
    color: var(--r-color-brown-black);
    letter-spacing: -0.7px;
  }

  :where(h3) {
    font-family: Faktum, sans-serif;
    font-size: 1.375rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.75rem;
    color: var(--r-color-brown-black);
    letter-spacing: -0.7px;
  }

  :where(h4) {
    font-family: "Roboto Flex", sans-serif;
    font-size: 1.1875rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5rem;
    color: var(--r-color-brown-black);
  }

  :where(.h4) {
    font-family: "Roboto Flex", sans-serif;
    font-size: 1.1875rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.5rem;
    color: var(--r-color-brown-black);
  }

  :where(.h3) {
    font-family: Faktum, sans-serif;
    font-size: 1.375rem;
    font-weight: 600;
    font-style: normal;
    line-height: 1.75rem;
    color: var(--r-color-brown-black);
    letter-spacing: -0.7px;
  }

  :where(.h2) {
    font-family: Faktum, sans-serif;
    font-size: 1.625rem;
    font-weight: 600;
    font-style: normal;
    line-height: 2rem;
    color: var(--r-color-brown-black);
    letter-spacing: -0.7px;
  }

  :where(.h1) {
    font-family: Faktum, sans-serif;
    font-size: 1.9375rem;
    font-weight: 700;
    font-style: normal;
    line-height: 2.25rem;
    color: var(--r-color-brown-black);
    letter-spacing: -0.9px;
  }

  :where(a, .link-button) {
    font-family: "Roboto Flex", sans-serif;
    font-size: 0.9375rem;
    font-weight: 500;
    font-style: normal;
    line-height: 1.25rem;
    color: var(--r-color-content-100);
    text-decoration-line: underline;
  }

  :where(select) {
    --border-width: 1px;
    --padding-horizontal: calc(1rem - var(--border-width));

    padding: calc(0.75rem - var(--border-width)) var(--padding-horizontal);
    padding-inline-end: calc(var(--padding-horizontal) + 1.5em); /* TODO: check for rtl */
    border: var(--border-width) solid var(--r-color-content-60);
    border-radius: var(--r-border-radius-m);

    font-size: 1rem;
    line-height: 1.5;
    /*You can not use CSS variables with background and you can not use `::after {clip-path}` with the select.
          So you have to hardcode colors or use additional wrapper. I have chosen hardcoded variant*/
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='7' viewBox='0 0 9 7' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.990487 2.04076C0.562659 1.37525 1.0405 0.5 1.83167 0.5H7.16833C7.9595 0.5 8.43734 1.37525 8.00951 2.04076L5.34118 6.1915C4.94754 6.80382 4.05246 6.80382 3.65882 6.1915L0.990487 2.04076Z' fill='%234a4a4a'/%3E%3C/svg%3E")
      no-repeat var(--padding-horizontal);
    background-position-x: calc(100% - 1em);
    background-position-y: center;
    background-size: 0.5625em;

    &:dir(rtl) {
      background-position-x: calc(0% + 1em);
    }

    &:hover {
      border-color: var(--r-color-content-100);
    }

    &:disabled {
      cursor: not-allowed;

      border-color: var(--r-color-content-40);

      color: var(--r-color-content-100);

      opacity: 1;
      background-color: var(--r-color-content-20);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='7' viewBox='0 0 9 7' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.990487 2.04076C0.562659 1.37525 1.0405 0.5 1.83167 0.5H7.16833C7.9595 0.5 8.43734 1.37525 8.00951 2.04076L5.34118 6.1915C4.94754 6.80382 4.05246 6.80382 3.65882 6.1915L0.990487 2.04076Z' fill='%23e0ddd9'/%3E%3C/svg%3E");
    }

    &[aria-invalid="true"] {
      --border-width: 2px;

      border-color: var(--r-color-error);
    }

    &:focus-within {
      --border-width: 2px;

      border-color: var(--r-color-content-100);
      outline: none;
    }
  }

  :where(input:not([type="radio"]):not([type="checkbox"])) {
    --border-width: 1px;

    padding: calc(0.875rem - var(--border-width)) calc(1rem - var(--border-width));
    border: var(--border-width) solid var(--r-color-content-60);
    border-radius: var(--r-border-radius-m);

    font-family: Roboto, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--r-color-black);

    &::placeholder {
      color: var(--r-color-content-80);
      opacity: 1;
    }

    &:hover {
      border-color: var(--r-color-content-100);
    }

    &[aria-invalid="true"][aria-describedby]:not(:focus-within) {
      --border-width: 2px;

      border-color: var(--r-color-error);
    }

    &:focus-within {
      --border-width: 2px;

      border-color: var(--r-color-content-100);
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: var(--r-color-content-40);
      color: var(--r-color-content-50);
      background: var(--r-color-content-20);

      &::placeholder {
        color: var(--r-color-content-50);
      }
    }
  }

  /* `has` supported by most of the browsers already. And this `cursor` thing is not such important, so we can use less robust by more maintainable thing*/

  :where(label:has(input:disabled)) {
    cursor: not-allowed;
  }

  :where(fieldset[aria-describedby][data-invalid]) {
    :where(input[type="radio"]) {
      border-width: min(calc(2em / 16));
      color: var(--r-color-error);
    }
  }

  :where(input[type="checkbox"]) {
    border-radius: 2px;
    &::before {
      transform-origin: bottom left;
      /*noinspection CssInvalidPropertyValue*/
      clip-path: path(
        "m9.85,2.84l-5.78,5.79l-4.04,-4.04l1.41,-1.41l2.63,2.62l4.37,-4.37l1.41,1.41z"
      );
    }
  }

  :where(label > input[type="radio"], label > input[type="checkbox"]) {
    margin-inline-end: 0.5em;
    vertical-align: middle;
  }

  :where(input[type="radio"]) {
    border-radius: 50%;

    &::before {
      border-radius: 50%;
    }
  }

  :where(input[type="radio"], input[type="checkbox"]) {
    position: relative;

    display: inline-block;

    width: 1.1em;
    height: 1.1em;
    /* Not removed via appearance */
    margin: 0;
    padding: 0;
    border: min(calc(2em / 16)) solid var(--r-color-content-100);

    color: var(--r-color-content-60);

    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;

    &::before {
      --border-space: min(calc(2em / 16));

      content: "";

      position: absolute;
      top: 50%;
      inset-inline-start: 50%;
      transform: translate(-50%, -50%) scale(0);

      width: calc((1.1em - var(--border-space)) * 2 / 3);
      height: calc((1.1em - var(--border-space)) * 2 / 3);
      /* Windows High Contrast Mode */
      background-color: FieldText;
      box-shadow: inset 1.1em 1.1em currentColor;
    }

    &:dir(rtl)::before {
      transform: translate(50%, -50%) scale(0);
    }

    &:checked {
      border-width: min(calc(2em / 16));
      color: #fff;
      background-color: var(--r-color-content-100);

      &::before {
        transform: translate(-50%, -50%) scale(1);
      }
    }

    &:dir(rtl):checked::before {
      transform: translate(50%, -50%) scale(1);
    }

    &:disabled {
      background: var(--r-color-content-20);

      &:checked {
        color: var(--r-color-content-40);
      }
    }

    &:focus-visible {
      border-color: var(--r-color-blue);
      outline-color: var(--r-color-blue);
    }
  }

  :where(input[type="checkbox"][aria-invalid="true"]) {
    border-color: var(--r-color-error);
    border-width: min(calc(2em / 16));
    color: var(--r-color-error);
  }

  :where(.form-field) {
    display: flex;
    flex-direction: column;

    :where(.form-field__label) {
      margin: 0;
      margin-block-end: 0.375rem;

      font-family: "Roboto Flex", sans-serif;
      font-size: 1.0625rem;
      font-weight: 500;
      line-height: 1.25rem;
      color: var(--r-color-content-100);
    }

    :where(.form-field__error) {
      display: flex;
      gap: 0.3125rem;
      align-items: center;

      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--r-color-error);

      &:not(:empty) {
        margin-block-start: 0.375rem;
      }

      & svg {
        font-size: 1.25rem;
      }
    }

    :where(.form-field__hint) {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: var(--r-color-content-80);

      &:not(:empty) {
        margin-block-start: 0.25rem;
      }
    }

    &.invalid {
      :where(.form-field__label) {
        color: var(--r-color-error);
      }
    }
  }

  :where(fieldset.form-field, .fieldset.form-field) {
    all: unset;

    display: grid;
    grid-template:
      "legend"
      "controls"
      "error" / auto;

    margin: 0;
    padding: 0;
    border: none;

    appearance: none;

    .form-field__legend {
      @add-mixin body-large-medium;

      grid-area: legend;
      margin: 0 0 1rem;
    }

    .form-field__controls {
      display: flex;
      grid-area: controls;
      flex-wrap: wrap;
      gap: 0.5rem;

      & label {
        display: flex;
        align-items: center;
      }
    }

    .form-field__error {
      grid-area: error;
      font-size: 0.8125rem;
      line-height: 1rem;
      color: var(--r-color-error);

      &:not(:empty) {
        margin-block-start: 0.375rem;
      }
    }
  }
  :where(b, .bold) {
    font-family: "Roboto Flex", sans-serif;
    font-weight: 500;
  }

  :where(hr, .hr) {
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid var(--r-color-content-40);
  }

  :where(p) {
    margin: 0;
    padding: 0;
  }

  :where(dd, dt, dl) {
    margin: 0;
    padding: 0;
  }
}

/**
* for <Transition name="fade" />
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--r-transition-short) linear;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
