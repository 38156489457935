import { type Plugin } from "vue";
import type { PostHogService } from "@/posthog/types";
import RealPostHog from "@/posthog/RealPostHog";
import NoopPostHog from "@/posthog/NoopPostHog";

const posthogPlugin: Plugin = {
  install(app) {
    const posthogToken = import.meta.env.VITE_POSTHOG_TOKEN;
    const instance = posthogToken ? new RealPostHog(posthogToken) : new NoopPostHog();

    app.config.globalProperties.$posthog = instance;
    app.provide("posthog", instance);
  },
};

export default posthogPlugin;

declare module "vue" {
  interface ComponentCustomProperties {
    $posthog: PostHogService;
  }
}

// https://youtrack.jetbrains.com/issue/WEB-59818/Vue-custom-global-properties-added-by-augmenting-vue-are-not-resolved
// We have to provide the second typing for the global properties to make it work in the JetBrains IDEs
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $posthog: PostHogService;
  }
}
