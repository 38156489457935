import { defineStore } from "pinia";
import { denormalizeUnknown } from "@/network/denormalize";
import { UnreadMessagesCountC } from "@/network/codecs";

export const useUnreadCountersStore = defineStore("unreadCounters", {
  state: () => ({
    careNavigatorUnreadMessagesCountRawData: null as unknown,
    notificationsUnreadCountRawData: null as unknown,
    paymentsUnreadCountRawData: null as unknown,
  }),
  getters: {
    careNavigatorUnreadMessagesCount: (state) => {
      return UnreadMessagesCountC.mask(state.careNavigatorUnreadMessagesCountRawData);
    },

    notificationsUnreadCount: (state) => {
      return UnreadMessagesCountC.mask(state.notificationsUnreadCountRawData);
    },

    paymentsUnreadCount: (state) => {
      return UnreadMessagesCountC.mask(state.paymentsUnreadCountRawData);
    },
  },
  actions: {
    async fetchCareNavigatorUnreadMessagesCount() {
      const response = await this.apiClient.get("v3/notifications/unread_messages_count").json();

      this.careNavigatorUnreadMessagesCountRawData = (
        denormalizeUnknown(response) as any
      ).unread_messages_count;
    },

    async fetchNotificationsUnreadCount() {
      const response = await this.apiClient
        .get("v3/notifications/unread_notifications_count")
        .json();

      this.notificationsUnreadCountRawData = (
        denormalizeUnknown(response) as any
      ).unread_notifications_count;
    },

    async fetchPaymentsUnreadCount() {
      const response = await this.apiClient.get("v3/notifications/unread_bills_count").json();

      this.paymentsUnreadCountRawData = (denormalizeUnknown(response) as any).unread_bills_count;
    },
  },
});
