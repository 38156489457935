export function normalizeError(err: unknown): Error {
  if (err instanceof Error) {
    return err;
  }
  console.warn(`Non Error exception. You should avoid throwing anything but instance of Error`, {
    err,
  });
  return new Error(String(err), { cause: err });
}

/**
 * Represents the return type of asynchronous function.
 * @template T - The type of the function.
 * @returns The inferred return type of the asynchronous function.
 */
export type AsyncReturnType<T extends (...args: any) => any> =
  ReturnType<T> extends Promise<infer R> ? R : never;

export type ArrayElement<ArrayType extends unknown[] | readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[]
    ? ElementType
    : ArrayType extends (infer ElementType)[]
      ? ElementType
      : never;
