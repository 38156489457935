<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <rect x="6" y="3" width="12" height="18" fill="white" />
    <path
      d="M7.3077 22.4999C6.81058 22.4999 6.38502 22.3229 6.03102 21.9689C5.67701 21.6149 5.5 21.1894 5.5 20.6923V3.3077C5.5 2.81058 5.67701 2.38503 6.03102 2.03103C6.38502 1.67701 6.81058 1.5 7.3077 1.5H16.6922C17.1894 1.5 17.6149 1.67701 17.9689 2.03103C18.3229 2.38503 18.5 2.81058 18.5 3.3077V20.6923C18.5 21.1894 18.3229 21.6149 17.9689 21.9689C17.6149 22.3229 17.1894 22.4999 16.6922 22.4999H7.3077ZM6.99997 19.75V20.6923C6.99997 20.782 7.02882 20.8558 7.08652 20.9135C7.14423 20.9712 7.21795 21 7.3077 21H16.6922C16.782 21 16.8557 20.9712 16.9134 20.9135C16.9711 20.8558 17 20.782 17 20.6923V19.75H6.99997ZM6.99997 4.25003H17V3.30773C17 3.21798 16.9711 3.14425 16.9134 3.08655C16.8557 3.02885 16.782 3 16.6922 3H7.3077C7.21795 3 7.14423 3.02885 7.08652 3.08655C7.02882 3.14425 6.99997 3.21798 6.99997 3.30773V4.25003ZM12 15.5C11.1 15.5 10.225 15.6291 9.37497 15.8875C8.52497 16.1458 7.73331 16.5166 6.99997 17V18.25H17V17C16.2666 16.5166 15.475 16.1458 14.625 15.8875C13.775 15.6291 12.9 15.5 12 15.5ZM12 14C12.9 14 13.7708 14.1092 14.6125 14.3275C15.4541 14.5459 16.25 14.8559 17 15.2577V5.74995H6.99997V15.2577C7.74997 14.8559 8.54581 14.5459 9.38747 14.3275C10.2291 14.1092 11.1 14 12 14ZM11.9988 12.75C12.7624 12.75 13.4118 12.4827 13.9471 11.9482C14.4823 11.4137 14.75 10.7647 14.75 10.0011C14.75 9.23753 14.4827 8.58813 13.9482 8.05288C13.4137 7.51763 12.7647 7.25 12.0011 7.25C11.2375 7.25 10.5881 7.51725 10.0529 8.05175C9.51762 8.58625 9.25 9.23528 9.25 9.99885C9.25 10.7624 9.51725 11.4118 10.0518 11.9471C10.5863 12.4823 11.2353 12.75 11.9988 12.75ZM12 11.25C11.6525 11.25 11.3573 11.1285 11.1144 10.8856C10.8714 10.6426 10.7499 10.3474 10.7499 9.99998C10.7499 9.65254 10.8714 9.35734 11.1144 9.11438C11.3573 8.87143 11.6525 8.74995 12 8.74995C12.3474 8.74995 12.6426 8.87143 12.8856 9.11438C13.1285 9.35734 13.25 9.65254 13.25 9.99998C13.25 10.3474 13.1285 10.6426 12.8856 10.8856C12.6426 11.1285 12.3474 11.25 12 11.25ZM12 18.25H17H6.99997H12Z"
      fill="currentColor"
    />
  </svg>
</template>
