<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <rect x="4.625" y="2.75" width="10.75" height="14.625" fill="white" />
    <path
      d="M10 15C9.18056 15 8.39236 15.1285 7.63542 15.3854C6.87847 15.6424 6.17361 16.0139 5.52083 16.5H14.4792C13.8403 16.0139 13.1389 15.6424 12.375 15.3854C11.6111 15.1285 10.8194 15 10 15ZM10 13.5C10.7917 13.5 11.5694 13.5972 12.3333 13.7917C13.0972 13.9861 13.8194 14.2847 14.5 14.6875V3.5H5.5V14.6875C6.18056 14.2847 6.90278 13.9861 7.66667 13.7917C8.43056 13.5972 9.20833 13.5 10 13.5ZM10 11C9.58333 11 9.22917 10.8542 8.9375 10.5625C8.64583 10.2708 8.5 9.91667 8.5 9.5C8.5 9.08333 8.64583 8.72917 8.9375 8.4375C9.22917 8.14583 9.58333 8 10 8C10.4167 8 10.7708 8.14583 11.0625 8.4375C11.3542 8.72917 11.5 9.08333 11.5 9.5C11.5 9.91667 11.3542 10.2708 11.0625 10.5625C10.7708 10.8542 10.4167 11 10 11ZM5.49417 18C5.08139 18 4.72917 17.8531 4.4375 17.5594C4.14583 17.2656 4 16.9125 4 16.5V3.5C4 3.0875 4.14694 2.73438 4.44083 2.44063C4.73472 2.14688 5.08806 2 5.50083 2H14.5058C14.9186 2 15.2708 2.14688 15.5625 2.44063C15.8542 2.73438 16 3.0875 16 3.5V16.5C16 16.9125 15.8531 17.2656 15.5592 17.5594C15.2653 17.8531 14.9119 18 14.4992 18H5.49417ZM10.005 12.5C10.835 12.5 11.5417 12.2067 12.125 11.62C12.7083 11.0333 13 10.325 13 9.495C13 8.665 12.7067 7.95833 12.12 7.375C11.5333 6.79167 10.825 6.5 9.995 6.5C9.165 6.5 8.45833 6.79333 7.875 7.38C7.29167 7.96667 7 8.675 7 9.505C7 10.335 7.29333 11.0417 7.88 11.625C8.46667 12.2083 9.175 12.5 10.005 12.5Z"
      fill="currentColor"
    />
  </svg>
</template>
