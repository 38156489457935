import { defineStore } from "pinia";
import {
  CallCategoriesC,
  CallsC,
  CallC,
  CallSubjectsC,
  CallExpertsC,
  CallExpertC,
  CallExpertsMetaC,
} from "@/network/codecs";
import { denormalizeUnknown } from "@/network/denormalize";

export const useVideoVisitsStore = defineStore("videoVisits", {
  state: () => ({
    callCategoriesRawData: null as unknown,
    callSubjectsRawData: null as unknown,
    callExpertsRawData: null as unknown,
    callExpertsRawMeta: null as unknown,
    callExpertsPending: false,
    callExpertRawData: null as unknown,
    upcomingCallsRawData: null as unknown,
    upcomingCallRawData: null as unknown,
    isPageLoading: false,
  }),
  getters: {
    callCategories: (state) => {
      return state.callCategoriesRawData ? CallCategoriesC.mask(state.callCategoriesRawData) : null;
    },

    callSubjects: (state) => {
      return state.callSubjectsRawData ? CallSubjectsC.mask(state.callSubjectsRawData) : null;
    },

    callExperts: (state) => {
      return state.callExpertsRawData ? CallExpertsC.mask(state.callExpertsRawData) : null;
    },

    callExpertsMeta: (state) => {
      return state.callExpertsRawMeta ? CallExpertsMetaC.mask(state.callExpertsRawMeta) : null;
    },

    callExpert: (state) => {
      return state.callExpertRawData ? CallExpertC.mask(state.callExpertRawData) : null;
    },

    upcomingCalls: (state) => {
      return state.upcomingCallsRawData ? CallsC.mask(state.upcomingCallsRawData) : null;
    },

    upcomingCall: (state) => {
      return state.upcomingCallRawData ? CallC.mask(state.upcomingCallRawData) : null;
    },
  },
  actions: {
    async fetchCallCategories() {
      const response = await this.apiClient.get("v3/company/call_categories").json();
      this.callCategoriesRawData = (denormalizeUnknown(response) as any).data;
    },

    async fetchCallSubjects(categoryId: string) {
      const response = await this.apiClient
        .get(`v3/company/call_categories/${categoryId}/call_subjects`)
        .json();

      this.callSubjectsRawData = (denormalizeUnknown(response) as any).data;
    },

    async fetchCallExperts(categoryId: string, date: string, expertId: string | undefined) {
      let path = `v3/call_experts?call_subject=${categoryId}&date=${date}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

      if (expertId) path += `&call_expert_id=${expertId}`;

      this.callExpertsPending = true;

      const response = await this.apiClient.get(path).json();

      const denormalizedResponse = denormalizeUnknown(response) as any;

      this.callExpertsRawData = denormalizedResponse.data;
      this.callExpertsRawMeta = denormalizedResponse.meta;

      this.callExpertsPending = false;
    },

    async fetchCallExpert(id: string, callSubject: string) {
      this.isPageLoading = true;
      const response = await this.apiClient
        .get(`v3/call_experts/${id}?call_subject=${callSubject}`)
        .json();

      this.callExpertRawData = (denormalizeUnknown(response) as any).data;
      this.isPageLoading = false;
    },

    async fetchUpcomingCalls(callSubject = "") {
      const response = await this.apiClient.get(`v3/calls?call_subject=${callSubject}`).json();
      this.upcomingCallsRawData = (denormalizeUnknown(response) as any).data;
    },

    async fetchUpcomingCall(token: string) {
      const response = await this.apiClient.get(`v3/calls/${token}`).json();
      this.upcomingCallRawData = (denormalizeUnknown(response) as any).data;
    },

    async fetchVisitCategoriesPage() {
      this.isPageLoading = true;
      await Promise.all([this.fetchCallCategories(), this.fetchUpcomingCalls()]);
      this.isPageLoading = false;
    },

    async fetchVisitCategoryPage(categoryId: string) {
      this.isPageLoading = true;
      await this.fetchCallSubjects(categoryId);
      this.isPageLoading = false;
    },

    async fetchUpcomingCallPage(token: string) {
      this.isPageLoading = true;
      await this.fetchUpcomingCall(token);
      this.isPageLoading = false;
    },
  },
});
