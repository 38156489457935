/**
 * 'some_value'                                   => undefined
 * '.htaccess'                                    => 'htaccess'
 * '../images/something.cool.jpg'                 => 'jpg'
 * 'http://www.w3schools.com/jsref/jsref_pop.asp' => 'asp'
 * 'http://stackoverflow.com/questions/680929'    => 'com/questions/680929'
 */
function getExt(string: string): string | undefined {
  const chunks = string.replaceAll("/", "").split(".");
  if (chunks.length < 2) {
    return undefined;
  }

  return chunks.pop();
}

const fileExtensions = ["doc", "docx", "pdf"];
export function isFileLink(string: string): boolean {
  const ext = getExt(string);

  return !!ext && fileExtensions.includes(ext);
}