<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M3.5 17.6346V16.1346H20.5V17.6346H3.5ZM3.5 12.7499V11.25H20.5V12.7499H3.5ZM3.5 7.86533V6.36536H20.5V7.86533H3.5Z"
      fill="currentColor"
    />
  </svg>
</template>
