<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M8.10688 11.288L6.80449 7.68425L3.21251 6.37064L3.18951 6.11992L11.8105 2.67828L8.35759 11.288H8.10688Z"
      fill="currentColor"
    />
  </svg>
</template>
