import { defineStore } from "pinia";
import { CareTasksC, CareTaskC } from "@/network/codecs";
import { denormalizeUnknown } from "@/network/denormalize";

export const useCareTasksStore = defineStore("careTasks", {
  state: () => ({
    tasksRawData: null as unknown,
    taskRawData: null as unknown,
  }),
  getters: {
    careTasks: (state) => {
      return state.tasksRawData ? CareTasksC.mask(state.tasksRawData) : null;
    },

    careTask: (state) => {
      return state.taskRawData ? CareTaskC.mask(state.taskRawData) : null;
    },
  },
  actions: {
    async fetchTasks() {
      const response = await this.apiClient.get("v3/care_tasks").json();
      this.tasksRawData = (denormalizeUnknown(response) as any).data;
    },

    async fetchTask(id: string) {
      const response = await this.apiClient.get(`v3/care_tasks/${id}`).json();
      this.taskRawData = (denormalizeUnknown(response) as any).data;
    },
  },
});
