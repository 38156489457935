<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <g clip-path="url(#clip0_825_1330)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.98603 11.9026C2.73014 11.9026 0.0971073 9.26157 0.0971069 5.99981V5.34211L0.551465 5.43321C0.575532 5.43804 0.597412 5.44251 0.617539 5.44664C0.729722 5.46954 0.787365 5.48131 0.864402 5.48131C1.34069 5.48131 1.73329 5.19157 1.89854 4.79811L2.02909 4.48727L2.35319 4.58011C2.51435 4.62627 2.67298 4.64871 2.83001 4.64871C3.83753 4.64871 4.66506 3.82044 4.66506 2.80808C4.66506 2.64917 4.64283 2.51148 4.61483 2.34302L4.5725 2.08841L4.79366 1.95536C5.09063 1.77675 5.3048 1.42844 5.32943 1.02231C5.32773 0.90192 5.294 0.792317 5.23813 0.62429L5.1026 0.216639L5.5238 0.132186C5.69963 0.0969347 5.87043 0.096982 6.00143 0.0970177L6.01373 0.0970203C9.26963 0.0970203 11.9027 2.73804 11.9027 5.99981C11.9027 9.26404 9.23946 11.9026 5.98603 11.9026ZM0.862042 6.24074C0.987295 8.97267 3.23185 11.1431 5.98603 11.1431C8.82323 11.1431 11.1432 8.84147 11.1432 5.99981C11.1432 3.17719 8.88303 0.891284 6.078 0.856837C6.08487 0.912787 6.0889 0.97119 6.0889 1.03181V1.04177L6.0884 1.05172C6.0594 1.60401 5.80643 2.1172 5.4001 2.45365C5.41406 2.56144 5.42447 2.67937 5.42447 2.80808C5.42447 4.23807 4.25873 5.40811 2.83001 5.40811C2.7022 5.40811 2.57489 5.39794 2.44822 5.37784C2.1194 5.88904 1.54162 6.24074 0.864402 6.24074C0.863615 6.24074 0.862825 6.24074 0.862042 6.24074Z"
        fill="currentColor"
      />
      <path
        d="M3.80165 0.843088C3.80165 0.450105 3.48309 0.131531 3.0901 0.131531C2.69712 0.131531 2.37854 0.450105 2.37854 0.843088C2.37854 1.23607 2.69712 1.55464 3.0901 1.55464C3.48309 1.55464 3.80165 1.23607 3.80165 0.843088Z"
        fill="currentColor"
      />
      <path
        d="M3.41412 3.06567C3.41412 2.77971 3.18229 2.54788 2.89633 2.54788C2.61036 2.54788 2.37854 2.77971 2.37854 3.06567C2.37854 3.35163 2.61036 3.58346 2.89633 3.58346C3.18229 3.58346 3.41412 3.35163 3.41412 3.06567Z"
        fill="currentColor"
      />
      <path
        d="M1.37477 3.42669C1.37477 3.13197 1.13584 2.89304 0.841101 2.89304C0.546364 2.89304 0.307434 3.13197 0.307434 3.42669C0.307434 3.72145 0.546364 3.96035 0.841101 3.96035C1.13584 3.96035 1.37477 3.72145 1.37477 3.42669Z"
        fill="currentColor"
      />
      <path
        d="M5.57155 5.75074L5.91022 6.36744L6.11662 6.73847L7.72005 3.99997H8.95835L6.71459 7.83074H5.47629L4.33325 5.75074H5.57155Z"
        fill="currentColor"
      />
      <path d="M6.64708 8.59677H5.49078V9.746H6.64708V8.59677Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_825_1330">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
