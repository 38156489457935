<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.2857 3.75372C18.2858 3.63823 18.2605 3.52414 18.2117 3.41949C18.1628 3.31484 18.0916 3.22218 18.0031 3.14805C17.9145 3.07392 17.8108 3.02013 17.6992 2.99047C17.5876 2.9608 17.4708 2.956 17.3571 2.97639L6.36537 4.93989C6.18288 4.9724 6.01764 5.0681 5.89864 5.21024C5.77964 5.35237 5.71447 5.53185 5.71454 5.71722V19.8559H2.57129V21.428H21.429V19.8559H18.2857V3.75372ZM14.7501 19.8559H16.7145V4.6878L7.28571 6.37172V19.8559H9.25012V15.9326C9.25012 15.9298 9.25196 15.9289 9.25379 15.9289H14.7465C14.7483 15.9289 14.7501 15.9307 14.7501 15.9326V19.8559ZM13.179 19.8559V17.5001H10.8213V19.8568L13.179 19.8559ZM9.25012 9.64147H14.7501V8.0703H9.25012V9.64147ZM14.7501 13.5703H9.25012V12.0001H14.7501V13.5703Z"
      fill="currentColor"
    />
  </svg>
</template>
