<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path d="M5.04163 19.25L5.95829 4.58333L16.0416 2.75L16.9583 19.25H5.04163Z" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2856 2.75372C17.2857 2.63823 17.2604 2.52414 17.2115 2.41949C17.1627 2.31484 17.0915 2.22218 17.003 2.14805C16.9144 2.07392 16.8107 2.02013 16.699 1.99047C16.5874 1.9608 16.4707 1.956 16.357 1.97639L5.36525 3.93989C5.18275 3.9724 5.01752 4.0681 4.89852 4.21024C4.77952 4.35237 4.71434 4.53185 4.71442 4.71722V18.8559H1.57117V20.428H20.4288V18.8559H17.2856V2.75372ZM13.75 18.8559H15.7144V3.6878L6.28558 5.37172V18.8559H8.25V14.9326C8.25 14.9298 8.25183 14.9289 8.25367 14.9289H13.7463C13.7482 14.9289 13.75 14.9307 13.75 14.9326V18.8559ZM12.1788 18.8559V16.5001H9.82117V18.8568L12.1788 18.8559ZM8.25 8.64147H13.75V7.0703H8.25V8.64147ZM13.75 12.5703H8.25V11.0001H13.75V12.5703Z"
      fill="currentColor"
    />
  </svg>
</template>
