<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11.2558 20.75V18.6577C10.3853 18.5025 9.63565 18.1625 9.0068 17.6375C8.37795 17.1125 7.91353 16.3782 7.61353 15.4346L9.00195 14.8577C9.27118 15.6321 9.65484 16.2244 10.1529 16.6346C10.651 17.0449 11.3161 17.25 12.1481 17.25C12.8699 17.25 13.5068 17.0782 14.0587 16.7346C14.6106 16.391 14.8866 15.8461 14.8866 15.1C14.8866 14.4525 14.6792 13.9356 14.2645 13.549C13.8497 13.1625 12.9917 12.7589 11.6905 12.3384C10.3276 11.9077 9.38116 11.4038 8.85103 10.8269C8.32089 10.25 8.05583 9.52433 8.05583 8.64998C8.05583 7.64358 8.40903 6.85159 9.11545 6.27403C9.82187 5.69646 10.5353 5.37563 11.2558 5.31153V3.25H12.7558V5.31153C13.5186 5.41281 14.1596 5.66088 14.6789 6.05575C15.1981 6.45062 15.6019 6.96408 15.8904 7.59613L14.5212 8.22303C14.302 7.74098 13.9962 7.36662 13.6039 7.09995C13.2116 6.83328 12.6956 6.69995 12.0558 6.69995C11.3033 6.69995 10.6984 6.88328 10.2414 7.24995C9.78433 7.61663 9.5558 8.08331 9.5558 8.64998C9.5558 9.23203 9.78978 9.69741 10.2577 10.0461C10.7257 10.3949 11.5955 10.7603 12.8673 11.1423C14.0494 11.5013 14.9315 12.0128 15.5135 12.6769C16.0956 13.341 16.3866 14.1384 16.3866 15.0692C16.3866 16.1628 16.035 17.0115 15.3318 17.6154C14.6286 18.2192 13.7699 18.5769 12.7558 18.6884V20.75H11.2558Z"
      fill="currentColor"
    />
  </svg>
</template>
