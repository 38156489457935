<template>
  <img
    class="ui-image"
    :src="imageSet[defaultSize]"
    :alt="alt"
    :srcset="srcSet"
    :sizes="sizes"
    :width="`${dimensions[0]}px`"
    :height="`${dimensions[1]}px`"
  />
</template>

<script setup lang="ts">
import { computed, type ImgHTMLAttributes } from "vue";
import type { ImageSet } from "@/network/codecs";

interface ImageProps extends /* @vue-ignore */ Omit<ImgHTMLAttributes, "src" | "srcset"> {
  /**
   * A set of images for different screen sizes provided by our backend
   */
  imageSet: ImageSet;
  /**
   * Alt text for the image. Required for accessibility.
   * @required
   */
  alt: string;
  /**
   * Default src for the image
   * We provide a size, but it will be converted to a `src` path
   */
  defaultSize: "w64" | "w128" | "w256" | "w512" | "w800" | "w1024" | "w1600" | "w2048";
  /**
   * `sizes` attribute for the image element to help the browser decide which image to load
   * example: "100vw, (min-width: 1024px) 50vw, 1024px"
   * Required, so you won't forget to add it
   */
  sizes: string;
}

const props = defineProps<ImageProps>();
const dimensions = computed(() => props.imageSet.aspect_ratio.split(":").map(Number));

const srcSet = computed(() => {
  return Object.entries(props.imageSet)
    .filter(([key]) => key.startsWith("w"))
    .map(([size, src]) => `${src} ${size.replace("w", "")}w`)
    .join(", ");
});
</script>

<style lang="postcss" scoped>
.ui-image {
  height: auto;
}
</style>
