<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M5.34625 19.5V10.398L2.402 12.625L1.5 11.4288L12 3.5L16.173 6.6365V4.5H18.6152V8.49425L22.5095 11.4288L21.6077 12.625L18.6635 10.398V19.5H13.1155V13.8845H10.8845V19.5H5.34625ZM6.84625 18H9.38475V12.3848H14.6152V18H17.1635V9.26725L12 5.39025L6.84625 9.26725V18ZM10.0963 10.0155H13.9038C13.9038 9.51417 13.7134 9.10225 13.3328 8.77975C12.9519 8.45742 12.5077 8.29625 12 8.29625C11.4923 8.29625 11.0481 8.45717 10.6673 8.779C10.2866 9.10083 10.0963 9.513 10.0963 10.0155Z"
      fill="currentColor"
    />
  </svg>
</template>
