<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <g clip-path="url(#clip0_553_6609)">
      <path
        d="M19 8.13474C18.2372 8.13474 17.5881 7.86712 17.0529 7.33187C16.5176 6.79662 16.25 6.14758 16.25 5.38477C16.25 4.62195 16.5176 3.97292 17.0529 3.43767C17.5881 2.9024 18.2372 2.63477 19 2.63477C19.7628 2.63477 20.4118 2.9024 20.9471 3.43767C21.4823 3.97292 21.75 4.62195 21.75 5.38477C21.75 6.14758 21.4823 6.79662 20.9471 7.33187C20.4118 7.86712 19.7628 8.13474 19 8.13474ZM4.3077 19.5001C3.80257 19.5001 3.375 19.3251 3.025 18.9751C2.675 18.6251 2.5 18.1975 2.5 17.6924V6.30784C2.5 5.80271 2.675 5.37514 3.025 5.02514C3.375 4.67514 3.80257 4.50014 4.3077 4.50014H14.3308C14.2833 4.75014 14.258 4.99853 14.2548 5.24532C14.2516 5.49212 14.2705 5.74372 14.3115 6.00012C14.3833 6.52063 14.5317 7.00845 14.7567 7.46357C14.9817 7.9187 15.2666 8.32447 15.6115 8.68089L12 11.0001L4.15383 6.00012L3.99998 7.44239L12 12.5578L16.7942 9.49817C17.116 9.70075 17.4618 9.85749 17.8317 9.96839C18.2016 10.0793 18.591 10.1347 19 10.1347C19.4371 10.1347 19.8708 10.0722 20.3009 9.94724C20.731 9.82224 21.1307 9.63475 21.5 9.38477V17.6924C21.5 18.1975 21.325 18.6251 20.975 18.9751C20.625 19.3251 20.1974 19.5001 19.6923 19.5001H4.3077Z"
        fill="white"
      />
      <path
        d="M4.3077 19.5001C3.80257 19.5001 3.375 19.3251 3.025 18.9751C2.675 18.6251 2.5 18.1975 2.5 17.6924V6.30784C2.5 5.80271 2.675 5.37514 3.025 5.02514C3.375 4.67514 3.80257 4.50014 4.3077 4.50014H14.3308C14.2833 4.75014 14.258 4.99853 14.2548 5.24532C14.2516 5.49212 14.2705 5.74372 14.3115 6.00012H4.15383L12 11.0001L15.6115 8.69627C15.7872 8.86165 15.974 9.01068 16.1721 9.14337C16.3701 9.27607 16.5775 9.39433 16.7942 9.49817L12 12.5578L3.99998 7.44239V17.6924C3.99998 17.7821 4.02883 17.8559 4.08653 17.9136C4.14423 17.9713 4.21795 18.0001 4.3077 18.0001H19.6923C19.782 18.0001 19.8557 17.9713 19.9134 17.9136C19.9711 17.8559 20 17.7821 20 17.6924V10.0251C20.2871 9.96102 20.5541 9.87641 20.8009 9.77129C21.0477 9.66616 21.2807 9.53732 21.5 9.38477V17.6924C21.5 18.1975 21.325 18.6251 20.975 18.9751C20.625 19.3251 20.1974 19.5001 19.6923 19.5001H4.3077ZM19 8.13474C18.2372 8.13474 17.5881 7.86712 17.0529 7.33187C16.5176 6.79662 16.25 6.14758 16.25 5.38477C16.25 4.62195 16.5176 3.97292 17.0529 3.43767C17.5881 2.9024 18.2372 2.63477 19 2.63477C19.7628 2.63477 20.4118 2.9024 20.9471 3.43767C21.4823 3.97292 21.75 4.62195 21.75 5.38477C21.75 6.14758 21.4823 6.79662 20.9471 7.33187C20.4118 7.86712 19.7628 8.13474 19 8.13474Z"
        fill="currentColor"
      />
      <path
        d="M19 8.13474C18.2372 8.13474 17.5881 7.86712 17.0529 7.33187C16.5176 6.79662 16.25 6.14758 16.25 5.38477C16.25 4.62195 16.5176 3.97292 17.0529 3.43767C17.5881 2.9024 18.2372 2.63477 19 2.63477C19.7628 2.63477 20.4118 2.9024 20.9471 3.43767C21.4823 3.97292 21.75 4.62195 21.75 5.38477C21.75 6.14758 21.4823 6.79662 20.9471 7.33187C20.4118 7.86712 19.7628 8.13474 19 8.13474Z"
        fill="#FF7468"
      />
    </g>
    <defs>
      <clipPath id="clip0_553_6609">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
