<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M8.00001 8.90386C8.25129 8.90386 8.46475 8.81603 8.64038 8.64038C8.81603 8.46475 8.90386 8.25129 8.90386 8.00001C8.90386 7.74872 8.81603 7.53526 8.64038 7.35963C8.46475 7.18398 8.25129 7.09616 8.00001 7.09616C7.74872 7.09616 7.53526 7.18398 7.35963 7.35963C7.18398 7.53526 7.09616 7.74872 7.09616 8.00001C7.09616 8.25129 7.18398 8.46475 7.35963 8.64038C7.53526 8.81603 7.74872 8.90386 8.00001 8.90386ZM8.00001 12.9039C8.25129 12.9039 8.46475 12.816 8.64038 12.6404C8.81603 12.4647 8.90386 12.2513 8.90386 12C8.90386 11.7487 8.81603 11.5353 8.64038 11.3596C8.46475 11.184 8.25129 11.0962 8.00001 11.0962C7.74872 11.0962 7.53526 11.184 7.35963 11.3596C7.18398 11.5353 7.09616 11.7487 7.09616 12C7.09616 12.2513 7.18398 12.4647 7.35963 12.6404C7.53526 12.816 7.74872 12.9039 8.00001 12.9039ZM8.00001 16.9039C8.25129 16.9039 8.46475 16.816 8.64038 16.6404C8.81603 16.4647 8.90386 16.2513 8.90386 16C8.90386 15.7487 8.81603 15.5353 8.64038 15.3596C8.46475 15.184 8.25129 15.0962 8.00001 15.0962C7.74872 15.0962 7.53526 15.184 7.35963 15.3596C7.18398 15.5353 7.09616 15.7487 7.09616 16C7.09616 16.2513 7.18398 16.4647 7.35963 16.6404C7.53526 16.816 7.74872 16.9039 8.00001 16.9039ZM5.30773 20.5C4.8026 20.5 4.37503 20.325 4.02503 19.975C3.67503 19.625 3.50003 19.1974 3.50003 18.6923V5.30773C3.50003 4.8026 3.67503 4.37503 4.02503 4.02503C4.37503 3.67503 4.8026 3.50003 5.30773 3.50003H15.7885L20.5 8.21156V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.30773ZM5.30773 19H18.6923C18.782 19 18.8558 18.9712 18.9135 18.9135C18.9712 18.8558 19 18.782 19 18.6923V9.00001H15V5.00001H5.30773C5.21798 5.00001 5.14426 5.02886 5.08656 5.08656C5.02886 5.14426 5.00001 5.21798 5.00001 5.30773V18.6923C5.00001 18.782 5.02886 18.8558 5.08656 18.9135C5.14426 18.9712 5.21798 19 5.30773 19Z"
      fill="currentColor"
    />
  </svg>
</template>
