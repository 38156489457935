<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M12 21.5C10.7065 21.5 9.60425 21.0493 8.69325 20.148C7.78242 19.2468 7.327 18.1526 7.327 16.8652V7.827C6.82183 7.827 6.39108 7.65033 6.03475 7.297C5.67825 6.94383 5.5 6.51467 5.5 6.0095V4.327C5.5 3.82183 5.67825 3.39108 6.03475 3.03475C6.39108 2.67825 6.82183 2.5 7.327 2.5H16.6635C17.1685 2.5 17.6008 2.67825 17.9605 3.03475C18.3202 3.39108 18.5 3.82183 18.5 4.327V6.0095C18.5 6.51467 18.3202 6.94383 17.9605 7.297C17.6008 7.65033 17.1685 7.827 16.6635 7.827V17.0095C16.625 18.2647 16.1535 19.3269 15.249 20.1962C14.3445 21.0654 13.2615 21.5 12 21.5ZM7.327 6.327H16.6635C16.7532 6.327 16.8317 6.2965 16.899 6.2355C16.9663 6.17467 17 6.09933 17 6.0095V4.327C17 4.23717 16.9663 4.16025 16.899 4.09625C16.8317 4.03208 16.7532 4 16.6635 4H7.327C7.23717 4 7.16025 4.03208 7.09625 4.09625C7.03208 4.16025 7 4.23717 7 4.327V6.0095C7 6.09933 7.03208 6.17467 7.09625 6.2355C7.16025 6.2965 7.23717 6.327 7.327 6.327ZM12 20C12.8782 20 13.625 19.6971 14.2405 19.0913C14.8558 18.4856 15.1635 17.7436 15.1635 16.8652V16.4902H12.0673V14.9905H15.1635V12.5673H12.0673V11.0673H15.1635V7.827H8.827V16.8652C8.827 17.7436 9.13625 18.4856 9.75475 19.0913C10.3734 19.6971 11.1218 20 12 20Z"
      fill="currentColor"
    />
  </svg>
</template>
