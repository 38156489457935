import { type RouteLocationNormalizedGeneric, useRoute, useRouter } from "vue-router";
import { computed, ref, watchEffect } from "vue";
import { useAuthSessionStore } from "@/stores/authSessionStore";
import {
  clearInitialLocation,
  getInitialLocation,
} from "@/router/preserve-location";

/**
 * Auth state may be changed during the app lifecycle. This hook watches for auth state changes and redirects
 * to the sign-in page or the home page if needed.
 */
export default async function useAuthStateRedirect() {
  const route = useRoute();
  const router = useRouter();

  const routerIsReady = ref(false);
  router.isReady().then(() => {
    routerIsReady.value = true;
  });

  const requiresAuth = computed(() => !!route.meta.requiresAuth);
  const requiresNotAuthenticated = computed(() => !!route.meta.requiresNotAuthenticated);

  const authSessionStore = useAuthSessionStore();

  const isAuthenticated = computed(() => {
    return authSessionStore.initialized ? !!authSessionStore.session : undefined;
  });


  watchEffect(() => {
    // check if authState is initialized
    if (routerIsReady.value && isAuthenticated.value !== undefined) {
      // if authState is changed, check if we still has access to the route
      const redirectTo = checkAuthStatusRedirect({
        requiresAuth: requiresAuth.value,
        requiresNotAuthenticated: requiresNotAuthenticated.value,
        isAuthenticated: isAuthenticated.value,
      });

      if (typeof redirectTo !== "boolean") {
        router.push(redirectTo);
      }
    }
  });
}

export function checkAuthStatusRedirect(props: {
  isAuthenticated: boolean;
  requiresNotAuthenticated: boolean;
  requiresAuth: boolean;
  targetRoute?: RouteLocationNormalizedGeneric
}) {
  const { isAuthenticated, requiresNotAuthenticated, requiresAuth, targetRoute } = props;
  const initialLocation = getInitialLocation();

  if (isAuthenticated) {
    clearInitialLocation();

    if (requiresNotAuthenticated) {
      return { name: "home" };
    } else if (initialLocation) {
      const isTheSameUrl = targetRoute?.fullPath === initialLocation;
      if(isTheSameUrl) {
        // url is the same, there is no need to redirect ot the same page and mess-up the history state
        return true;
      } else {
        // url is different, redirect to the initial location
        return initialLocation;
      }
    } else {
      return true;
    }
  } else {
    if (requiresAuth) {
      return { name: "signin" };
    } else {
      return true;
    }
  }
}
