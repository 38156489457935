import ky, { HTTPError, type KyInstance, type Options } from "ky";
import JSONApiException from "@/network/JSONApiException";
import { hasInjectionContext, inject } from "vue";
import type { Pinia } from "pinia";
import { useAuthSessionStore } from "@/stores/authSessionStore";
import type Translator from "@/i18n/Translator";
import { LOCALES_CONFIG } from "@/i18n";
import { captureException } from "@sentry/vue";

/**
 * Convert HTTPError to JSONApiException if possible
 * We use JSONApiException as a handy way to access JSON:API errors
 */
async function convertErrorToJSONApiException(
  error: HTTPError,
): Promise<HTTPError | JSONApiException> {
  try {
    const body = await error.response.clone().json();
    if (body.errors && body.errors.length > 0) {
      // it is a JSON:API error. We may need to add more checks later.
      return new JSONApiException(error.response, error.request, error.options, body.errors);
    }

    return error;
  } catch (e) {
    // response was not a JSON, we can do nothing
    console.error(e);
    return error;
  }
}

/**
 * Handle expired csrf token.
 * it reloads the page on error.
 */
async function handleExpiredCSRF(
  request: Request,
  options: Options,
  response: Response,
): Promise<Response | void> {
  // Our server returns 422 for invalid csrf token.
  if (response.status !== 422) {
    return response;
  }

  const url = new URL(request.url);
  // This specific method should not cause reloading
  if (request.method === "GET" && url.pathname === "/api/v3/session") {
    return response;
  }

  try {
    const body = await response.clone().json();
    const bodyErrors: ReadonlyArray<{ code?: string }> | undefined = body.errors;
    if (
      bodyErrors &&
      bodyErrors.length > 0 &&
      bodyErrors.some((e) => e.code === "invalid_authenticity_token")
    ) {
      if (typeof window !== "undefined") {
        console.warn("Reloading page due to expired csrf token");
        window.location.reload();
      }
    }
  } catch (e) {
    // it was not an "invalid_authenticity_token" error. Just suppress the error
    console.error(e);
  }
  return undefined;
}

/**
 * Do not use `app.inject("apiClient")`. Use `useApiClient()` instead
 */
export const API_CLIENT = Symbol("apiClient");

export function useApiClient() {
  if (hasInjectionContext()) {
    return inject(API_CLIENT) as KyInstance;
  } else {
    console.error(
      "ApiClient uses context data and can only be used inside setup() function or functions that support injection context.",
    );
    throw new Error("ApiClient used outside of injection context");
  }
}

export function createApiClient(pinia: Pinia, translator: Translator<any>) {
  const authSessionStore = useAuthSessionStore(pinia);
  return ky.extend({
    timeout: false,
    retry:
      // unsafe solution to avoid `AggregateError [ECONNREFUSED]:` error in the local development
      // FIXME: solve the issue with the infrastructure and remove this workaround (wee need help form DevOps)
      import.meta.env.VITE_PROXY_PATCH_DISABLED !== "true"
        ? {
            limit: 3,
            methods: ["get", "put", "delete", "post"],
          }
        : undefined,
    hooks: {
      beforeError: [convertErrorToJSONApiException],
      beforeRequest: [
        (request) => {
          const httpConfig = {
            lang: translator.currentLocale,
            csrf: authSessionStore.csrfToken,
          };

          if (!request.headers.has("X-Language")) {
            const backendLocale = LOCALES_CONFIG[httpConfig.lang]?.backendLocale;
            if (backendLocale) {
              request.headers.set("X-Language", backendLocale);
            } else {
              captureException(
                new Error(
                  `The app is on "${httpConfig.lang}" locale, but we could not find a corresponding backend locale`,
                ),
              );
            }
          }
          if (request.method !== "GET") {
            if (httpConfig.csrf) {
              request.headers.set("X-CSRF-Token", httpConfig.csrf);
            }
          }
        },
      ],
      afterResponse: [handleExpiredCSRF],
    },
    prefixUrl: `${import.meta.env.VITE_API_HOST}${import.meta.env.VITE_API_PATH}`,
  });
}
