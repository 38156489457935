<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M13.4819 21.5C11.8174 21.5 10.3995 20.9163 9.22819 19.749C8.05686 18.5817 7.47119 17.1653 7.47119 15.5V14.9537C6.12119 14.7782 4.99203 14.1763 4.08369 13.148C3.17536 12.1198 2.72119 10.9038 2.72119 9.5V3.75H5.72119V2.75H7.22119V6.25H5.72119V5.25H4.22119V9.5C4.22119 10.6 4.61286 11.5417 5.39619 12.325C6.17953 13.1083 7.12119 13.5 8.22119 13.5C9.32119 13.5 10.2629 13.1083 11.0462 12.325C11.8295 11.5417 12.2212 10.6 12.2212 9.5V5.25H10.7212V6.25H9.22119V2.75H10.7212V3.75H13.7212V9.5C13.7212 10.9038 13.267 12.1198 12.3587 13.148C11.4504 14.1763 10.3212 14.7782 8.97119 14.9537V15.5C8.97119 16.75 9.40961 17.8125 10.2864 18.6875C11.1633 19.5625 12.228 20 13.4807 20C14.7307 20 15.7932 19.5625 16.6682 18.6875C17.5432 17.8125 17.9807 16.75 17.9807 15.5V13.8828C17.455 13.7276 17.0224 13.4253 16.6827 12.976C16.3429 12.5267 16.1729 12.0154 16.1729 11.4423C16.1729 10.7319 16.4218 10.1281 16.9194 9.63075C17.4169 9.13342 18.0211 8.88475 18.7319 8.88475C19.4426 8.88475 20.0464 9.13342 20.5432 9.63075C21.04 10.1281 21.2884 10.7319 21.2884 11.4423C21.2884 12.0154 21.1185 12.5267 20.7787 12.976C20.439 13.4253 20.0064 13.7276 19.4807 13.8828V15.5C19.4807 17.1653 18.897 18.5817 17.7297 19.749C16.5624 20.9163 15.1464 21.5 13.4819 21.5ZM18.7307 12.5C19.0269 12.5 19.2772 12.3978 19.4817 12.1933C19.6862 11.9888 19.7884 11.7384 19.7884 11.4423C19.7884 11.1461 19.6862 10.8958 19.4817 10.6915C19.2772 10.487 19.0269 10.3848 18.7307 10.3848C18.4345 10.3848 18.1842 10.487 17.9797 10.6915C17.7752 10.8958 17.6729 11.1461 17.6729 11.4423C17.6729 11.7384 17.7752 11.9888 17.9797 12.1933C18.1842 12.3978 18.4345 12.5 18.7307 12.5Z"
      fill="currentColor"
    />
  </svg>
</template>
