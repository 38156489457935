<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11.25 19.57V12.4315L4.99997 8.81223V15.7776C4.99997 15.8289 5.0128 15.877 5.03845 15.9219C5.06408 15.9667 5.10254 16.0052 5.15383 16.0373L11.25 19.57ZM12.75 19.57L18.8461 16.0373C18.8974 16.0052 18.9359 15.9667 18.9615 15.9219C18.9872 15.877 19 15.8289 19 15.7776V8.81223L12.75 12.4315V19.57ZM11.0961 21.2064L4.40385 17.3545C4.11923 17.1904 3.89744 16.9712 3.73847 16.6968C3.57949 16.4225 3.5 16.1212 3.5 15.793V8.2084C3.5 7.8802 3.57949 7.57893 3.73847 7.30458C3.89744 7.03021 4.11923 6.81098 4.40385 6.64688L11.0961 2.79498C11.3807 2.63088 11.682 2.54883 12 2.54883C12.3179 2.54883 12.6192 2.63088 12.9038 2.79498L19.5961 6.64688C19.8807 6.81098 20.1025 7.03021 20.2615 7.30458C20.4205 7.57893 20.5 7.8802 20.5 8.2084V15.793C20.5 16.1212 20.4205 16.4225 20.2615 16.6968C20.1025 16.9712 19.8807 17.1904 19.5961 17.3545L12.9038 21.2064C12.6192 21.3705 12.3179 21.4526 12 21.4526C11.682 21.4526 11.3807 21.3705 11.0961 21.2064ZM15.8557 8.90073L18.175 7.56995L12.1538 4.08725C12.1025 4.0552 12.0513 4.03918 12 4.03918C11.9487 4.03918 11.8974 4.0552 11.8461 4.08725L9.67495 5.33725L15.8557 8.90073ZM12 11.1392L14.325 9.79303L8.14998 6.22378L5.82495 7.56995L12 11.1392Z"
      fill="currentColor"
    />
  </svg>
</template>
