<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M19.675 20.3557L20.298 19.7327L18.4423 17.877V15.1155H17.5577V18.2385L19.675 20.3557ZM5.30775 20.5C4.80908 20.5 4.38308 20.3234 4.02975 19.9703C3.67658 19.6169 3.5 19.1909 3.5 18.6923V5.30775C3.5 4.80908 3.67658 4.38308 4.02975 4.02975C4.38308 3.67658 4.80908 3.5 5.30775 3.5H18.6923C19.1909 3.5 19.6169 3.67658 19.9703 4.02975C20.3234 4.38308 20.5 4.80908 20.5 5.30775V11.6328C20.2538 11.5276 20.0073 11.4385 19.7605 11.3655C19.5137 11.2923 19.2602 11.234 19 11.1905V5.30775C19 5.23075 18.9679 5.16025 18.9038 5.09625C18.8398 5.03208 18.7692 5 18.6923 5H5.30775C5.23075 5 5.16025 5.03208 5.09625 5.09625C5.03208 5.16025 5 5.23075 5 5.30775V18.6923C5 18.7692 5.03208 18.8398 5.09625 18.9038C5.16025 18.9679 5.23075 19 5.30775 19H11.1655C11.2027 19.2768 11.2578 19.5387 11.3308 19.7855C11.4039 20.0323 11.493 20.2705 11.598 20.5H5.30775ZM5 18V19V5V11.1905V11.1155V18ZM7.25 16.6345H11.2673C11.3109 16.3743 11.3757 16.1208 11.4615 15.874C11.5475 15.6272 11.6411 15.3808 11.7423 15.1348H7.25V16.6345ZM7.25 12.75H13.5905C14.0212 12.3847 14.4808 12.0786 14.9693 11.8317C15.4578 11.5849 15.9808 11.4096 16.5385 11.3058V11.25H7.25V12.75ZM7.25 8.86525H16.75V7.3655H7.25V8.86525ZM18 22.5578C16.7513 22.5578 15.6892 22.1199 14.8135 21.2443C13.9378 20.3686 13.5 19.3064 13.5 18.0577C13.5 16.8091 13.9378 15.7469 14.8135 14.8713C15.6892 13.9956 16.7513 13.5577 18 13.5577C19.2487 13.5577 20.3108 13.9956 21.1865 14.8713C22.0622 15.7469 22.5 16.8091 22.5 18.0577C22.5 19.3064 22.0622 20.3686 21.1865 21.2443C20.3108 22.1199 19.2487 22.5578 18 22.5578Z"
      fill="currentColor"
    />
  </svg>
</template>
