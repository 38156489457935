<template>
  <span :class="['icon-frame', kind]" :style="computedStyles">
    <slot />
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    kind?: "default" | "small";
    background?: string;
    activeBackground?: string;
    hoverBackground?: string;
  }>(),
  {
    kind: "default",
    background: "transparent",
    activeBackground: "transparent",
    hoverBackground: "transparent",
  },
);

const computedStyles = computed(() => ({
  "--color-background": props.background,
  "--color-hover-background":
    (props.hoverBackground !== "transparent" && props.hoverBackground) || props.background,
  "--color-active-background":
    (props.activeBackground !== "transparent" && props.activeBackground) || props.background,
  "--color-hover-border":
    (props.hoverBackground !== "transparent" && "var(--r-color-content-50)") || "transparent",
}));
</script>

<style lang="postcss" scoped>
.icon-frame {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;

  background-color: var(--color-background);
  border: 1px solid var(--color-background);
  border-radius: 50%;

  &.default {
    width: 3rem;
    height: 3rem;
  }

  &.small {
    width: 2.5rem;
    height: 2.5rem;
  }

  @media (--hoverable) {
    &:hover {
      background-color: var(--color-hover-background);
      border-color: var(--color-hover-border);
    }
  }

  /* stylelint-disable-next-line order/order */
  &:active {
    background-color: var(--color-active-background);
    border-color: var(--color-active-background);
  }
}
</style>
