<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <g clip-path="url(#clip0_1505_10400)">
      <path
        d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30773C3.5 4.8026 3.675 4.37503 4.025 4.02503C4.375 3.67503 4.80257 3.50003 5.3077 3.50003H15.7884L20.5 8.21156V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM7.99998 16.9039C8.25126 16.9039 8.46472 16.816 8.64035 16.6404C8.816 16.4647 8.90383 16.2513 8.90383 16C8.90383 15.7487 8.816 15.5353 8.64035 15.3596C8.46472 15.184 8.25126 15.0962 7.99998 15.0962C7.74869 15.0962 7.53523 15.184 7.3596 15.3596C7.18395 15.5353 7.09613 15.7487 7.09613 16C7.09613 16.2513 7.18395 16.4647 7.3596 16.6404C7.53523 16.816 7.74869 16.9039 7.99998 16.9039ZM7.99998 12.9039C8.25126 12.9039 8.46472 12.816 8.64035 12.6404C8.816 12.4647 8.90383 12.2513 8.90383 12C8.90383 11.7487 8.816 11.5353 8.64035 11.3596C8.46472 11.184 8.25126 11.0962 7.99998 11.0962C7.74869 11.0962 7.53523 11.184 7.3596 11.3596C7.18395 11.5353 7.09613 11.7487 7.09613 12C7.09613 12.2513 7.18395 12.4647 7.3596 12.6404C7.53523 12.816 7.74869 12.9039 7.99998 12.9039ZM7.99998 8.90386C8.25126 8.90386 8.46472 8.81603 8.64035 8.64038C8.816 8.46475 8.90383 8.25129 8.90383 8.00001C8.90383 7.74872 8.816 7.53526 8.64035 7.35963C8.46472 7.18398 8.25126 7.09616 7.99998 7.09616C7.74869 7.09616 7.53523 7.18398 7.3596 7.35963C7.18395 7.53526 7.09613 7.74872 7.09613 8.00001C7.09613 8.25129 7.18395 8.46475 7.3596 8.64038C7.53523 8.81603 7.74869 8.90386 7.99998 8.90386ZM15 9.00001H19L15 5.00001V9.00001Z"
        fill="white"
      />
      <path
        d="M7.99998 8.90386C8.25126 8.90386 8.46472 8.81603 8.64035 8.64038C8.816 8.46475 8.90383 8.25129 8.90383 8.00001C8.90383 7.74872 8.816 7.53526 8.64035 7.35963C8.46472 7.18398 8.25126 7.09616 7.99998 7.09616C7.74869 7.09616 7.53523 7.18398 7.3596 7.35963C7.18395 7.53526 7.09613 7.74872 7.09613 8.00001C7.09613 8.25129 7.18395 8.46475 7.3596 8.64038C7.53523 8.81603 7.74869 8.90386 7.99998 8.90386ZM7.99998 12.9039C8.25126 12.9039 8.46472 12.816 8.64035 12.6404C8.816 12.4647 8.90383 12.2513 8.90383 12C8.90383 11.7487 8.816 11.5353 8.64035 11.3596C8.46472 11.184 8.25126 11.0962 7.99998 11.0962C7.74869 11.0962 7.53523 11.184 7.3596 11.3596C7.18395 11.5353 7.09613 11.7487 7.09613 12C7.09613 12.2513 7.18395 12.4647 7.3596 12.6404C7.53523 12.816 7.74869 12.9039 7.99998 12.9039ZM7.99998 16.9039C8.25126 16.9039 8.46472 16.816 8.64035 16.6404C8.816 16.4647 8.90383 16.2513 8.90383 16C8.90383 15.7487 8.816 15.5353 8.64035 15.3596C8.46472 15.184 8.25126 15.0962 7.99998 15.0962C7.74869 15.0962 7.53523 15.184 7.3596 15.3596C7.18395 15.5353 7.09613 15.7487 7.09613 16C7.09613 16.2513 7.18395 16.4647 7.3596 16.6404C7.53523 16.816 7.74869 16.9039 7.99998 16.9039ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30773C3.5 4.8026 3.675 4.37503 4.025 4.02503C4.375 3.67503 4.80257 3.50003 5.3077 3.50003H15.7884L20.5 8.21156V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.782 19 18.8557 18.9712 18.9134 18.9135C18.9711 18.8558 19 18.782 19 18.6923V9.00001H15V5.00001H5.3077C5.21795 5.00001 5.14423 5.02886 5.08653 5.08656C5.02883 5.14426 4.99997 5.21798 4.99997 5.30773V18.6923C4.99997 18.782 5.02883 18.8558 5.08653 18.9135C5.14423 18.9712 5.21795 19 5.3077 19Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1505_10400">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
