import { defineStore } from "pinia";
import { denormalizeUnknown } from "@/network/denormalize";
import { SignUpTokenTokenHolderC, type SessionEligibility } from "@/network/codecs";
import jsonApiErrorParser, { squashErrors } from "@/utils/errorParsers/jsonApiParser";
import type { SquashedMappedErrors } from "@/utils/errorParsers/types";
import router from "@/router";
import type { LocationQuery } from "vue-router";
import JSONApiException from "@/network/JSONApiException";
import { useAuthSessionStore } from "./authSessionStore";

export const LS_SIGN_UP_FORM_DATA_KEY = "SignUpFormData";

const parseDOB = (year: string, month: string, day: string) => {
  const result = new Date(
    Date.UTC(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day)),
  ).toISOString();
  return result;
};

const denormalizeData = (data: any) => {
  const dob =
    data.birthdayYear && data.birthdayMonth && data.birthdayDay
      ? parseDOB(data.birthdayYear, data.birthdayMonth, data.birthdayDay)
      : null;

  const result: { [key: string]: any } = {};

  if (data.email) result.email = data.email;
  if (data.fname) result.fname = data.fname;
  if (data.lname) result.lname = data.lname;
  if (data.employeeId) result.employee_id = data.employeeId;
  if (data.companyId) result.company_id = data.companyId;
  if (dob) result.dob = dob;
  if (data.phone) result.phone = data.phone;
  if (data.password) result.password = data.password;
  if (data.passwordConfirmation) result.password_confirmation = data.passwordConfirmation;
  if (data.zip) result.zip = data.zip;
  if (data.sexAtBirth) result.sex = data.sexAtBirth;
  if (data.race) result.race = data.race;
  if (data.genderId) result.gender = data.genderId;
  if (data.path) result.care_path = data.path;
  if (data.pregnancyWeek) result.pregnancy_week = data.pregnancyWeek;
  if (data.planType) result.care_plan_type = data.planType;
  if (data.conditions) result.health_conditions = data.conditions;
  if (data.partnerEmail) result.partner_email = data.partnerEmail;
  if (data.genderSelfDescription) result.gender_self_description = data.genderSelfDescription;

  return result;
};

const redirectOnSessionExpiredError = (error: unknown, path: string) => {
  if (error instanceof JSONApiException) {
    if (
      error.errors[0].code === "invalid_authenticity_token" ||
      error.errors[0].code === "invalid_signup_token"
    ) {
      router.push({
        path,
        state: { error: error.errors[0].title || "Your session has expired, please try again" },
      });
    }
  }
};

export const useSignUpStore = defineStore("signUp", {
  state: () => ({
    rawData: null as unknown,
    meta: null as unknown,
    /**
     * Whether the store has been initialized, so we do not fetch the data again and again
     */
    initialized: false,
    tokenError: null as unknown,
    isLoading: false,
    confirmed_company_id: "" as string,
    token: {} as { email_signup_token?: string; partner_invitation_token?: string },
    mappedErrors: {} as SquashedMappedErrors,
    isJustRegistered: false,
    isEligibilityDetailsSkipped: false,
    eligibilityManualStatus: null as
      | SessionEligibility["eligibility_check_status"]
      | "manual-retry"
      | "timeout-review",
  }),
  getters: {
    tokenData: (state) => {
      return state.rawData ? SignUpTokenTokenHolderC.mask(state.rawData) : null;
    },
  },
  actions: {
    async fetchCheckToken({ email_signup_token, partner_invitation_token }: LocationQuery) {
      try {
        if (email_signup_token) this.token.email_signup_token = String(email_signup_token);
        if (partner_invitation_token)
          this.token.partner_invitation_token = String(partner_invitation_token);

        this.tokenError = null;
        const response = await this.apiClient
          .post("v3/registration/check_signup_token", { json: this.token })
          .json();
        this.rawData = (denormalizeUnknown(response) as any).data;
      } catch (error) {
        let errorText;
        if (error instanceof JSONApiException) {
          errorText = jsonApiErrorParser({})(error).restErrors[0];
        } else {
          errorText = "Unknown error, please try again later";
        }

        localStorage.removeItem(LS_SIGN_UP_FORM_DATA_KEY);

        router.push({ path: "/signup", state: { error: errorText } });
      }
    },
    async fetchSignUpStep1(data: any) {
      try {
        const denormalizedData = denormalizeData(data);

        this.isLoading = true;
        const result: { confirmed_company_id: string } = await this.apiClient
          .post("v3/registration/validate_basic", { json: { ...this.token, ...denormalizedData } })
          .json();

        if (result && result.confirmed_company_id) {
          this.confirmed_company_id = result.confirmed_company_id;
        }

        this.mappedErrors.error = undefined;
        this.mappedErrors.fieldErrors = {};
        this.isLoading = false;
        // TODO: refactor needed
        return { ok: true, confirmed_company_id: this.confirmed_company_id };
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        const parsedErrors = jsonApiErrorParser({
          // companyId: ["data/attributes/company_id"], // disable it to show error when no country select rendered
          birthdayMonth: ["data/attributes/dob"],
          fname: ["data/attributes/fname"],
          lname: ["data/attributes/lname"],
          password: ["data/attributes/password"],
          passwordConfirmation: ["data/attributes/password_confirmation"],
          phone: ["data/attributes/phone"],
          zip: ["data/attributes/zip"],
          employeeId: ["data/attributes/employee_id"],
          personalInfo: [
            "data/attributes/base",
            (error) =>
              !!error.source &&
              error.source.pointer === "data/attributes/base" &&
              error.code === "invalid_personal_info",
          ],
        })(error);
        this.mappedErrors = squashErrors(parsedErrors);

        redirectOnSessionExpiredError(error, "/signup");
      }
    },

    async fetchSignUpStep2(data: any) {
      try {
        this.isLoading = true;
        await this.apiClient
          .post("v3/registration/validate_care_plan", {
            json: {
              ...this.token,
              sex: data.sexAtBirth,
              race: data.race,
              gender: data.genderId,
              care_path: data.path,
              care_plan_type: data.planType,
              pregnancy_week: data.pregnancyWeek,
              gender_self_description: data.genderSelfDescription,
            },
          })
          .json();
        this.mappedErrors.error = undefined;
        this.mappedErrors.fieldErrors = {};
        this.isLoading = false;
        return true;
      } catch (error) {
        this.isLoading = false;
        const parsedErrors = jsonApiErrorParser({
          sexAtBirth: ["data/attributes/sex"],
          path: ["data/attributes/care_path"],
          pregnancyWeek: ["data/attributes/pregnancy_week"],
          genderId: ["data/attributes/gender"],
          race: ["data/attributes/race"],
          planType: ["data/attributes/care_plan_type"],
        })(error);
        this.mappedErrors = squashErrors(parsedErrors);

        redirectOnSessionExpiredError(error, "/signup");
      }
    },

    async fetchSignUpStep3(data: any) {
      try {
        this.isLoading = true;
        await this.apiClient
          .post("v3/registration/validate_health_conditions", {
            json: {
              ...this.token,
              sex: data.sexAtBirth,
              race: data.race,
              gender: data.genderId,
              care_path: data.path,
              pregnancy_week: data.pregnancyWeek,
              care_plan_type: data.planType,
              health_conditions: data.conditions,
            },
          })
          .json();
        this.mappedErrors.error = undefined;
        this.mappedErrors.fieldErrors = {};
        this.isLoading = false;
        return true;
      } catch (error) {
        this.isLoading = false;
        const parsedErrors = jsonApiErrorParser({
          conditions: ["data/attributes/health_conditions"],
        })(error);
        this.mappedErrors = squashErrors(parsedErrors);

        redirectOnSessionExpiredError(error, "/signup");
      }
    },

    async fetchSignUpStep4(data: any) {
      try {
        this.isLoading = true;
        await this.apiClient
          .post("v3/registration/validate_partner_invitation", {
            json: {
              ...this.token,
              partner_email: data.partnerEmail,
              // company_id: this.confirmed_company_id,
            },
          })
          .json();
        this.mappedErrors.error = undefined;
        this.mappedErrors.fieldErrors = {};
        this.isLoading = false;
        return true;
      } catch (error) {
        this.isLoading = false;
        const parsedErrors = jsonApiErrorParser({
          partnerEmail: ["data/attributes/partner_email"],
        })(error);
        this.mappedErrors = squashErrors(parsedErrors);

        redirectOnSessionExpiredError(error, "/signup");
      }
    },

    async fetchSignUpFinal(data: any) {
      try {
        this.isLoading = true;
        const denormalizedData = denormalizeData(data);
        const authSessionStore = useAuthSessionStore();

        const result = await this.apiClient
          .post("v3/registration", {
            json: { ...this.token, ...denormalizedData, company_id: this.confirmed_company_id },
          })
          .json();

        await authSessionStore.updateSession(
          (denormalizeUnknown(result) as any).data,
          typeof result === "object" && result !== null && "meta" in result ? result.meta : null,
        );

        // TODO: review this:
        // can't redirect without delay for unknow reason
        await delay(1);
        this.isJustRegistered = true;
        this.mappedErrors.error = undefined;
        this.mappedErrors.fieldErrors = {};
        this.isLoading = false;
        return true;
      } catch (error) {
        this.isLoading = false;
        const parsedErrors = jsonApiErrorParser({
          partnerEmail: ["data/attributes/partner_email"],
        })(error);
        this.mappedErrors = squashErrors(parsedErrors);

        redirectOnSessionExpiredError(error, "/signup");
      }
    },

    skipEligibilityDetails() {
      this.isEligibilityDetailsSkipped = true;
    },

    setManualEligibilityStatus(
      status: SessionEligibility["eligibility_check_status"] | "manual-retry" | "timeout-review",
    ) {
      this.eligibilityManualStatus = status;
    },
  },
});

function delay(time: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      return resolve();
    }, time);
  });
}
