<template>
  <HomePageView
    :care-tasks="careTasksStore.careTasks"
    :upcoming-calls="videoVisitsStore.upcomingCalls"
    :payable-bills="billsStore.payableBills"
    :care-navigator="authSessionStore.session?.relationships.care_navigator!"
    :care-navigator-unread-messages-count="unreadCountersStore.careNavigatorUnreadMessagesCount"
  />
</template>

<script lang="ts">
import { useCareTasksStore } from "@/stores/careTasksStore";
import { useVideoVisitsStore } from "@/stores/videoVisitsStore";
import { useBillsStore } from "@/stores/billsStore";
import { useSignUpStore } from "@/stores/signUpStore";

export default {
  beforeRouteEnter: async () => {
    const careTasksStore = useCareTasksStore();
    const videoVisitsStore = useVideoVisitsStore();
    const billsStore = useBillsStore();
    const signUpStore = useSignUpStore();

    await Promise.all([
      careTasksStore.fetchTasks(),
      videoVisitsStore.fetchUpcomingCalls(),
      billsStore.fetchBills(),
    ]);

    // Hide SignupEligibility modal after home page loaded
    signUpStore.setManualEligibilityStatus(null);
  },
};
</script>

<script setup lang="ts">
import { useAuthSessionStore } from "@/stores/authSessionStore";
import { useUnreadCountersStore } from "@/stores/unreadCountersStore";
import HomePageView from "./HomePageView.vue";

const careTasksStore = useCareTasksStore();
const videoVisitsStore = useVideoVisitsStore();
const authSessionStore = useAuthSessionStore();
const unreadCountersStore = useUnreadCountersStore();
const billsStore = useBillsStore();
</script>
