<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M3.25 21.75V12.2885L4.8155 7.702C4.9295 7.32633 5.146 7.03208 5.465 6.81925C5.78417 6.60642 6.125 6.5 6.4875 6.5C6.6035 6.5 6.72175 6.5125 6.84225 6.5375C6.96275 6.5625 7.08008 6.60192 7.19425 6.65575L11.402 8.5H13.75V10H11.048L8.14625 8.7385L6.75 12.9615V21.75H3.25ZM9.25 18V16.5H20.75V18H9.25ZM19.0985 15.5C18.7252 15.5 18.4071 15.3693 18.1443 15.108C17.8814 14.8467 17.75 14.5293 17.75 14.156C17.75 13.7828 17.8807 13.4648 18.142 13.202C18.4033 12.9392 18.7206 12.8077 19.0938 12.8077C19.4671 12.8077 19.7852 12.9384 20.048 13.1997C20.3108 13.4609 20.4423 13.7782 20.4423 14.1515C20.4423 14.5248 20.3116 14.8429 20.0503 15.1058C19.7889 15.3686 19.4717 15.5 19.0985 15.5ZM13.0577 15.5C12.5606 15.5 12.135 15.323 11.781 14.969C11.427 14.615 11.25 14.1894 11.25 13.6923V12.5H9.25V11H11.8463C12.1023 11 12.3169 11.0866 12.4902 11.2598C12.6634 11.4331 12.75 11.6477 12.75 11.9038V13H15.25V11H16.75V13.6923C16.75 14.1894 16.573 14.615 16.219 14.969C15.865 15.323 15.4394 15.5 14.9423 15.5H13.0577ZM7.90375 5.19225C7.39608 5.19225 6.9615 5.0115 6.6 4.65C6.2385 4.2885 6.05775 3.85392 6.05775 3.34625C6.05775 2.83858 6.2385 2.40392 6.6 2.04225C6.9615 1.68075 7.39608 1.5 7.90375 1.5C8.41142 1.5 8.84608 1.68075 9.20775 2.04225C9.56925 2.40392 9.75 2.83858 9.75 3.34625C9.75 3.85392 9.56925 4.2885 9.20775 4.65C8.84608 5.0115 8.41142 5.19225 7.90375 5.19225Z"
      fill="currentColor"
    />
  </svg>
</template>
