<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M9.55005 17.6538L4.21545 12.3192L5.28468 11.25L9.55005 15.5154L18.7154 6.34998L19.7847 7.41918L9.55005 17.6538Z"
      fill="currentColor"
    />
  </svg>
</template>
