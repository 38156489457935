<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M4.50002 20.5V11.2402L2.63277 12.673L1.73077 11.477L12 3.625L22.2693 11.477L21.3673 12.6578L19.5 11.2402V20.5H4.50002ZM6.00002 19H18V10.1L12 5.525L6.00002 10.1V19Z"
      fill="currentColor"
    />
  </svg>
</template>
