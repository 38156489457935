<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M6.23071 13.5C5.81821 13.5 5.46513 13.3531 5.17146 13.0592C4.87763 12.7656 4.73071 12.4125 4.73071 12C4.73071 11.5875 4.87763 11.2344 5.17146 10.9408C5.46513 10.6469 5.81821 10.5 6.23071 10.5C6.64321 10.5 6.99638 10.6469 7.29021 10.9408C7.58388 11.2344 7.73071 11.5875 7.73071 12C7.73071 12.4125 7.58388 12.7656 7.29021 13.0592C6.99638 13.3531 6.64321 13.5 6.23071 13.5ZM12 13.5C11.5875 13.5 11.2344 13.3531 10.9407 13.0592C10.6469 12.7656 10.5 12.4125 10.5 12C10.5 11.5875 10.6469 11.2344 10.9407 10.9408C11.2344 10.6469 11.5875 10.5 12 10.5C12.4125 10.5 12.7655 10.6469 13.0592 10.9408C13.353 11.2344 13.5 11.5875 13.5 12C13.5 12.4125 13.353 12.7656 13.0592 13.0592C12.7655 13.3531 12.4125 13.5 12 13.5ZM17.7692 13.5C17.3567 13.5 17.0035 13.3531 16.7097 13.0592C16.416 12.7656 16.2692 12.4125 16.2692 12C16.2692 11.5875 16.416 11.2344 16.7097 10.9408C17.0035 10.6469 17.3567 10.5 17.7692 10.5C18.1817 10.5 18.5348 10.6469 18.8285 10.9408C19.1223 11.2344 19.2692 11.5875 19.2692 12C19.2692 12.4125 19.1223 12.7656 18.8285 13.0592C18.5348 13.3531 18.1817 13.5 17.7692 13.5Z"
      fill="currentColor"
    />
  </svg>
</template>
