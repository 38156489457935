import { v4 } from "uuid";
import { captureException } from "@sentry/vue";
import type { Session } from "@/stores/authSessionStore";
import { isValidLocale, LOCALES_CONFIG } from "@/i18n";
import { compare } from "compare-versions";

/**
 * Using this hook you can ask ReactNative for a question.
 * It is the only way to communicate with ReactNative from the webview.
 */
export function askQuestion(question: string, cb: (result: unknown) => void) {
  if (!window.ReactNativeWebView) {
    throw new Error("Not in ReactNativeWebView");
  }
  const messageId = v4();

  window.ReactNativeWebViewAnswerHandlers = window.ReactNativeWebViewAnswerHandlers || {};

  window.ReactNativeWebViewAnswerHandlers[messageId] = (result) => {
    if (window.ReactNativeWebViewAnswerHandlers) {
      delete window.ReactNativeWebViewAnswerHandlers[messageId];
    }

    cb(result);
  };

  window.ReactNativeWebView.postMessage(
    JSON.stringify({
      action: question,
      messageId,
    }),
  );
}

/**
 * We need to force ReactNative to refresh its config to respect the `new_ux` cookie.
 */
export function refreshRNConfig() {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ action: "refresh_frontend_config" }));
  }
}

export function openAppSettings() {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "open_settings", new_ux: true }),
    );
  } else {
    throw new Error("no webview detected");
  }
}

function syncSession(session: Session | null) {
  if (session === null) {
    // Well, it is actually not required, it seems the mobile app sign-outs user if we show the `/signin` page (or the `/signout`).
    // And we will show this page, so it should be fine as it is now.
    console.warn("Not implemented: syncSession(null)");
    return;
  }

  try {
    if (!session.attributes.sendbird_credentials.access_token) {
      throw new Error("No SendBird access token");
    }
    if (window.ReactNativeWebView) {
      const payload: {
        ok: true;
        user_id: number;
        preferred_language: string; // common locale like "en"
        sendbird: {
          app_id: string;
          access_token: string;
          user_id: string;
          channel_url: string;
        };
        care_navigator: {
          userpic_url: string | null; // 80х80 // maybe a little bigger for retina
        };
      } = {
        care_navigator: {
          userpic_url: session.relationships.care_navigator.attributes.image_set?.w128 || null,
        },
        ok: true,
        preferred_language: session.attributes.preferred_language,
        sendbird: {
          access_token: session.attributes.sendbird_credentials.access_token,
          app_id: session.attributes.sendbird_credentials.app_id,
          channel_url: session.attributes.sendbird_credentials.channel_url,
          user_id: session.attributes.sendbird_credentials.user_id,
        },
        user_id: Number(session.id),
      };

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "set_session",
          payload,
        }),
      );
    }
  } catch (e) {
    captureException(e, {
      extra: {
        source: "Failed to sync session to ReactNativeWebView",
      },
    });
  }
}

/**
 * RN does not want to be notified of the session object each time we open the webview.
 * It will ask if the session is set already.
 * If not, we will send it.
 */
export function syncSessionWithRNSecured(session: Session | null) {
  if (!window.ReactNativeWebView) {
    return;
  }
  syncSession(session);
}

declare global {
  interface Window {
    twillioIdentity: string;
    webkitAudioContext: AudioContext;
    ReactNativeWebView?: {
      postMessage: (s: string) => void;
    };
    /**
     * ReactNative can asynchronously call this function to pass result of a PostMessage to the webview
     */
    ReactNativeWebViewAnswerHandlers?: Partial<Record<string, (result: unknown) => void>>;
    /**
     * WebView can declare if it supports various features
     */
    ReactNativeWebViewFeatures?: {
      /**
       * @see: ReactNativeWebViewAnswerHandlers
       */
      questions?: true;
      webchat?: true;
    };
    /**
     * update just lingui's locale, withoout storing or full reload
     * @param locale
     */
    setLocale: (locale: string) => void;
    /**
     * Fairly full reload, with storing locale in localStorage
     * @param locale
     */
    updateLocale: (locale: string) => void;
    /**
     * ReactNative can call these functions to pass events to the webview
     */
    ReactNativePlaidSdkOpen?: () => void;
    ReactNativePlaidSdkSuccess?: (payload: {
      publicToken: string;
      metadata: { accounts: ReadonlyArray<{ id: string }> };
    }) => void;
    /**
     * We expect error: null if exit and error: {errorCode: string} if error
     * but in fact it can be errorCode: "" if exit
     * @param payload
     */
    ReactNativePlaidSdkExit?: (payload: { error?: null | { errorCode: string } }) => void;
    /**
     * Some info about the platform, so we can proxy it to the backend
     */
    ReactNativePlatform?: {
      version: string;
      build: string;
      codepushVersion: string;
      os: "ios" | "android";
    };
  }
}

/**
 * ReactNative needs to know when the locale changes
 */
export function sendLocaleToRN(localeToSend: string) {
  if (window.ReactNativeWebView) {
    if (isValidLocale(localeToSend)) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "change_locale",
          locale: LOCALES_CONFIG[localeToSend].reactNativeLocale,
        }),
      );
    }
  }
}

export function syncCSRFTokenWithRN(token: string) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "set_csrf_token",
        payload: {
          token,
        },
      }),
    );
  }
}

export function openCall(token: string) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "open_call",
        token,
      }),
    );
  }
}

export function sendPlaidTokenToRN(token: string) {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "plaid_token",
        token,
      }),
    );
  }
}

const minimumVersion = "1.7.0";

/**
 * Returns true if the mobile app is outdated.
 *
 * In this case we block the user from using the app and ask them to update
 *  The block is done somewhere in layouts (e.g. src/layouts/EmptyLayout.vue)
 */
export function isMobileAppOutdated(): boolean {
  const isRN = !!window.ReactNativeWebView;
  if (!isRN) return false;

  const platformData = window.ReactNativePlatform;

  if (!platformData) {
    console.warn(
      "We are inside the mobile app but have no platform data" +
        "Is it so old that it doesn't even have the platform data?",
      {
        platformData,
      },
    );
    return true;
  }

  const { version } = platformData;
  if (!version) {
    console.warn("Version is not defined", { version });
    return true;
  }

  console.info(`mobile app version is "${version}" which is enough`, {
    version,
    minimumVersion
  })

  return compare(version, minimumVersion, "<");
}
