import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router";
import HomePage from "@/pages/HomePage/HomePage.vue";
import { useAuthSessionStore } from "@/stores/authSessionStore";
import { checkAuthStatusRedirect } from "@/composables/useAuthStateRedirect";
import { inject, type InjectionKey, nextTick, type Ref } from "vue";
import PageMeta from "@/router/PageMeta.vue";
import { app } from "@/app";
import checkHasNewVersion from "@/utils/checkHasNewVersion";
import { captureException } from "@sentry/vue";
import NotFoundPage from "@/components/error/NotFoundPage.vue";
import { useProgressBarStore } from "@/stores/progressBarStore";
import { useRouterStore } from "@/stores/routerStore";
import type { TFunction } from "@/i18n/Translator";

declare module "vue-router" {
  interface RouteMeta {
    /**
     * If `true`, the user must be authenticated to access this route or its children, otherwise they will be redirected to the sign-in page.
     */
    requiresAuth?: boolean;
    /**
     * If `true`, the user must not be authenticated to access this route or its children, otherwise they will be redirected to the home page.
     */
    requiresNotAuthenticated?: boolean;
    /**
     * The title of the page used by the layout.
     * If a function is provided, it will be called to get the title, which is useful for dynamic titles and translations.
     */
    title?: string | ((t: TFunction) => string);
    /**
     * The title of the document, which is used by the browser tab.
     */
    documentTitle?: string | ((t: TFunction) => string);
    /**
     * The path to navigate back to.
     * You can use the `:param` syntax to include dynamic parameters.
     */
    backNavigationPath?: string;

    /**
     * Whether to show the back button in the layout.
     * If true, the back button will NOT be shown.
     */
    isMain?: boolean;
  }
}

const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: "/",
    component: PageMeta,
    children: [
      {
        path: "/",
        name: "empty_layout_authenticated",
        component: wrapAsyncImport(() => import("@/layouts/EmptyLayout.vue")),
        meta: { requiresAuth: true },
        children: [
          {
            path: "data-sharing-consent",
            name: "data-sharing-consent",
            component: wrapAsyncImport(
              () => import("@/pages/after-signup/data-sharing-consent/DataSharingConsent.vue"),
            ),
            beforeEnter() {
              const authSessionStore = useAuthSessionStore();
              if (authSessionStore.hasToGiveDataSharingConsent) {
                return true;
              } else {
                return { name: "home" };
              }
            },
            meta: {
              title: (t) => t("routerPageTitles::pages::dataSharingConsent"),
            },
          },
          {
            path: "eligibility-details",
            name: "eligibility-details",
            component: wrapAsyncImport(
              () => import("@/pages/after-signup/eligibility/SignupEligibility.vue"),
            ),
            beforeEnter() {
              const authSessionStore = useAuthSessionStore();
              if (authSessionStore.hasToAddEligibilityDetails) {
                return true;
              } else {
                return { name: "home" };
              }
            },
          },
          {
            path: "/",
            name: "migration_guard",
            component: wrapAsyncImport(
              () => import("@/layouts/global-guards/MigrateUserGuard.vue"),
            ),
            beforeEnter() {
              const authSessionStore = useAuthSessionStore();

              if (authSessionStore.hasToGiveDataSharingConsent) {
                return { name: "data-sharing-consent" };
              } else if (authSessionStore.hasToAddEligibilityDetails) {
                return { name: "eligibility-details" };
              } else {
                return true;
              }
            },
            children: [
              {
                // Required for state when user just registered and
                // session eligibility.require_eligibility_check is false
                // but we still have to propose fill in eligibility data
                // and it can be skipped
                path: "/",
                name: "eligibility_details_soft_guard",
                component: wrapAsyncImport(
                  () => import("@/layouts/global-guards/EligibilityDetailsGuard.vue"),
                ),
                children: [
                  {
                    path: "/",
                    name: "default_layout",
                    component: wrapAsyncImport(() => import("@/layouts/DefaultLayout.vue")),
                    children: [
                      // Home page route
                      {
                        path: "",
                        name: "home",
                        component: HomePage,
                        meta: {
                          title: (t) => t("routerPageTitles::pages::home"),
                        },
                      },

                      {
                        path: "profile",
                        redirect() {
                          return "/settings/basics";
                        },
                      },
                      {
                        path: "settings",
                        children: [
                          {
                            path: "",
                            name: "settings",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsPage.vue"),
                            ),
                            meta: {
                              isMain: true,
                              title: (t) => t("routerPageTitles::pages::settings"),
                            },
                          },
                          {
                            path: "basics",
                            name: "settingsBasics",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsBasics.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsBasics"),
                              backNavigationPath: "/settings",
                            },
                          },
                          {
                            path: "eligibility",
                            name: "settingsEligibility",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsEligibility.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsEligibility"),
                              backNavigationPath: "/settings/basics",
                            },
                          },
                          {
                            path: "personal-details",
                            name: "settingsPersonalDetails",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsPersonalDetails.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsPersonalDetails"),
                              backNavigationPath: "/settings/basics",
                            },
                          },
                          {
                            path: "health-goal",
                            name: "settingsHealthGoal",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsHealthGoal.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsUpdateGoal"),
                              backNavigationPath: "/settings",
                            },
                          },
                          {
                            path: "health-partner",
                            name: "settingsHealthPartner",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsHealthPartner.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsHealthPartner"),
                              backNavigationPath: "/settings",
                            },
                          },
                          {
                            path: "language",
                            name: "settingsLanguage",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsLanguagePage.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsLanguage"),
                              backNavigationPath: "/settings",
                            },
                          },
                          {
                            path: "password",
                            name: "settingsPassword",
                            component: wrapAsyncImport(
                              () => import("@/pages/settings/SettingsChangePassword.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::settingsChangePassword"),
                              backNavigationPath: "/settings",
                            },
                          },
                        ],
                      },
                      {
                        path: "providers",
                        children: [
                          {
                            path: "",
                            name: "providerCategories",
                            component: wrapAsyncImport(
                              () =>
                                import(
                                  "@/pages/providers/ProviderCategories/ProviderCategories.vue"
                                ),
                            ),
                            meta: {
                              isMain: true,
                              title: (t) => t("routerPageTitles::pages::providers"),
                            },
                          },
                          {
                            path: ":providerCategory",
                            children: [
                              {
                                path: "",
                                name: "providerCategory",
                                component: wrapAsyncImport(
                                  () =>
                                    import(
                                      "@/pages/providers/ProviderCategory/ProviderCategory.vue"
                                    ),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::providers"),
                                  backNavigationPath: "/providers",
                                },
                              },
                              {
                                path: ":providerId/book-appointment",
                                name: "providerBookAppointment",
                                component: wrapAsyncImport(
                                  () =>
                                    import(
                                      "@/pages/providers/ProviderBookAppointment/ProviderBookAppointment.vue"
                                    ),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::bookAppointment"),
                                  backNavigationPath: "/providers/:providerCategory",
                                },
                              },
                            ],
                          },
                        ],
                      },
                      // Care tasks routes
                      {
                        path: "care-tasks/:careTaskId",
                        name: "careTask",
                        component: wrapAsyncImport(
                          () => import("@/pages/care-tasks/CareTask/CareTask.vue"),
                        ),
                        meta: {
                          title: (t) => t("routerPageTitles::pages::careTask"),
                        },
                      },

                      {
                        path: "care-team",
                        name: "careTeam",
                        component: wrapAsyncImport(() => import("@/pages/care-team/CareTeam.vue")),
                        meta: {
                          isMain: true,
                          title: (t) => t("routerPageTitles::pages::careTeam"),
                        },
                      },

                      {
                        path: "video-visits",
                        children: [
                          {
                            path: "",
                            name: "videoVisitCategories",
                            component: wrapAsyncImport(
                              () =>
                                import("@/pages/video-visits/VisitCategories/VisitCategories.vue"),
                            ),
                            meta: {
                              isMain: true,
                              title: (t) => t("routerPageTitles::pages::videoVisits"),
                            },
                          },
                          {
                            path: ":visitCategory",
                            children: [
                              {
                                path: "",
                                name: "videoVisitCategory",
                                component: wrapAsyncImport(
                                  () =>
                                    import("@/pages/video-visits/VisitCategory/VisitCategory.vue"),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::videoVisits"),
                                  backNavigationPath: "/video-visits",
                                },
                              },
                              {
                                path: ":visitCallSubject",
                                children: [
                                  {
                                    path: "",
                                    name: "videoVisitCategoryCallSubject",
                                    component: wrapAsyncImport(
                                      () =>
                                        import(
                                          "@/pages/video-visits/VisitCallSubject/VisitCallSubject.vue"
                                        ),
                                    ),
                                    meta: {
                                      title: (t) => t("videoVisits::categoriesPage::pageTitle"),
                                      backNavigationPath: "/video-visits/:visitCategory",
                                    },
                                  },
                                  {
                                    path: ":visitExpert",
                                    children: [
                                      {
                                        path: "schedule/:date",
                                        name: "videoVisitCategoryCallSubjectScheduleConfirmation",
                                        component: wrapAsyncImport(
                                          () =>
                                            import(
                                              "@/pages/video-visits/VisitScheduleConfirmation/VisitScheduleConfirmation.vue"
                                            ),
                                        ),
                                        meta: {
                                          title: (t) =>
                                            t("routerPageTitles::pages::scheduleConfirmation"),
                                          backNavigationPath:
                                            "/video-visits/:visitCategory/:visitCallSubject",
                                        },
                                      },
                                      {
                                        path: "reschedule/:date",
                                        name: "videoVisitCategoryCallSubjectRescheduleConfirmation",
                                        component: wrapAsyncImport(
                                          () =>
                                            import(
                                              "@/pages/video-visits/VisitScheduleConfirmation/VisitScheduleConfirmation.vue"
                                            ),
                                        ),
                                        meta: {
                                          title: (t) =>
                                            t("routerPageTitles::pages::scheduleConfirmation"),
                                          backNavigationPath:
                                            "/video-visits/:visitCategory/:visitCallSubject/:visitExpert",
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            path: "call-subjects/:visitCallSubject",
                            children: [
                              {
                                path: "",
                                name: "videoVisitCallSubject",
                                component: wrapAsyncImport(
                                  () =>
                                    import(
                                      "@/pages/video-visits/VisitCallSubject/VisitCallSubject.vue"
                                    ),
                                ),
                                meta: {
                                  title: (t) => t("videoVisits::categoriesPage::pageTitle"),
                                  backNavigationPath: "/video-visits",
                                },
                              },
                              {
                                path: ":visitExpert",
                                name: "videoVisitCallSubjectSelectedExpert",
                                component: wrapAsyncImport(
                                  () =>
                                    import(
                                      "@/pages/video-visits/VisitCallSubject/VisitCallSubject.vue"
                                    ),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::videoVisits"),
                                  backNavigationPath: "/video-visits",
                                },
                              },
                            ],
                          },
                          {
                            path: "upcoming/:visitToken",
                            name: "videoVisitUpcoming",
                            component: wrapAsyncImport(
                              () => import("@/pages/video-visits/VisitUpcoming/VisitUpcoming.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::videoVisits"),
                              backNavigationPath: "/video-visits",
                            },
                          },
                        ],
                      },
                      // Chat routes
                      {
                        path: "care-navigator",
                        name: "careNavigator",
                        component: wrapAsyncImport(
                          () => import("@/pages/care-partner/ChatPage.vue"),
                        ),
                        meta: {
                          backNavigationPath: "/",
                          title: (t) => t("chat::chatPage::title"),
                          documentTitle: (t) => t("page_title::chat"),
                        },
                      },

                      // Notification routes
                      {
                        path: "notifications",
                        name: "notifications",
                        component: wrapAsyncImport(
                          () => import("@/pages/notifications/NotificationsPage.vue"),
                        ),
                        meta: {
                          isMain: true,
                          backNavigationPath: "/",
                          title: (t) => t("notifications::notificationsPage::title"),
                          documentTitle: (t) => t("page_title::notifications"),
                        },
                        children: [
                          {
                            path: ":notificationUid",
                            redirect: (to) => {
                              // This route comes from old UX with type=bill
                              // We should redirect /notifications/A-TEST-XXX?type=bill to /payments?notification-uid=A-TEST-XXX
                              // And then it will be transformed to /payments?event-id=XXX if it is found in GET v3/bills
                              if (to.query["type"] === "bill") {
                                return {
                                  path: "/payments",
                                  query: { "notification-uid": to.params.notificationUid },
                                };
                              }

                              return {
                                path: "/notifications",
                                query: { "notification-uid": to.params.notificationUid },
                              };
                            },
                          },
                          {
                            path: ":notificationUid/:eventId",
                            redirect: (to) => {
                              // This route comes from old UX with type=bill
                              // We should redirect /notifications/A-TEST-XXX/YYY?type=bill to /payments?notification-uid=A-TEST-XXX
                              // And then it will be transformed to /payments?event-id=XXX if it is found in GET v3/bills
                              if (to.query["type"] === "bill") {
                                return {
                                  path: "/payments",
                                  query: { "notification-uid": to.params.notificationUid },
                                };
                              }

                              return {
                                path: "/notifications",
                                query: { "event-id": to.params.eventId },
                              };
                            },
                          },
                        ],
                      },

                      // Payments routes
                      {
                        path: "payments",
                        name: "payments",
                        component: wrapAsyncImport(
                          () => import("@/pages/payments/PaymentsPage.vue"),
                        ),
                        meta: {
                          isMain: true,
                          backNavigationPath: "/",
                          title: (t) => t("notifications::paymentsPage::title"),
                          documentTitle: (t) => t("page_title::payments"),
                        },
                        children: [
                          {
                            path: ":notificationUid",
                            redirect: (to) => {
                              return {
                                path: "/payments",
                                query: { "notification-uid": to.params.notificationUid },
                              };
                            },
                          },
                          {
                            path: ":notificationUid/:eventId",
                            redirect: (to) => {
                              return {
                                path: "/payments",
                                query: { "notification-uid": to.params.notificationUid },
                              };
                            },
                          },
                        ],
                      },
                      {
                        path: "bills",
                        children: [
                          {
                            path: "oauth",
                            name: "billOauth",
                            component: wrapAsyncImport(
                              () => import("@/pages/bill/oauth/BillOauth.vue"),
                            ),
                            meta: {
                              backNavigationPath: "/payments",
                              title: (t) => t("notifications::paymentsPage::title"),
                              documentTitle: () => "Payments",
                            },
                          },
                          {
                            path: ":billId",
                            name: "bill",
                            component: wrapAsyncImport(() => import("@/pages/bill/BillPage.vue")),
                            meta: {
                              backNavigationPath: "/payments",
                              title: (t) => t("notifications::paymentsPage::title"),
                              documentTitle: () => "Payments",
                            },
                          },
                        ],
                      },
                      {
                        path: "employer-policies",
                        children: [
                          {
                            path: "",
                            name: "employerPolicies",
                            component: wrapAsyncImport(
                              () =>
                                import(
                                  "@/pages/employer-policies/EmployerPolicies/EmployerPolicies.vue"
                                ),
                            ),
                            meta: {
                              isMain: true,
                              title: (t) => t("routerPageTitles::pages::employerPolicies"),
                            },
                          },
                          {
                            path: ":policyId",
                            name: "employerPolicy",
                            component: wrapAsyncImport(
                              () =>
                                import(
                                  "@/pages/employer-policies/EmployerPolicy/EmployerPolicy.vue"
                                ),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::employerPolicies"),
                              backNavigationPath: "/employer-policies",
                            },
                          },
                        ],
                      },

                      // Old project links redirects.
                      // TODO: Need to remove after Backend will update database so this will never happen

                      // /programs -> /covered-programs
                      { path: "/programs", redirect: "/covered-programs" },
                      {
                        path: "/programs/:categoryId",
                        redirect: (to) => {
                          return `/covered-programs/${(to.params.categoryId as string).replaceAll("-", "_")}`;
                        },
                      },
                      {
                        path: "/programs/:categoryId/:programId",
                        redirect: (to) => {
                          return `/covered-programs/${(to.params.categoryId as string).replaceAll("-", "_")}/${(to.params.programId as string).replaceAll("-", "_")}`;
                        },
                      },

                      // /schedule/calls/new?category -> /video-visits/call-subjects/:category
                      {
                        path: "/schedule/calls/new",
                        redirect: (to) => {
                          return `/video-visits/call-subjects/${(to.query.category as string).replaceAll("-", "_")}`;
                        },
                      },
                      // End old project links redirect.

                      {
                        path: "covered-programs",
                        children: [
                          {
                            path: "",
                            name: "coveredProgramsCategories",
                            component: wrapAsyncImport(
                              () =>
                                import(
                                  "@/pages/covered-programs/CoveredCategories/CoveredCategories.vue"
                                ),
                            ),
                            meta: {
                              isMain: true,
                              title: (t) => t("routerPageTitles::pages::coveredPrograms"),
                            },
                          },
                          {
                            path: ":categoryId",
                            children: [
                              {
                                path: "",
                                name: "coveredProgramsCategory",
                                component: wrapAsyncImport(
                                  () =>
                                    import(
                                      "@/pages/covered-programs/CoveredCategory/CoveredCategory.vue"
                                    ),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::coveredPrograms"),
                                  backNavigationPath: "/covered-programs",
                                },
                              },
                              {
                                path: ":programId",
                                name: "coveredProgram",
                                component: wrapAsyncImport(
                                  () =>
                                    import(
                                      "@/pages/covered-programs/CoveredProgram/CoveredProgram.vue"
                                    ),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::coveredPrograms"),
                                  backNavigationPath: "/covered-programs/:categoryId",
                                },
                              },
                            ],
                          },
                        ],
                      },

                      {
                        path: "reimbursement",
                        children: [
                          {
                            path: "",
                            name: "reimbursement",
                            component: wrapAsyncImport(
                              () => import("@/pages/reimbursement/ReimbursementPage.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::reimbursement"),
                            },
                          },
                          {
                            path: "success",
                            name: "reimbursementSuccess",
                            component: wrapAsyncImport(
                              () => import("@/pages/reimbursement/ReimbursementSuccess.vue"),
                            ),
                            meta: {
                              title: (t) => t("routerPageTitles::pages::reimbursement"),
                            },
                          },
                        ],
                      },
                      {
                        path: "insights",
                        children: [
                          {
                            path: "",
                            name: "insights",
                            component: wrapAsyncImport(
                              () => import("@/pages/posts/InsightsPage.vue"),
                            ),
                            meta: {
                              isMain: true,
                              title: (t) => t("routerPageTitles::pages::insights"),
                            },
                          },
                          {
                            path: ":topic",
                            children: [
                              {
                                path: "",
                                name: "topic",
                                component: wrapAsyncImport(
                                  () => import("@/pages/posts/TopicPage.vue"),
                                ),
                                meta: {
                                  isMain: true,
                                  title: (t) => t("routerPageTitles::pages::insights"),
                                  backNavigationPath: "/insights",
                                },
                              },
                              {
                                path: ":article",
                                name: "article",
                                component: wrapAsyncImport(
                                  () => import("@/pages/posts/ArticlePage.vue"),
                                ),
                                meta: {
                                  title: (t) => t("routerPageTitles::pages::insights"),
                                  backNavigationPath: "/insights/:topic",
                                },
                              },
                            ],
                          },
                        ],
                      },
                      {
                        path: "/all-providers",
                        name: "allProviders",
                        component: wrapAsyncImport(
                          () => import("@/pages/all-providers/AllProvidersPage.vue"),
                        ),
                        meta: {
                          title: (t) => t("routerPageTitles::pages::allProviders"),
                          backNavigationPath: "/",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/",
        name: "empty_layout_anyone",
        component: wrapAsyncImport(() => import("@/layouts/EmptyLayout.vue")),
        meta: {}, // can be accessed by anyone
        children: [
          // Email confirmation
          {
            path: "confirmation",
            name: "emailConfirmation",
            component: wrapAsyncImport(
              () => import("@/pages/email-confirmation/EmailConfirmation.vue"),
            ),
            meta: {
              title: "Email confirmation",
              backNavigationPath: "/",
            },
          },

          // Video call
          {
            path: "join/:token",
            name: "videoCallExpertPage",
            component: wrapAsyncImport(() => import("@/pages/video-calls/VideoCallExpertPage.vue")),
            meta: {
              title: "Video call",
              backNavigationPath: "/video-visits",
              noMobileRedirect: true,
            },
          },
          {
            path: "calls/:token",
            name: "videoCallMemberPage",
            component: wrapAsyncImport(() => import("@/pages/video-calls/VideoCallMemberPage.vue")),
            meta: {
              title: "Video call",
              backNavigationPath: "/video-visits",
            },
          },
          {
            path: "/pages/download_apps",
            name: "DownloadAppsPage",
            component: wrapAsyncImport(
              () => import("@/components/download-app/DownloadAppsCallPage.vue"),
            ),
            meta: {
              title: "Download Apps",
              backNavigationPath: "/video-visits",
            },
          },
          {
            path: "/unlock",
            name: "UnlockPage",
            component: wrapAsyncImport(() => import("@/pages/unlock/UnlockPage.vue")),
            meta: {
              noMobileRedirect: true,
            },
          },
        ],
      },

      {
        path: "/",
        name: "authentication_layout",
        component: wrapAsyncImport(() => import("@/layouts/AuthenticationLayout.vue")),
        meta: { requiresAuth: false, requiresNotAuthenticated: true },
        children: [
          {
            path: "signin",
            name: "signin",
            component: wrapAsyncImport(() => import("@/pages/authentication/SignInPage.vue")),
            meta: {
              documentTitle: (t) => t("page_title::signin"),
            },
          },
          {
            path: "signup",
            name: "activateMembership",
            component: wrapAsyncImport(
              () => import("@/pages/authentication/ActivateMembership.vue"),
            ),
            meta: {
              documentTitle: (t) => t("page_title::activate_membership"),
            },
          },
          {
            path: "password/new",
            name: "forgotPassword",
            component: wrapAsyncImport(() => import("@/pages/authentication/ForgotPassword.vue")),
            meta: {
              documentTitle: (t) => t("page_title::forgot_password"),
            },
          },
          {
            path: "password/edit",
            name: "changePassword",
            component: wrapAsyncImport(() => import("@/pages/authentication/ChangePassword.vue")),
            meta: {
              documentTitle: (t) => t("page_title::change_password"),
            },
          },
        ],
      },

      {
        path: "/",
        name: "registration_layout",
        component: wrapAsyncImport(() => import("@/layouts/EmptyLayout.vue")),
        meta: { requiresAuth: false, requiresNotAuthenticated: true },
        children: [
          {
            path: "signup/continue",
            name: "signUp",
            component: wrapAsyncImport(() => import("@/pages/authentication/SignUpPage.vue")),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from) {
    // if we are in the same component but with query params added we should not scroll
    if (to.path === from.path) {
      return {};
    }

    return { top: 0 };
  },
  routes,
});

export default router;

export const routerShowErrorPage = Symbol("routerShowErrorPage") as InjectionKey<Ref<unknown>>;

/**
 * Check authentication status. This is a global guard and only way to redirect before initial page load.
 */
router.beforeEach(async (to) => {
  const routerStore = useRouterStore();
  const progressBar = useProgressBarStore();
  const authSessionStore = useAuthSessionStore();

  const requiresAuth = !!to.meta.requiresAuth;
  const requiresNotAuthenticated = !!to.meta.requiresNotAuthenticated;

  routerStore.startTransition(to);

  // before we navigate to a new route - check if there is a new version as it might require a page reload to load the new version
  const hasANewVersion = await checkHasNewVersion();
  if (hasANewVersion) {
    console.info("New version detected. Reloading the page to load the new version");
    window.location.reload();
  }

  await authSessionStore.fetchData();
  const isAuthenticated = authSessionStore.session !== null;

  // Remove scroll lock if we proceed to another page from modal
  document.body.classList.remove("scroll-locked");

  if (progressBar) {
    progressBar.start();
  }

  return checkAuthStatusRedirect({
    isAuthenticated,
    requiresNotAuthenticated,
    requiresAuth,
    targetRoute: to,
  });
});

router.afterEach(async (to, from, failure) => {
  const progressBar = useProgressBarStore();
  const routerStore = useRouterStore();

  if (!failure) {
    /**
     * The first time user enters the app the `from` argument seems to be an empty odd route.
     * We are not interested in this case and should exclude it
     */
    const initialRoute = from.fullPath === "/" && from.name === undefined;
    const routeAlreadyMarked = "canGoBack" in history.state;

    if (!routeAlreadyMarked) {
      history.replaceState(
        {
          ...history.state,
          canGoBack: !initialRoute, // this can be used to provide "app-like" back navigation
        },
        "",
      );
    }
  }

  if (!failure) {
    // next tick is to ensure that the page is fully mounted before capturing the pageview
    await nextTick(() => {
      // code is from docs https://posthog.com/docs/libraries/vue-js#capturing-pageleaves
      app.config.globalProperties.$posthog.capture("$pageleave", {
        $current_url: `${window.location.host}${from.fullPath}`,
        path: from.fullPath,
      });
      app.config.globalProperties.$posthog.capture("$pageview", { path: to.fullPath });
    });
  }

  if (progressBar) {
    progressBar.done();
  }

  routerStore.endTransition();
});

router.onError((error) => {
  // TODO: make a PR to fix this in vue-router. VueRouter should use runWithContext internally for `onError` handler
  app.runWithContext(() => {
    const progressBar = useProgressBarStore();
    const routerError = inject(routerShowErrorPage);
    captureException(error, {
      tags: {
        reason: "vue-router-error",
      },
    });
    if (routerError) {
      routerError.value = error;
    }
    progressBar.done();
  });
});

function wrapAsyncImport<T>(cb: () => Promise<T>): () => Promise<T> {
  return async (): Promise<T> => {
    try {
      return await cb();
    } catch (err) {
      captureException(err, {
        extra: {
          message: "Failed to load async component",
        },
        tags: {
          reason: "lazy-load-failed",
        },
      });
      window.location.reload();

      return undefined as any as T; // not reachable line
    }
  };
}
