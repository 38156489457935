<template>
  <div
    aria-live="polite"
    aria-atomic="false"
    aria-relevant="additions"
    class="notifications-container"
  >
    <UINotification
      v-for="notification in uiNotificationStore.notifications"
      :key="notification.id"
      :notification="notification"
      @remove="uiNotificationStore.remove(notification.id)"
      @show="uiNotificationStore.show(notification.id)"
      @hide="uiNotificationStore.hide(notification.id)"
    />
  </div>
</template>

<script setup lang="ts">
import UINotification from "./UINotification.vue";
import { useUINotificationStore } from "@/stores/UINotificationStore";

const uiNotificationStore = useUINotificationStore();
</script>

<style lang="postcss" scoped>
.notifications-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
