import { defineStore } from "pinia";

function clamp(n: number, min: number, max: number): number {
  if (n < min) return min;
  if (n > max) return max;
  return n;
}

function increment(currentValue: number, amount?: number) {
  if (currentValue >= 100) {
    return currentValue;
  }
  if (amount === undefined) {
    amount = Math.random() * 4;
  }


  return clamp(currentValue + amount, 0, 99.4);
}

type TimerType = ReturnType<typeof setInterval>;

export const useProgressBarStore = defineStore("progressBar", {
  state: () => ({
    progress: 0,
    timer: null as TimerType | null,
  }),
  actions: {
    start() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.progress = 0;
      this.progress = increment(this.progress);
      this.timer = setInterval(() => {
        this.progress = increment(this.progress);
      }, 800) as unknown as TimerType;
    },
    done() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.progress = increment(30 + Math.floor(50 * Math.random()));
      setTimeout(() => {
        this.progress = 100;
        setTimeout(() => {
          this.progress = 0;
        }, 200);
      }, 200);
    },
  },
});
