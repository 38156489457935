import { defineStore } from "pinia";
import { BillC, PlaidBillC } from "@/network/codecs";
import { denormalizeUnknown } from "@/network/denormalize";
import { array } from "superstruct";

export const useBillsStore = defineStore("bills", {
  state: () => ({
    billsRawData: null as unknown,
    billRawData: null as unknown,
    isPageLoading: false,
  }),
  getters: {
    bills: (state) => {
      return state.billsRawData ? array(BillC).mask(state.billsRawData) : [];
    },
    bill: (state) => {
      if (!state.billRawData) return null;
      // review this
      return PlaidBillC.mask(state.billRawData);
    },
    payableBills: (state) => {
      return state.billsRawData
        ? array(BillC)
            .mask(state.billsRawData)
            .filter(
              (bill) => bill.attributes.state === "requested" || bill.attributes.state === "failed",
            )
        : [];
    },
  },
  actions: {
    async fetchBills() {
      const response = await this.apiClient.get("v3/bills").json();
      this.billsRawData = (denormalizeUnknown(response) as any).data;
    },
    async fetchBillData(billId: string) {
      const response = await this.apiClient
        .get(`v3/bills/${billId}`, {
          headers: {
            platform: window.ReactNativePlatform?.os ?? "web",
          },
        })
        .json();

      this.billRawData = (denormalizeUnknown(response) as any).data;
    },
    async makePayment(
      payload: {
        accountId: string;
        publicToken: string;
        uid: string;
      },
      callbacks: { onError: (err: any) => void; onSettled: () => void },
    ) {
      try {
        await this.apiClient.post(`v3/bills/${payload.uid}/pay`, {
          json: {
            account_id: payload.accountId,
            public_token: payload.publicToken,
          },
        });
        await this.fetchBillData(payload.uid);
      } catch (e) {
        console.error("Error in makePayment", e);
        callbacks.onError(e);
      } finally {
        callbacks.onSettled();
      }
    },
    async markBillAsRead(authorizationId: string) {
      const rawBill = (
        this.billsRawData as Array<{
          id: string;
          attributes: {
            unread_at: string | null;
            authorization_id: string;
          };
        }>
      ).find((bill) => bill.attributes.authorization_id === authorizationId);
      if (rawBill) {
        if (rawBill.attributes.unread_at === null) {
          // Already read
          return;
        }
        await this.apiClient.put(`v3/notifications/${authorizationId}/read`);
        rawBill.attributes.unread_at = null;
        return;
      }

      console.error(`Bill  with authorization_id ${authorizationId} not found to mark as read`);
    },
    async fetchBillsPage() {
      this.isPageLoading = true;

      try {
        await this.fetchBills();
      } catch (error) {
        console.error("Failed to fetch bills page:", error);
      } finally {
        this.isPageLoading = false;
      }
    },
    async fetchBillPage(billId: string) {
      this.isPageLoading = true;

      try {
        await this.fetchBillData(billId);
      } catch (error) {
        console.error("Failed to fetch bill page:", error);
      } finally {
        this.isPageLoading = false;
      }
    },
  },
});
