<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M17.5727 27.0449L28.7949 15.8227L27.2415 14.2864L17.5727 23.9659L12.6987 19.092L11.1732 20.6283L17.5727 27.0449ZM20.0028 35.8333C17.8243 35.8333 15.7734 35.4178 13.8501 34.5867C11.9267 33.7556 10.2478 32.623 8.81312 31.189C7.37848 29.755 6.24543 28.0768 5.41396 26.1543C4.58248 24.2318 4.16675 22.1813 4.16675 20.0028C4.16675 17.8129 4.5823 15.7546 5.41341 13.8277C6.24453 11.9007 7.37708 10.2246 8.81108 8.79925C10.2451 7.37386 11.9233 6.24543 13.8458 5.41396C15.7683 4.58249 17.8188 4.16675 19.9972 4.16675C22.1871 4.16675 24.2455 4.58231 26.1724 5.41342C28.0993 6.24453 29.7755 7.37244 31.2008 8.79716C32.6262 10.2219 33.7547 11.8973 34.5861 13.8234C35.4176 15.7494 35.8333 17.8074 35.8333 19.9972C35.8333 22.1758 35.4178 24.2267 34.5867 26.15C33.7556 28.0733 32.6276 29.7523 31.2029 31.187C29.7782 32.6216 28.1028 33.7547 26.1767 34.5861C24.2507 35.4176 22.1927 35.8333 20.0028 35.8333Z"
      fill="currentColor"
    />
  </svg>
</template>
