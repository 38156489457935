<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M12.9557 18.0865L15 16.8462L17.0443 18.0865L16.502 15.7462L18.3 14.2037L15.9443 13.998L15 11.798L14.0557 13.998L11.7 14.2037L13.498 15.7462L12.9557 18.0865ZM6.30775 14V15.5H4.30775C3.80908 15.5 3.38308 15.3234 3.02975 14.9703C2.67658 14.6169 2.5 14.1909 2.5 13.6923V4.30775C2.5 3.80908 2.67658 3.38308 3.02975 3.02975C3.38308 2.67658 3.80908 2.5 4.30775 2.5H13.6923C14.1909 2.5 14.6169 2.67658 14.9703 3.02975C15.3234 3.38308 15.5 3.80908 15.5 4.30775V6.30775H14V4.30775C14 4.21792 13.9712 4.14417 13.9135 4.0865C13.8558 4.02883 13.7821 4 13.6923 4H4.30775C4.21792 4 4.14417 4.02883 4.0865 4.0865C4.02883 4.14417 4 4.21792 4 4.30775V13.6923C4 13.7821 4.02883 13.8558 4.0865 13.9135C4.14417 13.9712 4.21792 14 4.30775 14H6.30775ZM10.3078 21.5C9.80908 21.5 9.38308 21.3234 9.02975 20.9703C8.67658 20.6169 8.5 20.1909 8.5 19.6923V10.3078C8.5 9.80908 8.67658 9.38308 9.02975 9.02975C9.38308 8.67658 9.80908 8.5 10.3078 8.5H19.6923C20.1909 8.5 20.6169 8.67658 20.9703 9.02975C21.3234 9.38308 21.5 9.80908 21.5 10.3078V19.6923C21.5 20.1909 21.3234 20.6169 20.9703 20.9703C20.6169 21.3234 20.1909 21.5 19.6923 21.5H10.3078ZM10.3078 20H19.6923C19.7821 20 19.8558 19.9712 19.9135 19.9135C19.9712 19.8558 20 19.7821 20 19.6923V10.3078C20 10.2179 19.9712 10.1442 19.9135 10.0865C19.8558 10.0288 19.7821 10 19.6923 10H10.3078C10.2179 10 10.1442 10.0288 10.0865 10.0865C10.0288 10.1442 10 10.2179 10 10.3078V19.6923C10 19.7821 10.0288 19.8558 10.0865 19.9135C10.1442 19.9712 10.2179 20 10.3078 20Z"
      fill="currentColor"
    />
  </svg>
</template>
