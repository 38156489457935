<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M10.4248 17.8078V7.19226L18.3863 12.5L10.4248 17.8078ZM11.9248 15L15.6901 12.5L11.9248 10V15Z"
      fill="currentColor"
    />
    <path d="M6.61743 17.8078V7.19226H8.11743V17.8078H6.61743Z" fill="currentColor" />
  </svg>
</template>
