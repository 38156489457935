import type { MappedErrors, SquashedMappedErrors } from "./types";
import defaultErrorParser from "./defaultParser";
import JSONApiException, { type JSONApiError } from "@/network/JSONApiException";
import type { FieldErrors } from "@/composables/useForm";

/**
 * The simplest criteria - checks for `source.pointer` to be equal to `fieldName`
 */
type FieldName = string;

/**
 * For more complex criteria - pass a function that returns `true` if the error matches the criteria
 */
type FieldCondition = (error: JSONApiError) => boolean;

type Criteria = FieldName | FieldCondition | ReadonlyArray<FieldName | FieldCondition>;
/**
 * You can configure the parser to map errors to fields in your form.
 * If there are errors that do not match any of the criteria, they will be returned in `restErrors` array,
 * so you can show them above the form.
 */
export type JsonApiErrorParserConfig<F extends string> = Record<F, Criteria>;

export default function jsonApiErrorParser<F extends string>(
  config: JsonApiErrorParserConfig<F>,
): (error: unknown) => MappedErrors {
  return (error: unknown) => parseError(error, config);
}

export function parseError<F extends string>(
  e: unknown,
  config: JsonApiErrorParserConfig<F>,
): MappedErrors {
  if (e instanceof JSONApiException) {
    const result: MappedErrors = {
      fieldErrors: {},
      restErrors: [],
    };

    for (const error of e.errors) {
      const fieldNames = checkError(error, config);
      if (fieldNames) {
        for (const fieldName of fieldNames) {
          const chunkToStore = result.fieldErrors[fieldName as F] || [];
          chunkToStore.push(error.title);
          result.fieldErrors[fieldName as F] = chunkToStore;
        }
      } else {
        result.restErrors.push(error.title);
      }
    }

    return result;
  } else {
    return defaultErrorParser(e);
  }
}

function checkError<F extends string>(
  error: JSONApiError,
  config: JsonApiErrorParserConfig<F>,
): Array<F> | null {
  const fieldNames: Array<F> = [];
  for (const [fieldName, criteria] of Object.entries(config)) {
    if (checkCriteria(error, criteria as Criteria)) {
      fieldNames.push(fieldName as F);
    }
  }
  return fieldNames.length ? fieldNames : null;
}

function checkCriteria(error: JSONApiError, criteria: Criteria): boolean {
  if (typeof criteria === "string") {
    if (checkStringError(error, criteria)) {
      return true;
    }
  } else if (typeof criteria === "function") {
    if (criteria(error)) {
      return true;
    }
  } else if (isArray(criteria)) {
    for (const criterion of criteria) {
      if (typeof criterion === "string") {
        if (checkStringError(error, criterion)) {
          return true;
        }
      } else {
        if (criterion(error)) {
          return true;
        }
      }
    }
  } else {
    throw new TypeError("Invalid criteria type", {
      cause: criteria,
    });
  }

  return false;
}

function checkStringError(error: JSONApiError, fieldName: FieldName): boolean {
  return error.source?.pointer === fieldName;
}

function isArray<T>(value: T | ReadonlyArray<T>): value is ReadonlyArray<T> {
  return Array.isArray(value);
}

/**
 * Most of the places in the UI can show only one error per field.
 * This function takes the first error from each array of errors.
 */
export function squashErrors(sourceErrors: MappedErrors): SquashedMappedErrors {
  const fieldErrors: FieldErrors = {};
  for (const [fieldName, errors] of Object.entries(sourceErrors.fieldErrors)) {
    fieldErrors[fieldName] = (errors && errors[0]) || undefined;
  }
  return {
    error: sourceErrors.restErrors[0] || undefined,
    fieldErrors,
  };
}
