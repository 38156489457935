<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path d="M5.5 12.7499V11.25H18.5V12.7499H5.5Z" fill="currentColor" />
  </svg>
</template>
