<template>
  <router-link :to="`/video-visits/upcoming/${call.attributes.token}`" class="reminder">
    <span class="left-block">
      <RoundIconFrame background="var(--r-color-green-light)">
        <UIIcon name="profile-phone" />
      </RoundIconFrame>

      <span class="reminder-text">
        {{ $t("home::attendScheduledVideoVisit") }}

        <br />

        <strong v-if="time <= 0" class="call-live-caption" @click="onJoinClick">
          {{ $t("home::joinNowButton") }}
        </strong>

        <strong v-else class="ph-no-capture">{{ localizedComputedDatetime }}</strong>
      </span>
    </span>

    <UIIcon name="chevron" class="chevron" />
  </router-link>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import UIIcon from "@/uikit/renata/components/UIIcon/UIIcon.vue";
import RoundIconFrame from "@/uikit/renata/components/RoundIconFrame/RoundIconFrame.vue";
import type { Call } from "@/network/codecs";
import { ref, onMounted, onBeforeUnmount, computed } from "vue";
import { openCall } from "@/utils/react-native";
import { useTranslator } from "@/composables/useTranslator";

const props = defineProps<{
  call: Call;
}>();

const router = useRouter();

const translator = useTranslator();

const localizedComputedDatetime = computed(() => {
  const date = new Date(props.call.attributes.booked_at);

  const formatDate = new Intl.DateTimeFormat(translator.currentLocale, {
    month: "long",
    day: "numeric",
  });

  const formatTime = new Intl.DateTimeFormat(translator.currentLocale, {
    hour: "numeric",
    minute: "numeric",
    hour12: translator.currentLocale === "en",
    timeZoneName: "short",
  });

  return `${formatDate.format(date)}, ${formatTime.format(date)}`;
});

const intervalId = ref<undefined | ReturnType<typeof setInterval>>();

const time = ref(props.call.attributes.time_to_join);

const onJoinClick = () => {
  if (window.ReactNativeWebView) {
    openCall(props.call.attributes.token);
  } else {
    router.push(`/calls/${props.call.attributes.token}`);
    // router.push("/pages/download_apps");
  }
};

onMounted(() => (intervalId.value = setInterval(() => time.value--, 1000)));

onBeforeUnmount(() => clearInterval(intervalId.value));
</script>

<style lang="postcss" scoped>
.reminder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 0.25rem;
  padding: 1rem;

  text-decoration: none;

  background: var(--r-color-white);
  border-radius: 1.25rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--hoverable) {
    &:hover {
      border-color: var(--r-color-content-50);
    }
  }

  /* stylelint-disable-next-line order/order */
  &:active {
    background: var(--r-color-content-20);
    border-color: var(--r-color-content-20);
  }
}

.left-block {
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.chevron {
  font-size: 1.5rem;
}

@keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.reminder-text {
  font-family: "Roboto", sans-serif;
  font-size: 0.938rem;
  font-weight: 400;

  strong {
    font-family: "Roboto Flex", sans-serif;
    font-weight: 600;

    &.call-live-caption {
      display: flex;
      gap: 0.375rem;
      align-items: center;
      color: var(--r-color-coral-dark);

      &::before {
        content: "";

        position: static;

        flex-shrink: 0;

        width: 0.5rem;
        height: 0.5rem;

        background: var(--r-color-coral);
        border-radius: 50%;

        animation: glow 2s linear infinite;
      }
    }
  }
}
</style>
