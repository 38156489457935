<template>
  <slot v-if="!unifiedError" />
  <ScreenError
    v-else
    :title="$t('errorPages::somethingWentWrong')"
    :status="status"
    :message="unifiedError.message"
  />
</template>

<script setup lang="ts">
import { ref, onErrorCaptured, computed } from "vue";
import ScreenError from "@/components/error/ScreenError.vue";
import { normalizeError } from "@/utils/typecast";
import JSONApiException from "@/network/JSONApiException";
import { HTTPError } from "ky";
import { captureException } from "@sentry/vue";

const props = defineProps<{
  error: unknown | null;
}>();

const capturedError = ref<Error | null>(null);

onErrorCaptured((err) => {
  console.error(err);
  capturedError.value = normalizeError(err);
  captureException(capturedError.value, {
    tags: {
      reason: "errorBoundary",
    },
  });
});

const unifiedError = computed(() => {
  return props.error || capturedError.value
    ? normalizeError(props.error || capturedError.value)
    : null;
});

const status = computed(() => {
  return unifiedError.value instanceof JSONApiException || unifiedError.value instanceof HTTPError
    ? unifiedError.value.response.status
    : 500;
});
</script>
