<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M25.1979 3.98965L25.9791 0.310883L29.625 4.50779L25.1979 3.98965Z"
      fill="var(--r-color-coral)"
    />
    <path
      d="M14.677 19.8447L8.68738 11.5545L22.2812 13.0053L14.677 19.8447Z"
      fill="var(--r-color-coral)"
    />
    <path
      d="M17.1771 18.342L22.8021 13.2643L21.2396 20.0001L17.1771 18.342Z"
      fill="var(--r-color-coral)"
    />
    <path
      d="M7.177 16.3213L7.95825 11.3472L12.802 17.9275L9.15617 19.741L7.177 16.3213Z"
      fill="var(--r-color-coral)"
    />
    <path
      d="M8.1145 11.0363L12.802 5.23315L17.8541 9.11918L22.2291 12.4871L15.8749 11.8653L8.1145 11.0363Z"
      fill="var(--r-color-coral)"
    />
    <path
      d="M23.2708 0.207255L25.4583 0L22.6979 12.1244L18.1666 8.60107L23.2708 0.207255Z"
      fill="var(--r-color-coral)"
    />
    <path
      d="M6.76035 15.855L0.458252 3.31613L8.47911 9.79284L7.64577 10.8291C7.64577 10.8291 7.64577 10.8291 7.64577 10.8809V10.9327L6.76035 15.855Z"
      fill="var(--r-color-coral)"
    />
  </svg>
</template>
