<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M4.11538 21.5V10.6153H2.5V5.40387H7.67118C7.53014 5.24106 7.4343 5.0577 7.38365 4.8538C7.33302 4.64991 7.3077 4.43583 7.3077 4.21155C7.3077 3.4637 7.56945 2.82802 8.09295 2.30452C8.61645 1.78102 9.25213 1.51927 9.99998 1.51927C10.3833 1.51927 10.7384 1.59972 11.0654 1.76062C11.3923 1.92152 11.6846 2.13659 11.9423 2.40582C12.2 2.12632 12.4923 1.90869 12.8192 1.75292C13.1461 1.59716 13.5012 1.51927 13.8846 1.51927C14.6324 1.51927 15.2681 1.78102 15.7916 2.30452C16.3151 2.82802 16.5769 3.4637 16.5769 4.21155C16.5769 4.43335 16.549 4.64425 16.4932 4.84425C16.4374 5.04425 16.3442 5.23079 16.2134 5.40387H21.5V10.6153H19.8846V21.5H4.11538ZM13.8846 3.01922C13.5468 3.01922 13.2636 3.13349 13.0351 3.36202C12.8065 3.59056 12.6923 3.87373 12.6923 4.21155C12.6923 4.54938 12.8065 4.83256 13.0351 5.06107C13.2636 5.28961 13.5468 5.40387 13.8846 5.40387C14.2224 5.40387 14.5056 5.28961 14.7341 5.06107C14.9626 4.83256 15.0769 4.54938 15.0769 4.21155C15.0769 3.87373 14.9626 3.59056 14.7341 3.36202C14.5056 3.13349 14.2224 3.01922 13.8846 3.01922ZM8.80765 4.21155C8.80765 4.54938 8.92192 4.83256 9.15045 5.06107C9.37898 5.28961 9.66216 5.40387 9.99998 5.40387C10.3378 5.40387 10.621 5.28961 10.8495 5.06107C11.078 4.83256 11.1923 4.54938 11.1923 4.21155C11.1923 3.87373 11.078 3.59056 10.8495 3.36202C10.621 3.13349 10.3378 3.01922 9.99998 3.01922C9.66216 3.01922 9.37898 3.13349 9.15045 3.36202C8.92192 3.59056 8.80765 3.87373 8.80765 4.21155ZM3.99998 6.90385V9.1154H11.25V6.90385H3.99998ZM11.25 20V10.6153H5.61533V20H11.25ZM12.75 20H18.3846V10.6153H12.75V20ZM20 9.1154V6.90385H12.75V9.1154H20Z"
      fill="currentColor"
    />
  </svg>
</template>
