<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M15.773 12.9731L14.65 11.85C14.8 11.0218 14.5637 10.2773 13.9413 9.61639C13.3189 8.95549 12.5551 8.70004 11.65 8.85004L10.5269 7.72699C10.7525 7.62571 10.9839 7.54975 11.2211 7.49912C11.4583 7.44847 11.7179 7.42314 12 7.42314C13.1346 7.42314 14.0977 7.81898 14.8894 8.61064C15.681 9.40231 16.0769 10.3654 16.0769 11.5C16.0769 11.7821 16.0515 12.0449 16.0009 12.2885C15.9502 12.5321 15.8743 12.7603 15.773 12.9731ZM18.9538 16.0846L17.85 15.05C18.4833 14.5667 19.0458 14.0375 19.5375 13.4625C20.0291 12.8875 20.45 12.2334 20.8 11.5C19.9666 9.81671 18.7708 8.47921 17.2125 7.48754C15.6541 6.49588 13.9166 6.00004 12 6.00004C11.5166 6.00004 11.0416 6.03338 10.575 6.10004C10.1083 6.16671 9.64995 6.26671 9.19995 6.40004L8.0346 5.23469C8.66665 4.98341 9.31216 4.79815 9.97113 4.67892C10.6301 4.55969 11.3064 4.50007 12 4.50007C14.3435 4.50007 16.457 5.14622 18.3403 6.43852C20.2236 7.73083 21.5974 9.41801 22.4615 11.5C22.0909 12.3936 21.6124 13.2276 21.0259 14.0019C20.4393 14.7763 19.7486 15.4705 18.9538 16.0846ZM19.7615 21.8692L15.7154 17.8538C15.2025 18.0436 14.6343 18.1987 14.0106 18.3192C13.3868 18.4398 12.7166 18.5 12 18.5C9.64997 18.5 7.53652 17.8539 5.6596 16.5616C3.78269 15.2693 2.40897 13.5821 1.53845 11.5C1.90769 10.6167 2.38461 9.79267 2.96923 9.02792C3.55384 8.26318 4.19744 7.60005 4.9 7.03852L2.13075 4.23852L3.1846 3.18469L20.8153 20.8154L19.7615 21.8692ZM5.95383 8.09232C5.42563 8.51283 4.91184 9.01829 4.41248 9.60869C3.91311 10.1991 3.50894 10.8295 3.19995 11.5C4.03329 13.1834 5.22912 14.5209 6.78745 15.5125C8.34579 16.5042 10.0833 17 12 17C12.4551 17 12.9083 16.9616 13.3596 16.8846C13.8109 16.8077 14.1936 16.7282 14.5077 16.6462L13.2423 15.35C13.0718 15.4192 12.8743 15.4744 12.65 15.5154C12.4256 15.5564 12.2089 15.5769 12 15.5769C10.8654 15.5769 9.90222 15.1811 9.11055 14.3894C8.31889 13.5978 7.92305 12.6346 7.92305 11.5C7.92305 11.2975 7.94357 11.0856 7.9846 10.8645C8.02564 10.6433 8.08076 10.4411 8.14998 10.2577L5.95383 8.09232Z"
      fill="currentColor"
    />
  </svg>
</template>
