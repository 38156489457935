<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11.75 15.7885V7.3885L9.28475 9.85375L8.23075 8.76925L12.5 4.5L16.7693 8.76925L15.7153 9.85375L13.25 7.3885V15.7885H11.75ZM6.80775 19.5C6.30258 19.5 5.875 19.325 5.525 18.975C5.175 18.625 5 18.1974 5 17.6923V14.9808H6.5V17.6923C6.5 17.7692 6.53208 17.8398 6.59625 17.9038C6.66025 17.9679 6.73075 18 6.80775 18H18.1923C18.2692 18 18.3398 17.9679 18.4038 17.9038C18.4679 17.8398 18.5 17.7692 18.5 17.6923V14.9808H20V17.6923C20 18.1974 19.825 18.625 19.475 18.975C19.125 19.325 18.6974 19.5 18.1923 19.5H6.80775Z"
      fill="currentColor"
    />
    <path
      d="M11.75 15.7885V7.3885L9.28475 9.85375L8.23075 8.76925L12.5 4.5L16.7693 8.76925L15.7153 9.85375L13.25 7.3885V15.7885H11.75ZM6.80775 19.5C6.30258 19.5 5.875 19.325 5.525 18.975C5.175 18.625 5 18.1974 5 17.6923V14.9808H6.5V17.6923C6.5 17.7692 6.53208 17.8398 6.59625 17.9038C6.66025 17.9679 6.73075 18 6.80775 18H18.1923C18.2692 18 18.3398 17.9679 18.4038 17.9038C18.4679 17.8398 18.5 17.7692 18.5 17.6923V14.9808H20V17.6923C20 18.1974 19.825 18.625 19.475 18.975C19.125 19.325 18.6974 19.5 18.1923 19.5H6.80775Z"
      fill="currentColor"
    />
  </svg>
</template>
