import type { Ref } from "vue";
import { isRef, watchEffect } from "vue";

/**
 * Set the page title for the current page
 */
export default function usePageTitle(title: Ref<string> | string): void {
  watchEffect(() => {
    document.title = isRef(title) ? title.value : title;
  });
}
