<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M19.702 4.2981V9.69709H18.303V6.70765L13.6376 11.3336C13.9606 11.8067 14.2188 12.3092 14.4121 12.8411C14.6053 13.3729 14.702 13.9259 14.702 14.5C14.702 15.9489 14.1976 17.1773 13.189 18.1852C12.1803 19.1931 10.951 19.6971 9.501 19.6971C8.05104 19.6971 6.8222 19.1931 5.8145 18.1851C4.80682 17.1771 4.30298 15.9485 4.30298 14.4995C4.30298 13.0505 4.80694 11.8213 5.81485 10.812C6.82279 9.80274 8.0512 9.2981 9.50008 9.2981C10.068 9.2981 10.6179 9.39345 11.1496 9.58415C11.6813 9.77486 12.1812 10.0343 12.6491 10.3625L17.3021 5.69709H14.303V4.2981H19.702ZM9.49915 10.6971C8.44592 10.6971 7.54975 11.0678 6.81065 11.8091C6.07154 12.5504 5.70198 13.4477 5.70198 14.5009C5.70198 15.5542 6.07184 16.4503 6.81155 17.1894C7.55129 17.9285 8.44777 18.2981 9.501 18.2981C10.5542 18.2981 11.4512 17.9282 12.1919 17.1885C12.9326 16.4488 13.303 15.5523 13.303 14.4991C13.303 13.4458 12.9323 12.5489 12.191 11.8081C11.4497 11.0674 10.5524 10.6971 9.49915 10.6971Z"
      fill="currentColor"
    />
  </svg>
</template>
