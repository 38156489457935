import { onMounted } from "vue";
import { refreshRNConfig } from "@/utils/react-native";

/**
 * React Native needs to know when the new UX flag is updated.
 * After this it should refresh the `rn-config.json` file.
 */
export function useSyncNewUXFlagWithRN() {
  onMounted(() => {
    refreshRNConfig();
  });
}
