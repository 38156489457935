const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export const getCookieValue = (name: string): string | null => {
  if (!name?.trim()) return null;
  if (typeof document === "undefined") return null;

  const regex = new RegExp(`(?:^|; )${escapeRegExp(name)}=([^;]*)`);
  const match = regex.exec(document.cookie);

  return match ? decodeURIComponent(match[1]) : null;
};

type CookieObject = Record<string, string | null>;

/**
 * Parses a cookie string into an object of key-value pairs.
 * @param cookieString A string in the format key:value,key:value
 * @returns An object with keys and their corresponding values from the cookie string
 */
export const parseCookieString = (cookieString: string | null): CookieObject => {
  if (!cookieString?.trim()) return {};

  if (!/^[^:,]+(:[^:,]*)?(?:,[^:,]+(:[^:,]*)?)*$/.test(cookieString)) {
    console.warn("Invalid cookie string format");
    return {};
  }

  return cookieString.split(",").reduce<CookieObject>((acc, pair) => {
    const [key, value] = pair.split(":");
    const trimmedKey = key?.trim();
    try {
      if (trimmedKey) {
        acc[trimmedKey] = value ? decodeURIComponent(value.trim()) : null;
      }
    } catch (e) {
      console.warn("Error parsing cookie value", e);
      acc[trimmedKey] = null;
    }
    return acc;
  }, {});
};
