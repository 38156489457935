<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M16.3845 17.3078V6.69226H17.8845V17.3078H16.3845ZM6.11548 17.3078V6.69226L14.077 12L6.11548 17.3078ZM7.61548 14.5L11.3807 12L7.61548 9.50001V14.5Z"
      fill="currentColor"
    />
  </svg>
</template>
