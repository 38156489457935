import { captureException } from "@sentry/vue";

async function checkHasNewVersion(): Promise<boolean> {
  try {
    if (import.meta.env.DEV) {
      // in dev mode, we don't need to check for new version
      return false;
    }
    const random = Math.random().toString().replace(".", "");

    const resp = await fetch(`/version.txt?nocache=${random}`, {
      // disable cache to always get the latest version
      cache: "no-store",
    });
    if (!resp.ok) {
      return false;
    }
    try {
      const version = await resp.text();
      const currentVersion = import.meta.env.VITE_BUILD_TIMESTAMP;
      return !!version && !!currentVersion && version !== currentVersion;
    } catch {
      return false;
    }
  } catch (e) {
    console.error(e);
    captureException(e, {
      extra: {
        message: "Failed to check for new version",
      },
    });
    return false;
  }
}

export default checkHasNewVersion;
