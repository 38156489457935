import { HTTPError, type NormalizedOptions } from "ky";

export interface JSONApiError {
  /**
   * a short, human-readable summary of the problem that SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization.
   */
  title: string;
  /**
   * Some errors may have a hint of WHERE the error occurred.
   */
  source?: {
    pointer: string;
  };
  /**
   *  an application-specific error code, expressed as a string value.
   */
  code?: string;

  /**
   * a human-readable explanation specific to this occurrence of the problem. Like title, this field’s value can be localized.
   */
  detail?: string;

  /**
   * a unique identifier for this particular occurrence of the problem.
   */
  id?: string;
}

/**
 * HTTP error with JSON:API error response
 */
export default class JSONApiException extends HTTPError {
  constructor(
    response: Response,
    request: Request,
    options: NormalizedOptions,
    public readonly errors: readonly JSONApiError[],
  ) {
    super(response, request, options);

    const firstError = errors[0];
    if (firstError && firstError.title) {
      this.message = firstError.title;
    }
  }
}
