<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M3.50003 17.5V16H8.87501V17.5H3.50003ZM3.50003 12.75V11.25H14.6827V12.75H3.50003ZM3.50003 8.00001V6.50003H20.5V8.00001H3.50003Z"
      fill="currentColor"
    />
  </svg>
</template>
