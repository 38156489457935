/**
 * Store the current location in session storage.
 * This is useful for preserving the location when the user is redirected to the sign-in page.
 */
export function storeInitialLocation() {
    const initialLocation = `${window.location.pathname}${window.location.search}`;
    sessionStorage.setItem("initialLocation", initialLocation);
}

/**
 * This is useful for redirecting the user back to the page they were going to before they were redirected to the sign-in page.
 */
export function getInitialLocation() {
  return sessionStorage.getItem("initialLocation");
}

/**
 * Clear the stored location.
 */
export function clearInitialLocation() {
  sessionStorage.removeItem("initialLocation");
}
