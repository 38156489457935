<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11.2885 16.6152H12.7115L12.9538 15.25C13.2436 15.1667 13.4936 15.0679 13.7038 14.9537C13.9141 14.8398 14.1205 14.6872 14.323 14.496L15.6385 14.946L16.3595 13.727L15.3152 12.8422C15.3871 12.5449 15.423 12.2642 15.423 12C15.423 11.7358 15.3871 11.4551 15.3152 11.1578L16.3595 10.273L15.6385 9.054L14.323 9.504C14.1205 9.31283 13.9141 9.16025 13.7038 9.04625C13.4936 8.93208 13.2436 8.83333 12.9538 8.75L12.7115 7.38475H11.2885L11.0463 8.75C10.7564 8.83333 10.5064 8.93208 10.2963 9.04625C10.0859 9.16025 9.8795 9.31283 9.677 9.504L8.3615 9.054L7.6405 10.273L8.68475 11.1578C8.61292 11.4551 8.577 11.7358 8.577 12C8.577 12.2642 8.61292 12.5449 8.68475 12.8422L7.6405 13.727L8.3615 14.946L9.677 14.496C9.8795 14.6872 10.0859 14.8398 10.2963 14.9537C10.5064 15.0679 10.7564 15.1667 11.0463 15.25L11.2885 16.6152ZM12 14C11.45 14 10.9792 13.8042 10.5875 13.4125C10.1958 13.0208 10 12.55 10 12C10 11.45 10.1958 10.9792 10.5875 10.5875C10.9792 10.1958 11.45 10 12 10C12.55 10 13.0208 10.1958 13.4125 10.5875C13.8042 10.9792 14 11.45 14 12C14 12.55 13.8042 13.0208 13.4125 13.4125C13.0208 13.8042 12.55 14 12 14ZM5.30775 20.5C4.80258 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30775C3.5 4.80258 3.675 4.375 4.025 4.025C4.375 3.675 4.80258 3.5 5.30775 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80258 20.5 5.30775V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.30775ZM5.30775 19H18.6923C18.7692 19 18.8398 18.9679 18.9038 18.9038C18.9679 18.8398 19 18.7692 19 18.6923V5.30775C19 5.23075 18.9679 5.16025 18.9038 5.09625C18.8398 5.03208 18.7692 5 18.6923 5H5.30775C5.23075 5 5.16025 5.03208 5.09625 5.09625C5.03208 5.16025 5 5.23075 5 5.30775V18.6923C5 18.7692 5.03208 18.8398 5.09625 18.9038C5.16025 18.9679 5.23075 19 5.30775 19Z"
      fill="currentColor"
    />
  </svg>
</template>
