<template>
  <Transition name="notification">
    <div v-if="notification.visible" class="notification-container">
      <RoundIconFrame kind="small" :background="iconBackground">
        <UIIcon :name="iconName" :style="`color: ${iconColor}`" />
      </RoundIconFrame>

      <p class="notification-text">{{ notification.text }}</p>

      <RoundIconFrame kind="small" class="cross-icon" @click="removeNotification">
        <UIIcon name="cross" />
      </RoundIconFrame>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import UIIcon, { type IconName } from "@/uikit/renata/components/UIIcon/UIIcon.vue";
import RoundIconFrame from "@/uikit/renata/components/RoundIconFrame/RoundIconFrame.vue";
import type { Notification } from "@/stores/UINotificationStore";
import { computed, ref, nextTick, onMounted, onUnmounted } from "vue";

export type Props = {
  notification: Notification;
};

const props = defineProps<Props>();

const emit = defineEmits(["remove", "show", "hide"]);

const iconBackground = computed(() => {
  let color;

  switch (props.notification.type) {
    case "error":
    case "server":
      color = "var(--r-color-error)";
      break;

    case "alert":
      color = "var(--r-color-coral)";
      break;

    case "info":
      color = "var(--r-color-content-40)";
      break;

    case "success":
      color = "var(--r-color-green-light)";
      break;
  }

  return color;
});

const iconColor = computed(() => {
  let color;

  switch (props.notification.type) {
    case "error":
    case "server":
      color = "var(--r-color-white)";
      break;

    case "alert":
    case "info":
      color = "var(--r-color-black)";
      break;

    case "success":
      color = "var(--r-color-green-dark)";
      break;
  }

  return color;
});

const iconName = computed(() => {
  let name: IconName;

  switch (props.notification.type) {
    case "error":
      name = "cross";
      break;

    case "server":
      name = "block";
      break;

    case "alert":
      name = "warning";
      break;

    case "info":
      name = "info";
      break;

    case "success":
      name = "check";
      break;
  }

  return name;
});

const timeout = ref<ReturnType<typeof setTimeout>>();

const removeNotification = () => {
  emit("hide");

  nextTick(() => emit("remove"));
};

onMounted(() => {
  emit("show");

  timeout.value = setTimeout(() => {
    removeNotification();
  }, props.notification.duration);
});

onUnmounted(() => {
  clearTimeout(timeout.value);
});
</script>

<style lang="postcss" scoped>
.notification-container {
  transform: scale(1);

  display: flex;
  gap: 1rem;
  align-items: flex-start;

  width: calc(100% - 0.5rem);
  max-width: 768px;
  margin: 0.625rem auto 0;
  padding: 1rem 0.25rem 1rem 1rem;
  border: 1px solid transparent;
  border-radius: 1.25rem;

  opacity: 1;
  background: var(--r-color-white);
  box-shadow: 0px 0px 40px 0px rgba(64, 64, 64, 0.2);

  transition:
    transform 0.3s ease,
    opacity 0.3s ease;
}

.notification-enter-from {
  transform: scale(0.95);
  opacity: 0;
}

.notification-leave-to {
  transform: scale(0.95);
  opacity: 0;
}

.notification-text {
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  margin: 0;
}

.cross-icon {
  margin-inline-start: auto;
}
</style>
