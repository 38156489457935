<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <g clip-path="url(#clip0_3123_22655)">
      <path
        d="M15.5 5.25C14.8056 5.25 14.2153 5.00694 13.7292 4.52083C13.2431 4.03472 13 3.44444 13 2.75C13 2.05556 13.2431 1.46528 13.7292 0.979166C14.2153 0.493055 14.8056 0.25 15.5 0.25C16.1944 0.25 16.7847 0.493055 17.2708 0.979166C17.7569 1.46528 18 2.05556 18 2.75C18 3.44444 17.7569 4.03472 17.2708 4.52083C16.7847 5.00694 16.1944 5.25 15.5 5.25ZM2 18V3.5C2 3.09722 2.14688 2.74653 2.44063 2.44792C2.73438 2.14931 3.0875 2 3.5 2H11.5625C11.5208 2.25458 11.5 2.50924 11.5 2.76396C11.5 3.01854 11.5208 3.26389 11.5625 3.5C11.6181 3.81944 11.691 4.08681 11.7812 4.30208C11.8715 4.51736 12 4.75 12.1667 5H5V6.5H14.0833C14.3056 6.58333 14.5347 6.64583 14.7708 6.6875C15.0069 6.72917 15.25 6.75 15.5 6.75C15.8611 6.75 16.2465 6.69097 16.6562 6.57292C17.066 6.45486 17.5139 6.22222 18 5.875V13.5C18 13.9125 17.8507 14.2656 17.5521 14.5594C17.2535 14.8531 16.9028 15 16.5 15H5L2 18ZM5 9.25H15V7.75H5V9.25ZM5 12H12V10.5H5V12Z"
        fill="white"
      />
      <path
        d="M5 12H12V10.5H5V12ZM5 9.25H15V7.75H5V9.25ZM5 6.5H14.0833C13.6806 6.34722 13.3194 6.14583 13 5.89583C12.6806 5.64583 12.4028 5.34722 12.1667 5H5V6.5ZM2 18V3.5C2 3.09722 2.14688 2.74653 2.44063 2.44792C2.73438 2.14931 3.0875 2 3.5 2H11.5625C11.5208 2.25 11.5 2.5 11.5 2.75C11.5 3 11.5208 3.25 11.5625 3.5H3.5V14.375L4.375 13.5H16.5V6.625C16.75 6.55556 17.0104 6.45139 17.2812 6.3125C17.5521 6.17361 17.7917 6.02778 18 5.875V13.5C18 13.9125 17.8507 14.2656 17.5521 14.5594C17.2535 14.8531 16.9028 15 16.5 15H5L2 18ZM15.5 5.25C14.8056 5.25 14.2153 5.00694 13.7292 4.52083C13.2431 4.03472 13 3.44444 13 2.75C13 2.05556 13.2431 1.46528 13.7292 0.979166C14.2153 0.493055 14.8056 0.25 15.5 0.25C16.1944 0.25 16.7847 0.493055 17.2708 0.979166C17.7569 1.46528 18 2.05556 18 2.75C18 3.44444 17.7569 4.03472 17.2708 4.52083C16.7847 5.00694 16.1944 5.25 15.5 5.25Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_3123_22655">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
