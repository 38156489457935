import en from "virtual:translations/auto/en";
import { type Plugin } from "vue";
import Translator from "./Translator";
import type { Locale, CompiledTranslations, SpokenLanguage } from "./shared-types";
import DOMPurify from "dompurify";
import { sendLocaleToRN } from "@/utils/react-native";
import { type Router, useRouter } from "vue-router";
import { isFileLink } from "@/utils/files";

export function translateMonth(month: number, locale?: string): string {
  const format = new Intl.DateTimeFormat(locale, {
    month: "long",
  });
  const date = new Date(0, month, 1, 0, 0, 0, 0);
  return format.format(date);
}

const resources = {
  ar: () => import("virtual:translations/auto/ar").then((module) => module.default),
  bg: () => import("virtual:translations/auto/bg").then((module) => module.default),
  "bs-BA": () => import("virtual:translations/auto/bs-BA").then((module) => module.default),
  cs: () => import("virtual:translations/auto/cs").then((module) => module.default),
  da: () => import("virtual:translations/auto/da").then((module) => module.default),
  de: () => import("virtual:translations/auto/de").then((module) => module.default),
  el: () => import("virtual:translations/auto/el").then((module) => module.default),
  "el-CY": () => import("virtual:translations/auto/el-CY").then((module) => module.default),
  es: () => import("virtual:translations/auto/es").then((module) => module.default),
  et: () => import("virtual:translations/auto/et").then((module) => module.default),
  fi: () => import("virtual:translations/auto/fi").then((module) => module.default),
  fil: () => import("virtual:translations/auto/fil").then((module) => module.default),
  fr: () => import("virtual:translations/auto/fr").then((module) => module.default),
  he: () => import("virtual:translations/auto/he").then((module) => module.default),
  hi: () => import("virtual:translations/auto/hi").then((module) => module.default),
  hr: () => import("virtual:translations/auto/hr").then((module) => module.default),
  "ht-HT": () => import("virtual:translations/auto/ht-HT").then((module) => module.default),
  hu: () => import("virtual:translations/auto/hu").then((module) => module.default),
  id: () => import("virtual:translations/auto/id").then((module) => module.default),
  is: () => import("virtual:translations/auto/is").then((module) => module.default),
  it: () => import("virtual:translations/auto/it").then((module) => module.default),
  ja: () => import("virtual:translations/auto/ja").then((module) => module.default),
  "km-KH": () => import("virtual:translations/auto/km-KH").then((module) => module.default),
  ko: () => import("virtual:translations/auto/ko").then((module) => module.default),
  lb: () => import("virtual:translations/auto/lb").then((module) => module.default),
  lt: () => import("virtual:translations/auto/lt").then((module) => module.default),
  lv: () => import("virtual:translations/auto/lv").then((module) => module.default),
  ms: () => import("virtual:translations/auto/ms").then((module) => module.default),
  "mt-MT": () => import("virtual:translations/auto/mt-MT").then((module) => module.default),
  nl: () => import("virtual:translations/auto/nl").then((module) => module.default),
  no: () => import("virtual:translations/auto/no").then((module) => module.default),
  pl: () => import("virtual:translations/auto/pl").then((module) => module.default),
  pt: () => import("virtual:translations/auto/pt").then((module) => module.default),
  ro: () => import("virtual:translations/auto/ro").then((module) => module.default),
  sk: () => import("virtual:translations/auto/sk").then((module) => module.default),
  sl: () => import("virtual:translations/auto/sl").then((module) => module.default),
  sr: () => import("virtual:translations/auto/sr").then((module) => module.default),
  sv: () => import("virtual:translations/auto/sv").then((module) => module.default),
  sw: () => import("virtual:translations/auto/sw").then((module) => module.default),
  th: () => import("virtual:translations/auto/th").then((module) => module.default),
  tr: () => import("virtual:translations/auto/tr").then((module) => module.default),
  vi: () => import("virtual:translations/auto/vi").then((module) => module.default),
  "zh-CN": () => import("virtual:translations/auto/zh-CN").then((module) => module.default),
  "zh-HK": () => import("virtual:translations/auto/zh-HK").then((module) => module.default),
  "zh-TW": () => import("virtual:translations/auto/zh-TW").then((module) => module.default),
} satisfies Record<Exclude<Locale, "en">, () => Promise<CompiledTranslations>>;

export const i18nSymbol = Symbol("i18n");

export interface LocaleConfig {
  label: string;
  shortLabel: string;
  flag: string;
  recaptchaLocale: string;
  sendbirdLocale: string;
  backendLocale: string;
  reactNativeLocale: string;
  dir: "ltr" | "rtl";
}

export const LOCALES_CONFIG = {
  en: {
    label: "English",
    shortLabel: "EN",
    flag: "🇺🇸",
    recaptchaLocale: "en",
    sendbirdLocale: "en",
    backendLocale: "en",
    reactNativeLocale: "en",
    dir: "ltr",
  },
  ar: {
    label: "العربية",
    shortLabel: "AR",
    flag: "🇸🇦",
    recaptchaLocale: "ar",
    sendbirdLocale: "ar",
    backendLocale: "ar",
    reactNativeLocale: "ar",
    dir: "rtl",
  },
  bg: {
    label: "Български",
    shortLabel: "BG",
    flag: "🇧🇬",
    recaptchaLocale: "bg",
    sendbirdLocale: "bg",
    backendLocale: "bg",
    reactNativeLocale: "bg",
    dir: "ltr",
  },
  "bs-BA": {
    label: "Bosanski",
    shortLabel: "BS",
    flag: "🇧🇦",
    recaptchaLocale: "en",
    sendbirdLocale: "bs",
    backendLocale: "bs_BA",
    reactNativeLocale: "bs_BA",
    dir: "ltr",
  },
  cs: {
    label: "Čeština",
    shortLabel: "CS",
    flag: "🇨🇿",
    recaptchaLocale: "cs",
    sendbirdLocale: "cs",
    backendLocale: "cs",
    reactNativeLocale: "cs",
    dir: "ltr",
  },
  da: {
    label: "Dansk",
    shortLabel: "DA",
    flag: "🇩🇰",
    recaptchaLocale: "da",
    sendbirdLocale: "da",
    backendLocale: "da",
    reactNativeLocale: "da",
    dir: "ltr",
  },
  de: {
    label: "Deutsch",
    shortLabel: "DE",
    flag: "🇩🇪",
    recaptchaLocale: "de",
    sendbirdLocale: "de",
    backendLocale: "de",
    reactNativeLocale: "de",
    dir: "ltr",
  },
  el: {
    label: "Ελληνικά",
    shortLabel: "EL",
    flag: "🇬🇷",
    recaptchaLocale: "el",
    sendbirdLocale: "el",
    backendLocale: "el",
    reactNativeLocale: "el",
    dir: "ltr",
  },
  "el-CY": {
    label: "Ελληνικά (Κύπρος)",
    shortLabel: "EL",
    flag: "🇨🇾",
    recaptchaLocale: "el",
    sendbirdLocale: "el",
    backendLocale: "el_CY",
    reactNativeLocale: "el_CY",
    dir: "ltr",
  },
  es: {
    label: "Español",
    shortLabel: "ES",
    flag: "🇪🇸",
    recaptchaLocale: "es",
    sendbirdLocale: "es",
    backendLocale: "es",
    reactNativeLocale: "es",
    dir: "ltr",
  },
  et: {
    label: "Eesti",
    shortLabel: "ET",
    flag: "🇪🇪",
    recaptchaLocale: "et",
    sendbirdLocale: "et",
    backendLocale: "et",
    reactNativeLocale: "et",
    dir: "ltr",
  },
  fi: {
    label: "Suomi",
    shortLabel: "FI",
    flag: "🇫🇮",
    recaptchaLocale: "fi",
    sendbirdLocale: "fi",
    backendLocale: "fi",
    reactNativeLocale: "fi",
    dir: "ltr",
  },
  fil: {
    label: "Filipino",
    shortLabel: "FIL",
    flag: "🇵🇭",
    recaptchaLocale: "tl",
    sendbirdLocale: "tl",
    backendLocale: "tl",
    reactNativeLocale: "tl",
    dir: "ltr",
  },
  fr: {
    label: "Français",
    shortLabel: "FR",
    flag: "🇫🇷",
    recaptchaLocale: "fr",
    sendbirdLocale: "fr",
    backendLocale: "fr",
    reactNativeLocale: "fr",
    dir: "ltr",
  },
  he: {
    label: "עברית",
    shortLabel: "HE",
    flag: "🇮🇱",
    recaptchaLocale: "iw",
    sendbirdLocale: "he",
    backendLocale: "he",
    reactNativeLocale: "he",
    dir: "rtl",
  },
  hi: {
    label: "हिन्दी",
    shortLabel: "HI",
    flag: "🇮🇳",
    recaptchaLocale: "hi",
    sendbirdLocale: "hi",
    backendLocale: "hi",
    reactNativeLocale: "hi",
    dir: "ltr",
  },
  hr: {
    label: "Hrvatski",
    shortLabel: "HR",
    flag: "🇭🇷",
    recaptchaLocale: "hr",
    sendbirdLocale: "hr",
    backendLocale: "hr",
    reactNativeLocale: "hr",
    dir: "ltr",
  },
  "ht-HT": {
    label: "Kreyòl Ayisyen",
    shortLabel: "ht-HT",
    flag: "🇭🇹",
    recaptchaLocale: "en",
    sendbirdLocale: "ht",
    backendLocale: "ht_HT",
    reactNativeLocale: "ht_HT",
    dir: "ltr",
  },
  hu: {
    label: "Magyar",
    shortLabel: "HU",
    flag: "🇭🇺",
    recaptchaLocale: "hu",
    sendbirdLocale: "hu",
    backendLocale: "hu",
    reactNativeLocale: "hu",
    dir: "ltr",
  },
  id: {
    label: "Bahasa Indonesia",
    shortLabel: "ID",
    flag: "🇮🇩",
    recaptchaLocale: "id",
    sendbirdLocale: "id",
    backendLocale: "id",
    reactNativeLocale: "id",
    dir: "ltr",
  },
  is: {
    label: "Íslenska",
    shortLabel: "IS",
    flag: "🇮🇸",
    recaptchaLocale: "is",
    sendbirdLocale: "is",
    backendLocale: "is",
    reactNativeLocale: "is",
    dir: "ltr",
  },
  it: {
    label: "Italiano",
    shortLabel: "IT",
    flag: "🇮🇹",
    recaptchaLocale: "it",
    sendbirdLocale: "it",
    backendLocale: "it",
    reactNativeLocale: "it",
    dir: "ltr",
  },
  ja: {
    label: "日本語",
    shortLabel: "JA",
    flag: "🇯🇵",
    recaptchaLocale: "ja",
    sendbirdLocale: "ja",
    backendLocale: "ja",
    reactNativeLocale: "ja",
    dir: "ltr",
  },
  "km-KH": {
    label: "ភាសាខ្មែរ",
    shortLabel: "KM-KH",
    flag: "🇰🇭",
    recaptchaLocale: "en",
    sendbirdLocale: "km",
    backendLocale: "km_KH",
    reactNativeLocale: "km_KH",
    dir: "ltr",
  },
  ko: {
    label: "한국어",
    shortLabel: "KO",
    flag: "🇰🇷",
    recaptchaLocale: "ko",
    sendbirdLocale: "ko",
    backendLocale: "ko",
    reactNativeLocale: "ko",
    dir: "ltr",
  },
  lb: {
    label: "Lëtzebuergesch",
    shortLabel: "LB",
    flag: "🇱🇺",
    recaptchaLocale: "en",
    sendbirdLocale: "lb",
    backendLocale: "lb",
    reactNativeLocale: "lb",
    dir: "ltr",
  },
  lt: {
    label: "Lietuvių",
    shortLabel: "LT",
    flag: "🇱🇹",
    recaptchaLocale: "lt",
    sendbirdLocale: "lt",
    backendLocale: "lt",
    reactNativeLocale: "lt",
    dir: "ltr",
  },
  lv: {
    label: "Latviešu",
    shortLabel: "LV",
    flag: "🇱🇻",
    recaptchaLocale: "lv",
    sendbirdLocale: "lv",
    backendLocale: "lv",
    reactNativeLocale: "lv",
    dir: "ltr",
  },
  ms: {
    label: "Bahasa Melayu",
    shortLabel: "MS",
    flag: "🇲🇾",
    recaptchaLocale: "ms",
    sendbirdLocale: "ms",
    backendLocale: "ms",
    reactNativeLocale: "ms",
    dir: "ltr",
  },
  "mt-MT": {
    label: "Malti",
    shortLabel: "MT",
    flag: "🇲🇹",
    recaptchaLocale: "en",
    sendbirdLocale: "mt",
    backendLocale: "mt_MT",
    reactNativeLocale: "mt_MT",
    dir: "ltr",
  },
  nl: {
    label: "Nederlands",
    shortLabel: "NL",
    flag: "🇳🇱",
    recaptchaLocale: "nl",
    sendbirdLocale: "nl",
    backendLocale: "nl",
    reactNativeLocale: "nl",
    dir: "ltr",
  },
  no: {
    label: "Norsk",
    shortLabel: "NO",
    flag: "🇳🇴",
    recaptchaLocale: "no",
    sendbirdLocale: "no",
    backendLocale: "no",
    reactNativeLocale: "no",
    dir: "ltr",
  },
  pl: {
    label: "Polski",
    shortLabel: "PL",
    flag: "🇵🇱",
    recaptchaLocale: "pl",
    sendbirdLocale: "pl",
    backendLocale: "pl",
    reactNativeLocale: "pl",
    dir: "ltr",
  },
  pt: {
    label: "Português",
    shortLabel: "PT",
    flag: "🇵🇹",
    recaptchaLocale: "pt",
    sendbirdLocale: "pt",
    backendLocale: "pt",
    reactNativeLocale: "pt",
    dir: "ltr",
  },
  ro: {
    label: "Română",
    shortLabel: "RO",
    flag: "🇷🇴",
    recaptchaLocale: "ro",
    sendbirdLocale: "ro",
    backendLocale: "ro",
    reactNativeLocale: "ro",
    dir: "ltr",
  },
  sk: {
    label: "Slovenčina",
    shortLabel: "SK",
    flag: "🇸🇰",
    recaptchaLocale: "sk",
    sendbirdLocale: "sk",
    backendLocale: "sk",
    reactNativeLocale: "sk",
    dir: "ltr",
  },
  sl: {
    label: "Slovenščina",
    shortLabel: "SL",
    flag: "🇸🇮",
    recaptchaLocale: "sl",
    sendbirdLocale: "sl",
    backendLocale: "sl",
    reactNativeLocale: "sl",
    dir: "ltr",
  },
  sr: {
    label: "Српска",
    shortLabel: "SR",
    flag: "🇷🇸",
    recaptchaLocale: "sr",
    sendbirdLocale: "sr",
    backendLocale: "sr",
    reactNativeLocale: "sr",
    dir: "ltr",
  },
  sv: {
    label: "Svenska",
    shortLabel: "SV",
    flag: "🇸🇪",
    recaptchaLocale: "sv",
    sendbirdLocale: "sv",
    backendLocale: "sv",
    reactNativeLocale: "sv",
    dir: "ltr",
  },
  sw: {
    label: "Kiswahili",
    shortLabel: "SW",
    flag: "🇹🇿",
    recaptchaLocale: "sw",
    sendbirdLocale: "sw",
    backendLocale: "sw",
    reactNativeLocale: "sw",
    dir: "ltr",
  },
  th: {
    label: "ไทย",
    shortLabel: "TH",
    flag: "🇹🇭",
    recaptchaLocale: "th",
    sendbirdLocale: "th",
    backendLocale: "th",
    reactNativeLocale: "th",
    dir: "ltr",
  },
  tr: {
    label: "Türkçe",
    shortLabel: "TR",
    flag: "🇹🇷",
    recaptchaLocale: "tr",
    sendbirdLocale: "tr",
    backendLocale: "tr",
    reactNativeLocale: "tr",
    dir: "ltr",
  },
  vi: {
    label: "Tiếng Việt",
    shortLabel: "VI",
    flag: "🇻🇳",
    recaptchaLocale: "vi",
    sendbirdLocale: "vi",
    backendLocale: "vi",
    reactNativeLocale: "vi",
    dir: "ltr",
  },
  "zh-CN": {
    label: "中文(简体)",
    shortLabel: "ZH-CN",
    flag: "🇨🇳",
    recaptchaLocale: "zh-CN",
    sendbirdLocale: "zh-CN",
    backendLocale: "zh_CN",
    reactNativeLocale: "zh_CN",
    dir: "ltr",
  },
  "zh-HK": {
    label: "中文(香港)",
    shortLabel: "ZH-HK",
    flag: "🇭🇰",
    recaptchaLocale: "zh-HK",
    sendbirdLocale: "zh-TW", // Sendbird does not support HK according to the [docs](https://sendbird.com/docs/chat/platform-api/v3/message/translations/translation-engine). TW is the closest
    backendLocale: "zh_HK",
    reactNativeLocale: "zh_HK",
    dir: "ltr",
  },
  "zh-TW": {
    label: "中文(繁體)",
    shortLabel: "ZH-TW",
    flag: "🇹🇼",
    recaptchaLocale: "zh-TW",
    sendbirdLocale: "zh-TW",
    backendLocale: "zh_TW",
    reactNativeLocale: "zh_TW",
    dir: "ltr",
  },
} satisfies Record<Locale, LocaleConfig>;

export const isValidLocale = (locale: string): locale is Locale => {
  return locale in LOCALES_CONFIG;
};

const envLocales = import.meta.env.VITE_PUBLIC_LOCALES
  ? import.meta.env.VITE_PUBLIC_LOCALES.split(",")
  : [];

/**
 * Locales allowed to use on production
 */
export const PUBLIC_LOCALES = (Object.keys(LOCALES_CONFIG) as Locale[]).filter((locale) => {
  return envLocales.length > 0 ? envLocales.includes(locale) : true;
});

export interface SpokenLanguageConfig {
  label: string;
}

export const SPOKEN_LANGUAGES = Object.assign(
  Object.fromEntries(
    Object.entries(LOCALES_CONFIG).map(([locale, config]) => {
      return [
        locale,
        {
          label: config.label,
        },
      ];
    }),
  ) as Record<Locale, SpokenLanguageConfig>,
  {
    ru: {
      label: "Русский",
    },
  },
) satisfies Record<SpokenLanguage, SpokenLanguageConfig>;

const translator = new Translator(en, resources);

const LOCAL_STORAGE_LOCALE_KEY = "new-ux-locale";

export function getInitialLocale() {
  const qpLocale = new URLSearchParams(window.location.search).get("locale") as string | undefined;

  const frontendQpLocale = Object.entries(LOCALES_CONFIG).find(([, config]) => {
    return config.backendLocale === qpLocale;
  })?.[0] as Locale | undefined;

  const initialLocale =
    frontendQpLocale || (localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) as Locale | null) || "en";

  saveLocaleToLocalstorage(initialLocale);

  return { locale: initialLocale, dir: LOCALES_CONFIG[initialLocale].dir };
}

export function saveLocaleToLocalstorage(locale: Locale) {
  localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale);
}

export async function setLocale(locale: Locale) {
  saveLocaleToLocalstorage(locale);
  // we use reload to ensure that the new locale is applied everywhere, even in API responses
  window.location.reload();
}

export async function initializeLocale() {
  const initialData = getInitialLocale();
  if (initialData.locale && initialData.locale !== "en") {
    await translator.changeLocale(initialData.locale);
  }
  sendLocaleToRN(initialData.locale);

  return initialData;
}

/**
 * Sanitizes the translation string to allow only safe tags as we insert it into the DOM using `v-html`
 */
function purifyTranslation(dirty: string): string {
  return DOMPurify.sanitize(dirty, {
    ALLOWED_TAGS: ["strong", "em", "br", "b", "i", "a"], // some safe presentational tags + links
    ALLOWED_ATTR: ["href", "class"], // no attributes allowed, except for href in links and class
  });
}

export const i18nPlugin: Plugin = {
  install(app) {
    app.provide(i18nSymbol, translator);
    app.config.globalProperties.$t = translator.t;

    // The `v-t` directive allows to use rich text translations in the template.
    // Usage: <span v-t="key"></span>
    // With variables: <span v-t="{key, variables}"></span>
    app.directive("t", (el, binding) => {
      let router: Router | undefined;
      app.runWithContext(() => {
        router = useRouter();
      });
      const key = typeof binding.value === "string" ? binding.value : binding.value.key;
      const variables = typeof binding.value === "string" ? undefined : binding.value.variables;
      el.innerHTML = purifyTranslation(translator.t(key, variables));
      if (router) {
        handleLinksInsideTranslations(el, router);
      } else {
        console.warn("Router is not provided, so we can not handle links");
      }
    });
  },
};

function handleLinksInsideTranslations(el: HTMLElement, router: Router) {
  if (el instanceof HTMLElement) {
    // links should be handled in a special way to avoid full page reloads
    const allLinksInside = el.querySelectorAll("a");
    allLinksInside.forEach((link) => {
      // just for debug
      link.setAttribute("data-stork-link", "true");
      link.addEventListener("click", (e) => {
        const href = link.getAttribute("href");
        if (href) {
          const isTheSameDomain =
            new URL(href, window.location.origin).origin === window.location.origin;
          const isFile = isFileLink(href);
          if (isFile) {
            // files should be handled in a special way
            link.setAttribute("download", "true");
          }
          if (isTheSameDomain && !isFile) {
            e.preventDefault();
            router.push(href);
            // for debug as well
            link.setAttribute("data-stork-local-link", "true");
          }
        }
      });
    });
  }
}
