<template>
  {{ null }}
</template>

<script lang="ts" setup>
import usePageTitle from "@/composables/usePageTitle";
import { computed } from "vue";

const props = defineProps<{
  title: string;
}>();

usePageTitle(computed(() => props.title));
</script>
