import { inject, ref, watchEffect } from "vue";
import { useAuthSessionStore } from "@/stores/authSessionStore";
import type { PostHogService } from "@/posthog/types";
import { getCookieValue, parseCookieString } from "@/utils/getCookieValue";

export function usePosthog(): PostHogService {
  return inject("posthog")!;
}

/**
 * check if the user has consented to analytics tracking every 5 seconds and update the PostHog instance accordingly
 */
export function useWatchPostHogConsent() {
  const posthog = usePosthog();

  const consentAnalytics = ref<boolean>(checkCookieYesCookieAnalytics());
  const trackingEnabled = ref<boolean>(posthog.has_opted_in_capturing());
  const authSessionStore = useAuthSessionStore();

  watchEffect((onCleanup) => {
    consentAnalytics.value = checkCookieYesCookieAnalytics();

    const interval = setInterval(() => {
      consentAnalytics.value = checkCookieYesCookieAnalytics();
    }, 5000);

    onCleanup(() => {
      clearInterval(interval);
    });
  });

  watchEffect(() => {
    if (consentAnalytics.value && import.meta.env.VITE_POSTHOG_TOKEN) {
      console.debug("User has consented to analytics tracking. Enable PostHog capturing.");
      posthog.opt_in_capturing();
      posthog.startSessionRecording();
      trackingEnabled.value = true;
    } else {
      console.debug("User has not consented to analytics tracking. Disable PostHog capturing.");
      posthog.stopSessionRecording();
      posthog.opt_out_capturing();
      trackingEnabled.value = false;
    }
  });

  watchEffect(() => {
    const session = authSessionStore.session;
    if (session) {
      if (trackingEnabled.value) {
        console.debug("Identify user in PostHog with session data");
        posthog.identify(session.id, {
          company_name: session.relationships?.company?.attributes?.name,
          company_id: session.relationships?.company?.id,
          preferred_language: session.attributes?.preferred_language,
          is_partner: session.attributes?.partner,
          mobile_app_version: window.ReactNativePlatform?.version || null,
        });
      } else {
        console.debug("Session updated, but PostHog capturing is disabled");
      }
    }
  });
}

/**
 * Check if the user has consented to analytics tracking using CookieYes
 *
 * The CookieYes API is not immediately available when the page loads, so we have extracted the consent information directly from the cookie
 */
export function checkCookieYesCookieAnalytics(): boolean {
  const consent = getCookieYesConsentFromCookie();
  if(!consent) return false;

  return consent.categories.analytics && consent.isUserActionCompleted;
}

/**
 * Get the consent information from the CookieYes cookie directly
 *
 * The CookieYes API is not immediately available when the page loads,
 *  so we have extracted the consent information directly from the cookie to speed up the process
 */
export function getCookieYesConsentFromCookie(): CookieYesCookieValue | null {
  const consentCookie = getCookieValue("cookieyes-consent");
  if (!consentCookie) return null;

  const parsedConsent = parseCookieString(consentCookie);
  // consentid:xxx,consent:yes,action:yes,necessary:yes,functional:yes,analytics:yes,performance:yes,advertisement:yes,other:yes

  return {
    categories: {
      necessary: parsedConsent["necessary"] === "yes",
      functional: parsedConsent["functional"] === "yes",
      analytics: parsedConsent["analytics"] === "yes",
      performance: parsedConsent["performance"] === "yes",
      advertisement: parsedConsent["advertisement"] === "yes",
    },
    isUserActionCompleted: parsedConsent["action"] === "yes",
  }
}

export interface CookieYesCookieValue {
  categories: {
    necessary: boolean;
    functional: boolean;
    analytics: boolean;
    performance: boolean;
    advertisement: boolean;
  };
  isUserActionCompleted: boolean;
}

declare global {
  interface Window {
    getCkyConsent: CookieYesCookieValue & {
      activeLaw: string;
      languageCode: string;
      consentID: string;
    };
  }
}
