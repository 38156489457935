<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <g filter="url(#filter0_d_8_2200)">
      <path
        d="M18.4881 12.7457C19.2857 11.8248 20.7143 11.8248 21.5119 12.7457L27.5264 19.6907C28.6481 20.986 27.728 23 26.0145 23H13.9855C12.272 23 11.3519 20.986 12.4736 19.6907L18.4881 12.7457Z"
        fill="currentColor"
      />
      <path
        d="M21.8898 12.4184C20.8929 11.2673 19.1071 11.2673 18.1102 12.4184L12.0957 19.3634C10.6935 20.9825 11.8436 23.5 13.9855 23.5H26.0145C28.1564 23.5 29.3065 20.9825 27.9043 19.3634L21.8898 12.4184Z"
        stroke="#E0DDD9"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_8_2200"
        x="0.874474"
        y="0.949791"
        width="38.2511"
        height="33.1555"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="5.05263" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_2200" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_2200" result="shape" />
      </filter>
    </defs>
  </svg>
</template>
