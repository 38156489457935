<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <rect x="6" y="6" width="15" height="9" fill="white" />
    <path
      d="M13.75 12.7115C13.0513 12.7115 12.46 12.4695 11.976 11.9856C11.492 11.5016 11.2501 10.9103 11.2501 10.2115C11.2501 9.51284 11.492 8.92151 11.976 8.43754C12.46 7.95358 13.0513 7.71159 13.75 7.71159C14.4487 7.71159 15.0401 7.95358 15.524 8.43754C16.008 8.92151 16.25 9.51284 16.25 10.2115C16.25 10.9103 16.008 11.5016 15.524 11.9856C15.0401 12.4695 14.4487 12.7115 13.75 12.7115ZM7.15388 15.8077C6.65678 15.8077 6.23122 15.6307 5.8772 15.2767C5.5232 14.9227 5.3462 14.4971 5.3462 14V6.42309C5.3462 5.92599 5.5232 5.50043 5.8772 5.14642C6.23122 4.79242 6.65678 4.61542 7.15388 4.61542H20.3461C20.8432 4.61542 21.2688 4.79242 21.6228 5.14642C21.9768 5.50043 22.1538 5.92599 22.1538 6.42309V14C22.1538 14.4971 21.9768 14.9227 21.6228 15.2767C21.2688 15.6307 20.8432 15.8077 20.3461 15.8077H7.15388ZM8.65385 14.3077H18.8462C18.8462 13.809 19.0232 13.383 19.3772 13.0298C19.7312 12.6766 20.1567 12.5 20.6539 12.5V7.92307C20.1551 7.92307 19.7292 7.74607 19.376 7.39207C19.0228 7.03805 18.8462 6.61249 18.8462 6.11539H8.65385C8.65385 6.61411 8.47685 7.04007 8.12285 7.39327C7.76884 7.74647 7.34328 7.92307 6.84618 7.92307V12.5C7.34489 12.5 7.77085 12.677 8.12405 13.031C8.47725 13.385 8.65385 13.8106 8.65385 14.3077ZM19.1731 19.3076H3.65393C3.15681 19.3076 2.73125 19.1306 2.37725 18.7766C2.02325 18.4226 1.84625 17.9971 1.84625 17.5V7.59619H3.3462V17.5C3.3462 17.5769 3.37826 17.6474 3.44238 17.7115C3.50648 17.7756 3.57699 17.8077 3.65393 17.8077H19.1731V19.3076ZM7.15388 14.3077H6.84618V6.11539H7.15388C7.07054 6.11539 6.99843 6.14584 6.93753 6.20674C6.87663 6.26764 6.84618 6.33976 6.84618 6.42309V14C6.84618 14.0833 6.87663 14.1554 6.93753 14.2163C6.99843 14.2773 7.07054 14.3077 7.15388 14.3077Z"
      fill="currentColor"
    />
  </svg>
</template>
