<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M9.69233 21.5L9.31156 18.4538C9.04361 18.3641 8.76892 18.2384 8.48751 18.0769C8.20609 17.9153 7.95448 17.7422 7.73268 17.5576L4.91153 18.7499L2.60388 14.75L5.04426 12.9058C5.02117 12.757 5.00482 12.6077 4.99521 12.4577C4.98559 12.3077 4.98078 12.1583 4.98078 12.0096C4.98078 11.8673 4.98559 11.7227 4.99521 11.5759C5.00482 11.4291 5.02117 11.2686 5.04426 11.0942L2.60388 9.24998L4.91153 5.26925L7.72306 6.45195C7.96409 6.26092 8.22147 6.08623 8.49518 5.9279C8.76892 5.76955 9.03783 5.6423 9.30193 5.54615L9.69233 2.5H14.3076L14.6884 5.55578C14.9884 5.66474 15.2599 5.79198 15.5029 5.9375C15.7458 6.08302 15.991 6.2545 16.2384 6.45195L19.0884 5.26925L21.3961 9.24998L18.9173 11.123C18.9532 11.2846 18.9727 11.4355 18.9759 11.5759C18.9791 11.7163 18.9807 11.8577 18.9807 12C18.9807 12.1359 18.9775 12.274 18.9711 12.4144C18.9647 12.5548 18.9416 12.7154 18.9019 12.8962L21.3615 14.75L19.0538 18.7499L16.2384 17.548C15.991 17.7455 15.7384 17.9201 15.4807 18.0721C15.223 18.224 14.9589 18.348 14.6884 18.4442L14.3076 21.5H9.69233ZM12.0115 15C12.8436 15 13.5516 14.708 14.1355 14.124C14.7195 13.54 15.0115 12.832 15.0115 12C15.0115 11.1679 14.7195 10.4599 14.1355 9.87595C13.5516 9.29198 12.8436 9 12.0115 9C11.1692 9 10.4587 9.29198 9.87981 9.87595C9.30097 10.4599 9.01156 11.1679 9.01156 12C9.01156 12.832 9.30097 13.54 9.87981 14.124C10.4587 14.708 11.1692 15 12.0115 15Z"
      fill="currentColor"
    />
  </svg>
</template>
