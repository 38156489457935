import { useAuthSessionStore } from "@/stores/authSessionStore";
import { watchEffect } from "vue";
import { syncCSRFTokenWithRN } from "@/utils/react-native";

export function useSyncCSRFWithRN() {
  const session = useAuthSessionStore();
  watchEffect(() => {
    if (session.csrfToken) {
      syncCSRFTokenWithRN(session.csrfToken);
    }
  });
}
