<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M8.19214 7.28851C7.97964 7.28851 7.80156 7.2166 7.65789 7.07276C7.51406 6.92893 7.44214 6.75077 7.44214 6.53827C7.44214 6.3256 7.51406 6.14751 7.65789 6.00401C7.80156 5.86035 7.97964 5.78851 8.19214 5.78851H11.2499V3.63477C11.2499 3.42227 11.3218 3.2441 11.4656 3.10027C11.6095 2.9566 11.7876 2.88477 12.0001 2.88477C12.2128 2.88477 12.3909 2.9566 12.5344 3.10027C12.6781 3.2441 12.7499 3.42227 12.7499 3.63477V5.78851H15.8076C16.0201 5.78851 16.1982 5.86043 16.3419 6.00426C16.4857 6.1481 16.5576 6.32627 16.5576 6.53876C16.5576 6.75143 16.4857 6.92951 16.3419 7.07301C16.1982 7.21668 16.0201 7.28851 15.8076 7.28851H8.19214ZM9.30764 21.2885C8.81047 21.2885 8.38489 21.1115 8.03089 20.7575C7.67689 20.4035 7.49989 19.9779 7.49989 19.4808V11C7.49989 10.2468 7.76356 9.6066 8.29089 9.07927C8.81806 8.5521 9.45822 8.28851 10.2114 8.28851H13.7884C14.5416 8.28851 15.1817 8.5521 15.7089 9.07927C16.2362 9.6066 16.4999 10.2468 16.4999 11V19.4808C16.4999 19.9779 16.3229 20.4035 15.9689 20.7575C15.6149 21.1115 15.1893 21.2885 14.6921 21.2885H9.30764ZM8.99989 19.7885H14.6921C14.782 19.7885 14.8557 19.7597 14.9134 19.702C14.9711 19.6442 14.9999 19.5704 14.9999 19.4808V11C14.9999 10.6567 14.8838 10.3689 14.6516 10.1368C14.4193 9.9046 14.1316 9.78851 13.7884 9.78851H10.2114C9.86822 9.78851 9.58047 9.9046 9.34814 10.1368C9.11597 10.3689 8.99989 10.6567 8.99989 11V12.125H10.9999C11.2124 12.125 11.3905 12.1969 11.5341 12.3408C11.678 12.4846 11.7499 12.6628 11.7499 12.8753C11.7499 13.0879 11.678 13.266 11.5341 13.4095C11.3905 13.5532 11.2124 13.625 10.9999 13.625H8.99989V15.952H10.9999C11.2127 15.952 11.3909 16.0239 11.5344 16.1678C11.6781 16.3114 11.7499 16.4896 11.7499 16.7023C11.7499 16.9148 11.678 17.0928 11.5341 17.2365C11.3905 17.3802 11.2124 17.452 10.9999 17.452H8.99989V19.7885Z"
      fill="currentColor"
    />
  </svg>
</template>
