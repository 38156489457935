<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M12 19.9231C11.5875 19.9231 11.2344 19.7762 10.9406 19.4824C10.6469 19.1887 10.5 18.8356 10.5 18.4231C10.5 18.0106 10.6469 17.6575 10.9406 17.3637C11.2344 17.07 11.5875 16.9231 12 16.9231C12.4125 16.9231 12.7656 17.07 13.0593 17.3637C13.3531 17.6575 13.5 18.0106 13.5 18.4231C13.5 18.8356 13.3531 19.1887 13.0593 19.4824C12.7656 19.7762 12.4125 19.9231 12 19.9231ZM10.6346 15.0193V3.57703H13.3653V15.0193H10.6346Z"
      fill="currentColor"
    />
  </svg>
</template>
