<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M7.30775 21.5001V20.5001C6.81058 20.5001 6.385 20.3231 6.031 19.9691C5.677 19.6151 5.5 19.1895 5.5 18.6923V8.3078C5.5 7.81064 5.677 7.38505 6.031 7.03105C6.385 6.67705 6.81058 6.50005 7.30775 6.50005H9.452V3.78855C9.452 3.54372 9.53817 3.33505 9.7105 3.16255C9.883 2.99022 10.0917 2.90405 10.3365 2.90405H13.6635C13.9083 2.90405 14.117 2.99022 14.2895 3.16255C14.4618 3.33505 14.548 3.54372 14.548 3.78855V6.50005H16.6923C17.1894 6.50005 17.615 6.67705 17.969 7.03105C18.323 7.38505 18.5 7.81064 18.5 8.3078V18.6923C18.5 19.1895 18.323 19.6151 17.969 19.9691C17.615 20.3231 17.1894 20.5001 16.6923 20.5001V21.5001H15.1923V20.5001H8.80775V21.5001H7.30775ZM10.952 6.50005H13.048V4.40405H10.952V6.50005ZM12 12.1923C12.8833 12.1923 13.7458 12.0798 14.5875 11.8548C15.4292 11.6298 16.2333 11.2923 17 10.8423V8.3078C17 8.21797 16.9712 8.14422 16.9135 8.08655C16.8558 8.02889 16.7821 8.00005 16.6923 8.00005H7.30775C7.21792 8.00005 7.14417 8.02889 7.0865 8.08655C7.02883 8.14422 7 8.21797 7 8.3078V10.8423C7.76667 11.2923 8.57083 11.6298 9.4125 11.8548C10.2542 12.0798 11.1167 12.1923 12 12.1923ZM11.25 14.6923V13.6616C10.5052 13.5911 9.77858 13.4661 9.07025 13.2866C8.36192 13.1071 7.67183 12.8564 7 12.5346V18.6923C7 18.7821 7.02883 18.8559 7.0865 18.9136C7.14417 18.9712 7.21792 19.0001 7.30775 19.0001H16.6923C16.7821 19.0001 16.8558 18.9712 16.9135 18.9136C16.9712 18.8559 17 18.7821 17 18.6923V12.5346C16.3282 12.8564 15.6381 13.1071 14.9298 13.2866C14.2214 13.4661 13.4948 13.5911 12.75 13.6616V14.6923H11.25Z"
      fill="currentColor"
    />
  </svg>
</template>
