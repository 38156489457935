<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M8.25003 18.3654H15.75V16.8654H8.25003V18.3654ZM8.25003 15.5577H15.75V14.0577H8.25003V15.5577ZM12 12.2904C13.0615 11.3288 13.9519 10.5119 14.6712 9.83946C15.3904 9.16702 15.75 8.41542 15.75 7.58466C15.75 7.04876 15.559 6.58978 15.1769 6.20773C14.7949 5.82568 14.3359 5.63466 13.8 5.63466C13.3987 5.63466 13.0564 5.72152 12.7731 5.89526C12.4897 6.06897 12.2321 6.30007 12 6.58853C11.768 6.30007 11.5103 6.06897 11.2269 5.89526C10.9436 5.72152 10.6013 5.63466 10.2 5.63466C9.66411 5.63466 9.20514 5.82568 8.82311 6.20773C8.44106 6.58978 8.25003 7.04876 8.25003 7.58466C8.25003 8.41542 8.59714 9.15452 9.29136 9.80196C9.98559 10.4494 10.8885 11.2788 12 12.2904ZM17.6923 21.5H6.30773C5.8026 21.5 5.37503 21.325 5.02503 20.975C4.67503 20.625 4.50003 20.1974 4.50003 19.6923V4.30773C4.50003 3.8026 4.67503 3.37503 5.02503 3.02503C5.37503 2.67503 5.8026 2.50003 6.30773 2.50003H17.6923C18.1974 2.50003 18.625 2.67503 18.975 3.02503C19.325 3.37503 19.5 3.8026 19.5 4.30773V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6923 21.5ZM6.30773 20H17.6923C17.782 20 17.8558 19.9712 17.9135 19.9135C17.9712 19.8558 18 19.782 18 19.6923V4.30773C18 4.21798 17.9712 4.14426 17.9135 4.08656C17.8558 4.02886 17.782 4.00001 17.6923 4.00001H6.30773C6.21798 4.00001 6.14426 4.02886 6.08656 4.08656C6.02886 4.14426 6.00001 4.21798 6.00001 4.30773V19.6923C6.00001 19.782 6.02886 19.8558 6.08656 19.9135C6.14426 19.9712 6.21798 20 6.30773 20Z"
      fill="currentColor"
    />
  </svg>
</template>
