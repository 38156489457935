import { defineStore } from "pinia";

export interface Notification {
  type: "error" | "server" | "success" | "alert" | "info";
  text: string;
  id: number;
  visible: boolean;
  duration: number;
}

const defaultDuration = 5000;

export const useUINotificationStore = defineStore("uiNotification", {
  state: () => ({
    notificationsArray: [] as Notification[],
    _counter: 0,
  }),
  getters: {
    notifications: (state) => state.notificationsArray,
  },
  actions: {
    error(text: string, duration = defaultDuration) {
      return this.add({ text, type: "error", duration });
    },
    server(text: string, duration = defaultDuration) {
      return this.add({ text, type: "server", duration });
    },
    success(text: string, duration = defaultDuration) {
      return this.add({ text, type: "success", duration });
    },
    alert(text: string, duration = defaultDuration) {
      return this.add({ text, type: "alert", duration });
    },
    info(text: string, duration = defaultDuration) {
      return this.add({ text, type: "info", duration });
    },

    add({ text, type, duration }: Omit<Notification, "id" | "visible">) {
      const id = this._counter;

      this.notificationsArray.push({
        text,
        type,
        id,
        visible: false,
        duration,
      });

      this._counter += 1;

      return id;
    },

    remove(id: number) {
      this.notificationsArray = this.notificationsArray.filter((el) => el.id !== id);
    },

    show(id: number) {
      const notification = this.notificationsArray.find((el) => el.id === id);

      notification && (notification.visible = true);
    },

    hide(id: number) {
      const notification = this.notificationsArray.find((el) => el.id === id);

      notification && (notification.visible = false);
    },
  },
});
