<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <rect x="4" y="3" width="12" height="9" fill="white" />
    <path
      d="M7.11543 17.1153C6.86478 17.1153 6.65468 17.0305 6.48513 16.861C6.31558 16.6914 6.23081 16.4813 6.23081 16.2307V14.7307H18.6058L18.9231 15.0481V5.99998H20.4231C20.6737 5.99998 20.8838 6.08475 21.0534 6.2543C21.2229 6.42385 21.3077 6.63394 21.3077 6.88457V20.6537L17.7693 17.1153H7.11543ZM2.69238 16.2691V3.38463C2.69238 3.13398 2.77716 2.92388 2.94671 2.75433C3.11626 2.58478 3.32636 2.5 3.57701 2.5H16.0385C16.2891 2.5 16.4992 2.58478 16.6688 2.75433C16.8383 2.92388 16.9231 3.13398 16.9231 3.38463V11.8461C16.9231 12.0968 16.8383 12.3069 16.6688 12.4764C16.4992 12.646 16.2891 12.7307 16.0385 12.7307H6.23081L2.69238 16.2691ZM15.4231 11.2308V3.99998H4.19236V11.9231L4.88471 11.2308H15.4231Z"
      fill="currentColor"
    />
  </svg>
</template>
