<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M12 7.00001C11.5141 7.00001 11.1009 6.82982 10.7606 6.48943C10.4202 6.14905 10.25 5.73591 10.25 5.25003C10.25 4.76413 10.4202 4.35099 10.7606 4.01061C11.1009 3.67022 11.5141 3.50003 12 3.50003C12.4859 3.50003 12.899 3.67022 13.2394 4.01061C13.5798 4.35099 13.75 4.76413 13.75 5.25003C13.75 5.73591 13.5798 6.14905 13.2394 6.48943C12.899 6.82982 12.4859 7.00001 12 7.00001ZM10.75 20.5V9.38466H13.25V20.5H10.75Z"
      fill="currentColor"
    />
  </svg>
</template>
