import type { Locale, CompiledTranslations, CompiledTranslationEntry, TranslationKeys } from "../shared-types";

export type Variables = Record<string, string | unknown>;
export type TFunction = (key: TranslationKeys, variables?: Variables) => string;

export default class Translator<En extends CompiledTranslations> {
  private locale: Locale = "en";
  private readonly resources: Record<string, CompiledTranslations> & { en: En };

  constructor(
    private readonly enResources: En,
    private readonly otherResources: Record<
      string,
      CompiledTranslations | (() => Promise<CompiledTranslations>)
    >,
    private readonly logger: {
      error: typeof console.error;
      warn: typeof console.warn;
    } = console,
  ) {
    this.resources = {
      en: enResources,
    };
  }

  public get currentLocale(): Locale {
    return this.locale;
  }

  public readonly t = (key: keyof En & string, variables?: Variables): string => {
    const { record: translationRecord, locale } = this.getTranslationRecord(this.locale, key);
    if (!translationRecord) {
      this.logger.error(`translationRecord for key "${String(key)}" is not found`);
      return key as string;
    }

    let translationString: string | undefined;
    if (typeof translationRecord === "object") {
      const count: number | undefined =
        variables && "count" in variables ? Number(variables.count) : undefined;
      if (count === undefined) {
        this.logger.error("Count is not provided for plural form");
        return key as string;
      } else {
        const pluralForm = this.getPluralForm(count, locale);
        translationString = translationRecord[pluralForm];
      }
      // TODO: handle case when plural form is not found
    } else {
      translationString = translationRecord;
    }

    if (!translationString) {
      this.logger.error(`Translation for key "${String(key)}" is not found`);
      return key as string;
    } else if (variables) {
      return this.replaceVariables(translationString, variables);
    } else {
      return translationString;
    }
  };

  public readonly changeLocale = async (locale: Locale): Promise<void> => {
    this.locale = locale;
    await this.loadLocale(locale);
  };

  private replaceVariables(translation: string, variables: Variables): string {
    return Object.keys(variables).reduce((result, varName) => {
      const varValue = variables[varName];
      return result.replace(new RegExp(`{${varName}}`, "g"), String(varValue));
    }, translation);
  }

  /**
   * Returns translation record for the given key and locale.
   * If translation is not found in the given locale, falls back to the main language.
   * If translation is not found in the main language, returns undefined.
   *
   * Returns locale for the found translation record,
   * as it might be different from the requested locale if the main language was used as a fallback,
   * and it may affect on plural forms.
   */
  private getTranslationRecord(
    locale: Locale,
    key: string,
  ): {
    record: CompiledTranslationEntry | undefined;
    locale: Locale;
  } {
    const resource: CompiledTranslations | undefined = this.resources[locale];


    const translation: CompiledTranslationEntry | undefined = resource ? resource[key] : undefined;

    if (!translation) {
      if (locale === "en") {
        return { record: undefined, locale };
      } else {
        this.logger.warn(
          `Translation for key "${String(key)}" is not found in locale "${locale}". Trying to fall back to the main language`,
        );
        return this.getTranslationRecord("en", key);
      }
    }

    return { record: translation, locale };
  }

  private getPluralForm(count: number, locale: Locale): Intl.LDMLPluralRule {
    const intl = new Intl.PluralRules(locale);
    return intl.select(count);
  }

  private async loadLocale(locale: Locale): Promise<void> {
    if (locale === "en") {
      return;
    }

    if (this.resources[locale]) {
      return;
    }

    if (!this.otherResources[locale]) {
      this.logger.error(`Translation resources for locale "${locale}" are not provided`);
      return;
    }

    if (
      this.otherResources.hasOwnProperty(locale) &&
      typeof this.otherResources[locale] === "function"
    ) {
      (this.resources as any)[locale] = await this.otherResources[locale]();
    }
  }
}
