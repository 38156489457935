<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M11.9883 20.5096C10.1743 20.5096 8.63625 19.8705 7.37408 18.5923C6.1119 17.3141 5.4808 15.7599 5.4808 13.9298C5.4808 12.7253 5.67888 11.5067 6.07503 10.274C6.47118 9.04136 6.98432 7.92149 7.61445 6.91444C8.24457 5.90739 8.94598 5.08496 9.71868 4.44714C10.4914 3.80933 11.2486 3.49042 11.9904 3.49042C12.7429 3.49042 13.5036 3.8075 14.2723 4.44167C15.0409 5.07583 15.7397 5.89629 16.3686 6.90304C16.9975 7.90979 17.51 9.03148 17.906 10.2681C18.302 11.5048 18.5 12.7253 18.5 13.9298C18.5 15.7599 17.8682 17.3141 16.6046 18.5923C15.341 19.8705 13.8022 20.5096 11.9883 20.5096ZM11.9904 19.0096C13.3801 19.0096 14.5625 18.5157 15.5375 17.5279C16.5125 16.5401 17 15.3431 17 13.9369C17 12.9713 16.8391 11.9651 16.5173 10.9183C16.1955 9.87147 15.7888 8.90864 15.2971 8.02979C14.8055 7.15094 14.2658 6.42466 13.678 5.85094C13.0903 5.27723 12.5277 4.99037 11.9904 4.99037C11.4609 4.99037 10.9003 5.27723 10.3087 5.85094C9.71699 6.42466 9.17532 7.15254 8.68365 8.03459C8.19199 8.91664 7.78525 9.88107 7.46345 10.9279C7.14167 11.9747 6.98078 12.9763 6.98078 13.9327C6.98078 15.3417 7.46828 16.5401 8.44328 17.5279C9.41828 18.5157 10.6006 19.0096 11.9904 19.0096ZM13 17.5096C13.2125 17.5096 13.3906 17.4377 13.5344 17.2939C13.6781 17.1501 13.75 16.9718 13.75 16.7592C13.75 16.5467 13.6781 16.3686 13.5344 16.225C13.3906 16.0814 13.2125 16.0096 13 16.0096C12.0972 16.0096 11.3299 15.6936 10.6979 15.0617C10.066 14.4297 9.74998 13.6624 9.74998 12.7596C9.74998 12.5471 9.67808 12.369 9.53428 12.2252C9.39046 12.0815 9.21226 12.0096 8.99968 12.0096C8.78708 12.0096 8.60899 12.0815 8.4654 12.2252C8.32182 12.369 8.25003 12.5471 8.25003 12.7596C8.25003 14.0738 8.71315 15.194 9.6394 16.1202C10.5656 17.0464 11.6858 17.5096 13 17.5096Z"
      fill="currentColor"
    />
  </svg>
</template>
