<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M12 20.3269L10.8962 19.3346C9.2385 17.8308 7.86766 16.5384 6.78368 15.4577C5.69971 14.3769 4.84075 13.4151 4.20678 12.5721C3.5728 11.7291 3.12985 10.9602 2.87793 10.2654C2.626 9.57051 2.50003 8.86538 2.50003 8.15C2.50003 6.73078 2.97856 5.54265 3.93561 4.5856C4.89266 3.62855 6.08079 3.15002 7.50001 3.15002C8.37309 3.15002 9.19809 3.3542 9.97501 3.76255C10.7519 4.17088 11.4269 4.75646 12 5.51927C12.5731 4.75646 13.2481 4.17088 14.025 3.76255C14.8019 3.3542 15.6269 3.15002 16.5 3.15002C17.9192 3.15002 19.1074 3.62855 20.0644 4.5856C21.0215 5.54265 21.5 6.73078 21.5 8.15C21.5 8.86538 21.374 9.57051 21.1221 10.2654C20.8702 10.9602 20.4272 11.7291 19.7932 12.5721C19.1593 13.4151 18.3019 14.3769 17.2211 15.4577C16.1404 16.5384 14.7679 17.8308 13.1038 19.3346L12 20.3269ZM12 18.3C13.6 16.8603 14.9167 15.6263 15.95 14.5981C16.9833 13.5699 17.8 12.6766 18.4 11.9183C19 11.1599 19.4167 10.4865 19.65 9.89807C19.8833 9.30961 20 8.72692 20 8.15C20 7.15 19.6667 6.31667 19 5.65C18.3333 4.98333 17.5 4.65 16.5 4.65C15.7103 4.65 14.9804 4.87404 14.3106 5.32213C13.6407 5.77019 13.1103 6.39359 12.7192 7.19233H11.2808C10.8833 6.38719 10.3513 5.76218 9.68463 5.3173C9.01796 4.87243 8.28976 4.65 7.50001 4.65C6.50642 4.65 5.67469 4.98333 5.00481 5.65C4.33494 6.31667 4.00001 7.15 4.00001 8.15C4.00001 8.72692 4.11667 9.30961 4.35001 9.89807C4.58334 10.4865 5.00001 11.1599 5.60001 11.9183C6.20001 12.6766 7.01667 13.5683 8.05001 14.5933C9.08334 15.6183 10.4 16.8539 12 18.3Z"
      fill="currentColor"
    />
  </svg>
</template>
