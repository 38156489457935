<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M6.40002 18.6538L5.34619 17.6L10.9462 12L5.34619 6.39996L6.40002 5.34613L12 10.9461L17.6 5.34613L18.6538 6.39996L13.0538 12L18.6538 17.6L17.6 18.6538L12 13.0538L6.40002 18.6538Z"
      fill="currentColor"
    />
  </svg>
</template>
