<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M7 0L8.97496 4.2817L13.6574 4.83688L10.1955 8.0383L11.1145 12.6631L7 10.36L2.8855 12.6631L3.80445 8.0383L0.342604 4.83688L5.02504 4.2817L7 0Z"
      fill="currentColor"
    />
  </svg>
</template>
