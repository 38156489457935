import type { PostHogService } from "@/posthog/types";

export default class NoopPostHog implements PostHogService {
  constructor() {
    console.debug("Initializing noop PostHog");
  }
  public optInCapturing() {}

  public optOutCapturing() {}

  public identifyUser() {}

  public reset() {}

  public startSessionRecording() {}

  public stopSessionRecording() {}

  public identify() {}

  public capture() {
    return undefined;
  }

  public opt_out_capturing() {}

  public opt_in_capturing() {}

  public has_opted_in_capturing() {
    return false;
  }

  public get_distinct_id() {
    return "";
  }
}
