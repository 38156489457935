<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :aria-hidden="!$attrs['aria-label']"
  >
    <path
      d="M12.9462 12L8.34619 7.40002L9.40002 6.34619L15.0538 12L9.40002 17.6538L8.34619 16.6L12.9462 12Z"
      fill="currentColor"
    />
  </svg>
</template>
